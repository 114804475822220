import { withRouter } from "react-router-dom"
import React, { useContext, useState, useEffect } from "react";
import Context from '../Context'
import Container from '@material-ui/core/Container';
import clsx from 'clsx';
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "../components/MainTheme.js";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Divider } from '@material-ui/core';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';
import InputAdornment from '@material-ui/core/InputAdornment';
import AccountCircle from '@material-ui/icons/AccountCircle';
import HomeIcon from '@material-ui/icons/Home';
import PhoneIcon from '@material-ui/icons/Phone';
import PublicIcon from '@material-ui/icons/Public';
import Text from "../components/Text";
import MuiAlert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar';
import { Button, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { useForm } from "react-hook-form";
import VisibilityIcon from '@material-ui/icons/Visibility';
import InputLabel from '@material-ui/core/InputLabel';
import Breadcrumb from "../components/Breadcrumb";

function UserProfile(props) {

    const classes = useStyles();

    const { user } = useContext(Context)
    const { register, handleSubmit, getValues, reset, formState: { errors } } = useForm()

    const maxHeightPaper = clsx(classes.paper, classes.maxHeight);

    const [open, setOpen] = useState(false);
    const [successSubmit, setSuccessSubmit] = useState(false);
    const [error, setError] = useState(false);
    const [userProfile, setUserProfile] = useState([]);
    const [image, setImage] = useState('');
    const [disable, setDisable] = useState(true)
    const [savedPassword, setSavedPassword] = useState(false);

    const [file, setFile] = useState(null);


    const [name, setName] = useState("");
    const [surname, setSurName] = useState("");
    const [country, setCountry] = useState(null);
    const [city, setCity] = useState(null);
    const [phone, setPhone] = useState(null)
    const [birthDate, setBirthDate] = useState(null)

    const [loading, setLoading] = useState(false);
    const [errorName, setErrorName] = useState(false);
    const [errorSurname, setErrorSurname] = useState(false);
    const [errorPhone, setErrorPhone] = useState(false);
    const [isPassword, setIsPassword] = useState("password");


    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    useEffect(() => {
        getData()
    }, [])

    async function getData() {


        if (user) {
            await fetch(`/back-end/public/api/getUserProfile`).then(
                response => response.json()).then(
                    json => setUserProfile(json)
                )
        }

    }

    const Imagefile = {
        beforeUpload: file => {
            setFile(file)
            setImage(URL.createObjectURL(file))
            return false;
        }
    };

    function removedImage() {
        setImage(null)
        setFile(null)
    }

    const onSubmit = async data => {

        setLoading(true)

        const formDataPassword = new FormData();

        formDataPassword.append('password', data.password)
        formDataPassword.append('user_id', user.id)

        await fetch("/back-end/public/api/changePassword", {
            method: "POST",
            body: formDataPassword
        }).then(
            response => response.json()).then(
                json => {
                    if (json.msg === 'success') {
                        setSavedPassword(true)
                        reset();
                    }
                    else setError(true)
                }
            )

        setLoading(false)
    }
    async function submit() {

        setLoading(true)

        if (name.trim() === "")
            setErrorName(true)
        else setErrorName(false)
        if (surname.trim() === "")
            setErrorSurname(true)
        else setErrorSurname(false)
        if (phone.trim() === "")
            setErrorPhone(true)

        else if (name.trim() !== "" && surname.trim() !== "" && phone.trim() !== "") {

            const formData = new FormData();

            formData.append('name', name)
            formData.append('surname', surname)
            if (country !== null)
                if (birthDate !== '')
                    formData.append('birth_date', birthDate)
            formData.append('country', country)
            if (city !== null)
                formData.append('city', city)
            if (phone !== null)
                formData.append('phone', phone)
            if (file !== '')
                formData.append('file', file)

            await fetch("/back-end/public/api/updateUserData", {
                method: "POST",
                body: formData
            }).then((response) => response.json()).then((json) => {
                if (json.msg === "success") {
                    setUserProfile([])
                    getData()
                    setDisable(true)
                    setSuccessSubmit(true)
                } else {
                    getData()
                    setDisable(true)
                    setError(true)
                }
            })

        }
        setLoading(false)
    }

    function updateProfile() {
        setDisable(false)
        setOpen(true)
        userProfile.filter(item => item !== null).map((item) => {
            setName(item.name)
            setSurName(item.surname)
            setBirthDate(item.birth_date)
            setCountry(item.birth_place)
            setCity(item.birth_city)
            setPhone(item.phone)
        })
    }
    function cancel() {
        setImage('')
        setDisable(true)
        setErrorName(false)
        setErrorSurname(false)
        setErrorPhone(false)

    }

    const handleCloseSubmit = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSavedPassword(false)
        setSuccessSubmit(false);
    }
    const handleError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setError(false);
    }
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    function showPassword() {
        if (isPassword === 'password')
            setIsPassword("text")
        else setIsPassword("password")
    }

    return (
        <Container maxWidth="lg">
            <Grid container spacing={3} justify='center'>

                <Grid item xs={12} lg={10}>
                    <Breadcrumb />

                    <Paper className={maxHeightPaper}>
                        <Grid item xs={12} lg={12} style={{ display: 'flex', justifyContent: 'space-betwwen' }}>
                            <Typography style={{ padding: '10px 0 10px 20px' }} variant="h6" className={classes.title}>

                            Dettagli account
                            </Typography>

                            <Snackbar open={open} autoHideDuration={3000} onClose={handleClose} >
                                <Alert onClose={handleClose} severity="info">
                                Prima di continuare, aggiungi le informazioni mancanti e salva
                                </Alert>
                            </Snackbar>
                            <Snackbar open={successSubmit} autoHideDuration={3000} onClose={handleCloseSubmit} >
                                <Alert onClose={handleCloseSubmit} severity="success">
                                Il profile è stato aggiornato correttamente
                                </Alert>
                            </Snackbar>
                            <Snackbar open={savedPassword} autoHideDuration={3000} onClose={handleCloseSubmit} >
                                <Alert onClose={handleCloseSubmit} severity="success">
                                La password è stata aggiornata
                                </Alert>
                            </Snackbar>
                            <Snackbar open={error} autoHideDuration={3000} onClose={handleError} >
                                <Alert onClose={handleError} severity="error">
                                Si è verificato un errore, riprova
                                </Alert>
                            </Snackbar>
                        </Grid>

                        <Divider variant='fullWidth' />
                        {userProfile.length > 0 ?
                            <form noValidate autoComplete="off">
                                {userProfile.filter(item => item !== null).map((item, index) =>

                                    <Grid key={index} item xs={12} lg={12} style={{ display: 'flex', justifyContent: 'space-between', padding: '20px 0 20px 20px' }}>

                                        {disable ? <Grid item xs={12} sm={5} lg={4} key={index} style={{
                                            display: 'grid', gridGap: '20px'
                                        }}>
                                            <Text required={true} label='Nome' value={item.name} ></Text>
                                            <Text required={true} label='Cognome' value={item.surname} ></Text>
                                            <Text required={false} label='Data di nascita' value={item.birth_date} ></Text>
                                            <Text required={false} label='Luogo di nascita' value={item.birth_place} ></Text>

                                            <Text required={false} label='Luogo di nascita' value={item.birth_city} ></Text>

                                            <Text required={true} label='Telefono' value={item.phone} ></Text>

                                        </Grid> :

                                            <Grid item xs={12} sm={5} lg={4} style={{
                                                display: 'grid', gridGap: '20px'
                                            }} >

                                                <TextField
                                                    variant="outlined"
                                                    size="small"
                                                    id="input-with-icon-textfield"
                                                    label='Nome'
                                                    defaultValue={item.name}
                                                    required
                                                    InputLabelProps={{
                                                        style: { color: '#3f51b5' },
                                                        classes: {
                                                            asterisk: classes.asterisk
                                                        }

                                                    }}
                                                    onChange={(e) => setName(e.target.value)}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <AccountCircle fontSize="small" style={{ color: '#212121' }} />
                                                            </InputAdornment>
                                                        ),
                                                        classes: {
                                                            disabled: classes.profileText,
                                                            input: classes.profileText,

                                                        }

                                                    }}
                                                    error={errorName ? true : false}
                                                    helperText={errorName ? 'Il campo è obbligatorio' : null}
                                                />

                                                <TextField

                                                    variant="outlined"
                                                    size="small"
                                                    id="input-with-icon-textfield"
                                                    label='Cognome'
                                                    defaultValue={item.surname}
                                                    required
                                                    InputLabelProps={{
                                                        style: { color: '#3f51b5' },
                                                        classes: {
                                                            asterisk: classes.asterisk
                                                        }

                                                    }}
                                                    onChange={(e) => setSurName(e.target.value)}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <AccountCircle fontSize="small" style={{ color: '#212121' }} />
                                                            </InputAdornment>
                                                        ),
                                                        classes: {
                                                            disabled: classes.profileText,
                                                            input: classes.profileText,

                                                        }

                                                    }}
                                                    error={errorSurname ? true : null}
                                                    helperText={errorSurname ? 'Il campo è obbligatorio' : null}

                                                />
                                                <TextField
                                                    variant="outlined"
                                                    size="small"
                                                    id="input-with-icon-textfield"
                                                    label='Data di nascita'
                                                    InputLabelProps={{
                                                        style: { color: '#3f51b5' },
                                                        classes: {
                                                            asterisk: classes.asterisk
                                                        }

                                                    }}
                                                    type='date'
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <CalendarTodayIcon fontSize="small" style={{ color: '#212121' }} />
                                                            </InputAdornment>
                                                        ),
                                                        classes: {
                                                            disabled: classes.profileText,
                                                            input: classes.profileText,

                                                        }

                                                    }}
                                                    onChange={(e) => setBirthDate(e.target.value)}
                                                    defaultValue={item.birth_date}
                                                />
                                                <TextField
                                                    variant="outlined"
                                                    size="small"
                                                    id="input-with-icon-textfield"
                                                    label='Luogo di nascita'
                                                    defaultValue={item.birth_place}
                                                    InputLabelProps={{
                                                        style: { color: '#3f51b5' },
                                                        classes: {
                                                            asterisk: classes.asterisk
                                                        }

                                                    }}
                                                    onChange={(e) => setCountry(e.target.value)}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <PublicIcon fontSize="small" style={{ color: '#212121' }} />
                                                            </InputAdornment>
                                                        ),
                                                        classes: {
                                                            disabled: classes.profileText,
                                                            input: classes.profileText,

                                                        }

                                                    }}
                                                />
                                                <TextField
                                                    variant="outlined"
                                                    size="small"
                                                    id="input-with-icon-textfield"
                                                    label='Birth city'
                                                    defaultValue={item.birth_city}
                                                    InputLabelProps={{
                                                        style: { color: '#3f51b5' },
                                                        classes: {
                                                            asterisk: classes.asterisk
                                                        }

                                                    }}
                                                    onChange={(e) => setCity(e.target.value)}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <HomeIcon fontSize="small" style={{ color: '#212121' }} />
                                                            </InputAdornment>
                                                        ),
                                                        classes: {
                                                            disabled: classes.profileText,
                                                            input: classes.profileText,

                                                        }

                                                    }}
                                                />
                                                <TextField
                                                    variant="outlined"
                                                    size="small"
                                                    id="input-with-icon-textfield"
                                                    label='Telefono'
                                                    defaultValue={item.phone}
                                                    required
                                                    InputLabelProps={{
                                                        style: { color: '#3f51b5' },
                                                        classes: {
                                                            asterisk: classes.asterisk
                                                        }

                                                    }}
                                                    onChange={(e) => setPhone(e.target.value)}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <PhoneIcon fontSize="small" style={{ color: '#212121' }} />
                                                            </InputAdornment>
                                                        ),
                                                        classes: {
                                                            disabled: classes.profileText,
                                                            input: classes.profileText,

                                                        }

                                                    }}
                                                    error={errorPhone ? true : null}
                                                    helperText={errorPhone ? 'Il campo è obbligatorio' : null}
                                                />

                                            </Grid>
                                        }
                                        <Grid item xs={12} sm={5} lg={4}>
                                            <Grid item xs={12} lg={12} style={{
                                                textAlign: 'center', padding: '10px'
                                            }} >
                                                {image ? <Avatar src={image}
                                                    style={{ lineHeight: '60px' }} size={120} /> :
                                                    <Avatar src={'/back-end/public/' + item.image_path}
                                                        style={{ lineHeight: '87px' }} size={120} icon={<UserOutlined />} />}


                                                {disable ? null :

                                                    <div style={{ marginTop: '1rem' }}>
                                                        <Upload
                                                            {...Imagefile}
                                                            listType="picture"
                                                            maxCount={1}
                                                            accept='image/*'
                                                            onRemove={removedImage} >
                                                            <Button icon={<UploadOutlined />} >Upload Immagine</Button>
                                                        </Upload>
                                                    </div>

                                                }
                                            </Grid>
                                            {disable ?
                                                <Grid item xs={12} style={{ textAlign: 'center' }} >
                                                    <Button
                                                        type="primary"
                                                        className={classes.button}
                                                        onClick={updateProfile}

                                                    >
                                                        Aggiorna
                                                    </Button>
                                                </Grid> : null}

                                            {disable ? null :
                                                <Grid item xs={12} style={{ textAlign: 'center' }} >
                                                    <Button
                                                        type="primary"
                                                        className={classes.button}
                                                        onClick={cancel}
                                                        disabled={loading}
                                                    >
                                                        Cancella
                                                    </Button>
                                                    <Button
                                                        type="primary"
                                                        className={classes.button}
                                                        onClick={submit}
                                                        loading={loading}>
                                                        Salva
                                                    </Button>

                                                </Grid>}


                                        </Grid>



                                    </Grid>
                                )}  </form> : null}
                    </Paper>
                </Grid>
                {userProfile.length > 0 ?
                    <Grid item xs={12} lg={10}>
                        <Grid item xs={12} lg={5}>
                            <Paper className={maxHeightPaper} style={{ padding: '20px' }}>
                                <InputLabel htmlFor="age-native-helper" style={{ marginBottom: '2rem' }}>Cambia password</InputLabel>
                                <form className={classes.root} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                                    <Grid item xs={12} sm={5} lg={12} style={{
                                        display: 'grid', gridGap: '20px'
                                    }}>
                                        <TextField
                                            {...register('password', { required: true })}
                                            variant="outlined"
                                            size="small"
                                            id="password"
                                            label='Password'
                                            type={isPassword}
                                            InputLabelProps={{
                                                style: { color: '#3f51b5' },
                                                classes: {
                                                    asterisk: classes.asterisk
                                                }

                                            }}
                                            required
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        {isPassword === "password" ?
                                                            <VisibilityOffIcon fontSize="small" style={{ color: '#212121', cursor: 'pointer' }} onClick={() =>
                                                                showPassword()} /> :
                                                            <VisibilityIcon fontSize="small" style={{ color: '#212121', cursor: 'pointer' }} onClick={() =>
                                                                showPassword()} />}
                                                    </InputAdornment>
                                                ),
                                                classes: {
                                                    disabled: classes.profileText,
                                                    input: classes.profileText,

                                                }

                                            }}
                                            error={errors?.password?.type === "required" && true}
                                            helperText={errors?.password?.type === "required" && 'Il campo è obbligatorio'}
                                        />
                                        <TextField
                                            {...register('confirme_password', {
                                                required: true,
                                                validate: {
                                                    passwordMatch: value => (value === getValues().password) || 'The passwords do not match',
                                                }
                                            })}
                                            required
                                            variant="outlined"
                                            size="small"
                                            id="confirme_password"
                                            label='Conferma password'
                                            type={isPassword}
                                            InputLabelProps={{
                                                style: { color: '#3f51b5' },
                                                classes: {
                                                    asterisk: classes.asterisk
                                                }

                                            }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        {isPassword === "password" ?
                                                            <VisibilityOffIcon fontSize="small" style={{ color: '#212121', cursor: 'pointer' }} onClick={() =>
                                                                showPassword()} /> :
                                                            <VisibilityIcon fontSize="small" style={{ color: '#212121', cursor: 'pointer' }} onClick={() =>
                                                                showPassword()} />}
                                                    </InputAdornment>
                                                ),
                                                classes: {
                                                    disabled: classes.profileText,
                                                    input: classes.profileText,

                                                }

                                            }}

                                            error={(errors?.confirme_password?.type === 'passwordMatch' && true) || (errors?.confirme_password?.type === "required" && true)}
                                            helperText={(errors?.confirme_password?.type === 'passwordMatch' && errors?.confirme_password?.message) ||
                                                (errors?.confirme_password?.type === "required" && 'Il campo è obbligatorio')}
                                        />
                                    </Grid>

                                    <Grid item xs={12} style={{ textAlign: 'center' }} >
                                        <Button
                                            htmlType="submit"
                                            type="primary"
                                            className={classes.button}
                                            loading={loading}
                                            style={{ width: '120px' }}
                                        >
                                            Salva
                                        </Button>
                                    </Grid>
                                </form>

                            </Paper>
                        </Grid>
                    </Grid> : null}
            </Grid >
        </Container >

    );
}

export default withRouter(UserProfile);
