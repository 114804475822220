import { withRouter } from "react-router-dom"
import React, { useState, useEffect } from "react";
import TextField from '@material-ui/core/TextField';
import { useStyles } from "../components/MainTheme.js";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button } from 'antd';
import Grid from '@material-ui/core/Grid';


function UpdateSection(props) {

    const section_id = props.section_id
    const open = props.open


    const classes = useStyles();

    const [section, setSection] = useState(null);
    const [loading, setLoading] = useState(false);

    const [errorName, setErrorName] = useState(false);

    useEffect(() => {
        getData()
    }, [open])

    async function getData() {
        await fetch("/back-end/public/api/getSectionNameAdmin/" + section_id).then(
            response => response.json()).then(
                json => setSection(json)
            )

    }

    async function update() {

        setLoading(true)

        if (section === '') {
            setErrorName(true)
            setLoading(false)
        } else {

            const formData = new FormData();

            formData.append('section_name', section)
            formData.append('section_id', section_id)

            await fetch("/back-end/public/api/updateSection", {
                method: "POST",
                body: formData
            }).then(
                response => response.json()).then(
                    json => {
                        if (json.msg === 'success') {
                            handleClose()
                        }
                        else {
                            setLoading(false)
                        }
                    }

                )
        }


    }

    const handleClose = () => {
        setSection(null)
        setErrorName(false)
        setLoading(false)
        props.section_id_clear(null)
        props.close(false)
    };

    return section ? (

        <Dialog fullWidth disableBackdropClick disableEscapeKeyDown open={open} onClose={handleClose}>
            <DialogTitle>Aggiorna sezione</DialogTitle>

            <DialogContent>
                <Grid item xs={12} sm={12} lg={12}>
                    <TextField
                        fullWidth
                        id="outlined-full-width"
                        label="Nome sezione"
                        placeholder="Nome sezione"
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        size='small'
                        variant="outlined"
                        required
                        defaultValue={section}
                        onChange={(e) => setSection(e.target.value)}
                        error={errorName}
                        helperText={errorName ? 'Il campo è obbligatorio' : null}
                    />
                </Grid>


            </DialogContent>
            <DialogActions>
                <Button disabled={loading} onClick={handleClose} >
                    Cancella
                </Button>
                <Button
                    loading={loading}
                    form="my-form-id"
                    type="primary"
                    className={classes.button}
                    onClick={update} >
                    Salva
                </Button>
            </DialogActions>
        </Dialog>
    ) : null


}

export default withRouter(UpdateSection);
