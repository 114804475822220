import React, { Component } from 'react';
import { EditorState, convertToRaw, convertFromHTML, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import "../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../App.css";
import draftToHtml from 'draftjs-to-html';


class ControlledEditor extends Component {

    constructor(props) {
        super(props);

        if (props.content) {
            const content = props.content
            const html = content
            const blocksFromHTML = convertFromHTML(html)
            const content_html = ContentState.createFromBlockArray(blocksFromHTML)
            this.state = {
                editorState: EditorState.createWithContent(content_html)
            };
        } else {
            this.state = {
                editorState: EditorState.createEmpty(),
            }
        }
    }

    onEditorStateChange: Function = (editorState) => {

        this.props.description(draftToHtml(convertToRaw(editorState.getCurrentContent())))
        this.setState({
            editorState,
        });

    };



    render() {
        const { editorState } = this.state;
        return (
            <Editor
                editorState={editorState}
                wrapperClassName="demo-wrapper"
                editorClassName="home-editor"
                onEditorStateChange={this.onEditorStateChange}
            />

        )
    }
}


export default ControlledEditor;
