import React from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import InputAdornment from '@material-ui/core/InputAdornment';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { useStyles } from "./MainTheme.js";
import HomeIcon from '@material-ui/icons/Home';
import PhoneIcon from '@material-ui/icons/Phone';
import PublicIcon from '@material-ui/icons/Public';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';


export default function Text(props) {

    const classes = useStyles();

    return (
        <TextField
            id={props.label}
            label={props.label}
            value={props.value}
            disabled
            required={props.required}
            InputLabelProps={{
                style: { color: '#212121' },

            }}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        {props.label === 'Nome' ? <AccountCircle fontSize="small" style={{ color: '#212121' }} /> : null}
                        {props.label === 'Cognome' ? <AccountCircle fontSize="small" style={{ color: '#212121' }} /> : null}
                        {props.label === 'Data di nascita' ? <CalendarTodayIcon fontSize="small" style={{ color: '#212121' }} /> : null}
                        {props.label === 'Luogo di nascita' ? <PublicIcon fontSize="small" style={{ color: '#212121' }} /> : null}
                        {props.label === 'Luogo di nascita' ? <HomeIcon fontSize="small" style={{ color: '#212121' }} /> : null}
                        {props.label === 'Telefono' ? <PhoneIcon fontSize="small" style={{ color: '#212121' }} /> : null}
                    </InputAdornment>
                ),
                classes: {
                    disabled: classes.profileText,
                    input: classes.profileText,

                }

            }}
        > {props.children}
        </TextField >
    )
}

Text.propTypes = {
    children: PropTypes.node,
};
