import { withRouter, useHistory, useParams } from "react-router-dom"
import React, { useState, useEffect } from "react";
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { useStyles } from "../components/MainTheme.js";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { useForm } from "react-hook-form";
import { Upload, Button, Image } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/NativeSelect';
import FormHelperText from '@material-ui/core/FormHelperText';
import ControlledEditor from "../components/EditorContainer"

function UpdateCourse(props) {

  const { course_id } = useParams()
  const history = useHistory();
  const classes = useStyles();
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [image, setImage] = useState("");
  const [displayImage, setDisplayImage] = useState(null);
  const [description, setDescription] = useState("");
  const [content, setContent] = useState("");
  const [courseType, setCourseType] = useState("Normal");


  const [course, setCourse] = useState(null);

  const { handleSubmit } = useForm()


  useEffect(() => {
    getData()

  }, [])

  async function getData() {
    await fetch(`/back-end/public/api/getCourse/${course_id}`).then(
      response => response.json()).then(
        json => {
          setContent(json.description)
          setCourse(json)
          setTitle(json.title)
          setDescription(json.description)
          setCourseType(json.type)
        }
      )
  }


  const onSubmit = async data => {
    setLoading(true)

    if (title === "") {
      setLoading(false)
      setError(true)
    } else { 


      const formData = new FormData();
      formData.append('title', title)
      formData.append('course_id', course_id)
      formData.append('type', courseType)
      if (image)
        formData.append('file', image)
      if (description && description.trim().length !== 7)
        formData.append('description', description)

      await fetch("/back-end/public/api/updateCourse", {
        method: "POST",
        body: formData
      }).then(
        response => response.json()).then(
          json => {
            if (json.msg === 'success') {
              setLoading(false)
              history.push("/admin/corsi")
            }

          })

    }
  }

  const handleChangeType = (event) => {
    setCourseType(event.target.value)
  };


  const file = {
    beforeUpload: file => {
      setImage(file)
      setDisplayImage(URL.createObjectURL(file))

      return false;
    }
  };

  function removedImage() {
    setDisplayImage(null)
  }

  const handleCallback = (childData) => {
    setDescription(childData)
  }
  function cancel() {
    history.goBack()
  }


  return (

    <Container maxWidth="lg">
      <Grid item xs={12} sm={12} lg={12}>
        {course ?
          <Paper style={{ padding: '16px' }}>
            <Typography variant="h6" className={classes.title} style={{ marginBottom: '1rem' }}>
            Aggiorna corso
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
              <Grid container spacing={3}>
                <Grid item sx={12} sm={12} lg={12}>
                  <TextField
                    size="small"
                    fullWidth
                    variant='outlined'
                    required
                    id="standard-helperText"
                    label="Nome del Corso"
                    onChange={(e) => setTitle(e.target.value)}
                    defaultValue={course.title}
                    error={error}
                    helperText={error ? 'Il campo è obbligatorio' : null}
                  />
                </Grid>
                <Grid item sx={12} sm={12} lg={12}>
                  <InputLabel htmlFor="age-native-helper">Tipologia Corso</InputLabel>
                  <NativeSelect
                    required
                    fullWidth
                    style={{ width: '30%' }}
                    value={courseType}
                    onChange={handleChangeType}
                    inputProps={{
                      name: 'type',
                    }}
                  >
                    <option value='Normal'>Corsi sola visualizzazione</option>
                    <option value='Mandatory' >Formazione Obbligatoria (con Timer)</option>
                  </NativeSelect>
                  <FormHelperText>Seleziona la tipologia</FormHelperText>

                </Grid>
                <Grid item xs={12} sm={12} lg={12}>
                  <InputLabel htmlFor="age-native-helper">Descrizione Corso </InputLabel>
                  <ControlledEditor description={handleCallback} content={content} />
                </Grid>
                <Grid item xs={12} sm={12} lg={12}>
                  <Image
                    width={300}
                    src={displayImage ? displayImage : `/back-end/public/${course.picture_path}`}
                  />
                </Grid>
                <Grid item sx={12} sm={12} lg={12} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>

                  <Upload
                    {...file}
                    listType="picture"
                    maxCount={1}
                    accept='image/*'
                    onRemove={removedImage}
                  >
                    <Button icon={<UploadOutlined />} >Immagine del Corso</Button>
                  </Upload>


                  <Button
                    htmlType="submit"
                    type="primary"
                    className={classes.button}
                    style={{
                      width: '120px',
                    }}
                    loading={loading}
                  >
                    Salva
                  </Button>
                  <Button
                    disabled={loading}
                    onClick={() => cancel()}
                    style={{
                      width: '120px',
                    }}
                    className={classes.button}>
                    Cancella
                  </Button>
                </Grid>
              </Grid>
            </form>

          </Paper>
          : null}
      </Grid>

    </Container>

  );
}

export default withRouter(UpdateCourse);
