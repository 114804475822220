import { withRouter } from "react-router-dom"
import React, { useState, useEffect } from "react";
import TextField from '@material-ui/core/TextField';
import { useStyles } from "../components/MainTheme.js";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button } from 'antd';
import Grid from '@material-ui/core/Grid';


function UpdateVideo(props) {

    const video_id = props.video_id
    const open = props.open

    const classes = useStyles();


    const [video, setVideo] = useState(null);


    const [errorName, setErrorName] = useState(false);
    const [errorUrl, setErrorUrl] = useState(false);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        getData()
    }, [open])

    async function getData() {
        if (video_id)
            await fetch("/back-end/public/api/getVideo/" + video_id).then(
                response => response.json()).then(
                    json =>
                        setVideo(json)
                )
    }


    async function update() {

        setLoading(true)

        if (video.video_name === '') {
            setErrorName(true)
            setLoading(false)
        }
        else if (video.url === "") {
            setErrorUrl(true)
            setLoading(false)


        }

        else {
            const formData = new FormData();
            formData.append('video_name', video.video_name)
            formData.append('url', video.url)
            formData.append('video_id', video_id)

            await fetch("/back-end/public/api/updateVideo", {
                method: "POST",
                body: formData
            }).then(
                response => response.json()).then(
                    json => {
                        if (json.msg === 'success') {
                            setErrorUrl(false)
                            setErrorName(false)
                            setVideo(false)
                            setLoading(false)
                            props.video_id_clear(null)
                            props.close(false)
                        }
                        else {
                            setLoading(false)
                        }
                    }

                )
        }

    }

    const handleClose = () => {
        setErrorUrl(false)
        setErrorName(false)
        setVideo(false)
        setLoading(false)
        props.video_id_clear(null)
        props.close(false)
    };


    return video ? (

        <Dialog fullWidth disableBackdropClick disableEscapeKeyDown open={open} onClose={handleClose}>
            <DialogTitle>Update video</DialogTitle>

            <DialogContent>
                <Grid item xs={12} sm={12} lg={12}>
                    <TextField
                        fullWidth
                        id="outlined-full-width"
                        label="Video Name"
                        placeholder="Video name"
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        size='small'
                        variant="outlined"
                        required
                        defaultValue={video.video_name}
                        onChange={(e) => setVideo({ ...video, video_name: e.target.value })}
                        error={errorName}
                        helperText={errorName ? 'Il campo è obbligatorio' : null} />

                    <TextField
                        fullWidth
                        id="outlined-full-width"
                        label="Url"
                        placeholder="Url"
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        size='small'
                        variant="outlined"
                        required
                        defaultValue={video.url}
                        onChange={(e) => setVideo({ ...video, url: e.target.value })}
                        error={errorUrl}
                        helperText={errorUrl ? 'Il campo è obbligatorio' : null} />
                </Grid>

            </DialogContent>
            <DialogActions>
                <Button disabled={loading} onClick={handleClose} >
                    Cancel
                </Button>
                <Button
                    loading={loading}
                    type="primary"
                    className={classes.button}
                    onClick={update}   >
                    Save
                </Button>
            </DialogActions>

        </Dialog>
    ) : null
}

export default withRouter(UpdateVideo);
