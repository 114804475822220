import React, { useState, useEffect } from "react";
import Container from '@material-ui/core/Container';
import { withRouter, useHistory, useParams } from "react-router-dom"
import Grid from '@material-ui/core/Grid';
import {  Modal} from 'antd';
import { useStyles } from "../components/MainTheme.js";


function CheckResult() {

    const { section_id } = useParams()
    const { course_id } = useParams()
    const history = useHistory();

    const [answerScore, setScoreAnswers] = useState([])
    const [testScore, setTestScore] = useState('')
    const [passedTest, setPassedTest] = useState(null)
    const [didNotPassed, setDidNotPassed] = useState(null)

    useEffect(() => {
        let isMounted = true;
        if (isMounted) getData()
        return () => { isMounted = false };
    }, [])

    async function getData() {

        if(section_id === '0')
        {
            await fetch(`/back-end/public/api/getTestTitleFinalUser/${course_id}`)
            .then((response) => response.json())
            .then(json =>{ 
                if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                else 
                {  setPassedTest(json.if_pass_test)
                     
                      setDidNotPassed(json.if_didnt_pass_test)
                  }
            }
            )

            await fetch(`/back-end/public/api/getTestScoreFinal/${course_id}`).then(
                response => response.json()).then(
                    json => {
                        if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                        else setScoreAnswers(json)
                    }
                )
    
            await fetch(`/back-end/public/api/getTotalUserScoreQuestionFinal/${course_id}`).then(
                response => response.json()).then(
                    json => {
                        if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                        else setTestScore(json)
                    }
                )
        }
      else{  
          
        await fetch(`/back-end/public/api/getTestTitleUser/${course_id}/${section_id}`)
            .then((response) => response.json())
            .then(json =>{ 
                if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                else 
                {  setPassedTest(json.if_pass_test)
                     
                      setDidNotPassed(json.if_didnt_pass_test)
                  }
            }
            )

        await fetch(`/back-end/public/api/getTestScore/${course_id}/${section_id}`).then(
            response => response.json()).then(
                json => {
                    if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                    else setScoreAnswers(json)
                }
            )

        await fetch(`/back-end/public/api/getTotalUserScoreQuestion/${course_id}/${section_id}`).then(
            response => response.json()).then(
                json => {
                    if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                    else setTestScore(json)
                }
            )
        }
    } 
    useEffect(() => {
      if(testScore && answerScore) success() 
    }, [testScore])
  
    function success() {
          if(testScore && answerScore && (((answerScore*100)/testScore)  >= 70)) 
            {
        Modal.success({
            title: `${passedTest}`,
    content: (
      <div>
        <p>Punteggio: {Math.round((answerScore*100)/testScore)}%</p>
      </div>
    ),
    okText:'Check result',
          onOk() { 
                if (section_id === '0') {
    
                    history.replace({
                        pathname: `/home/corsi/${course_id}/${section_id}/test-risultati`,
    
                    })
                } else {
                    history.replace({
                        pathname: `/home/corsi/${course_id}/moduli/${section_id}/test-risultati`,
    
                    })
                }
        
        },
        }) }else if(testScore && answerScore && (((answerScore*100)/testScore)  < 70)) {

            Modal.error({
                title: `${didNotPassed}`,
    content: (
      <div>
        <p>Punteggio: {Math.round((answerScore*100)/testScore)}%</p>
      </div>
    ),
    
                onOk()
                {
                    if (section_id === '0') {

                        history.replace({
                            pathname: `/home/corsi/${course_id}`,
        
                        })
                    } else {
                        history.replace({
                            pathname: `/home/corsi/${course_id}/moduli/${section_id}`,
        
                        })
                    }
                }
              });
          
        }
      }
    return (
        <div >
            <Container maxWidth="lg">
                <Grid container spacing={3} justify='center'>
               
                </Grid>

            </Container>
        </div>
    );

}

export default withRouter(CheckResult);