import { withRouter, useHistory } from "react-router-dom"
import React, { useContext, useState, useEffect } from "react";
import Context from '../Context'
import Container from '@material-ui/core/Container';
import clsx from 'clsx';
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "../components/MainTheme.js";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Divider } from '@material-ui/core';
import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import InputAdornment from '@material-ui/core/InputAdornment';
import AccountCircle from '@material-ui/icons/AccountCircle';
import HomeIcon from '@material-ui/icons/Home';
import PhoneIcon from '@material-ui/icons/Phone';
import PublicIcon from '@material-ui/icons/Public';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { useForm } from "react-hook-form";
import { Button, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import VisibilityIcon from '@material-ui/icons/Visibility';
import InputLabel from '@material-ui/core/InputLabel';

function UserProfile() {

    const history = useHistory();
    const classes = useStyles();
    const [image, setImage] = useState(null);

    const [name, setName] = useState(null);
    const [userData, setUserData] = useState([]);
    const [surname, setSurName] = useState(null);
    const [country, setCountry] = useState("");
    const [city, setCity] = useState("");
    const [phone, setPhone] = useState("");
    const [birthDate, setBirthDate] = useState("");
    const [ready, setReady] = useState(false);
    const [loading, setLoading] = useState(false);

    const moment = require('moment');
    const { user } = useContext(Context)
    var date = null;
    const [isPassword, setIsPassword] = useState("password");

    const [errorName, setErrorName] = useState(false);
    const [errorSurname, setErrorSurname] = useState(false);
    const [errorPhone, setErrorPhone] = useState(false);
    const [gotData, setGotData] = useState(false);


    const [file, setFile] = useState("");

    const maxHeightPaper = clsx(classes.paper, classes.maxHeight);

    const { register, handleSubmit, getValues, formState: { errors } } = useForm()

    useEffect(() => {
        getData()
    }, [])

    async function getData() {

        await fetch(`/back-end/public/api/getWpUserMeta`, {
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
            },
        }).then(
            response => response.json()).then(
                json => setUserData(json
            )).then(()=> setGotData(true))
    }


    useEffect(() => {

        async function getUserData() {
            setName(userData.first_name[0].meta_value)
            setSurName(userData.last_name[0].meta_value)
            if (userData.birthdate) {
                var darr = userData.birthdate[0].meta_value.split("/");    // ["25", "09", "2019"]
                var ISOFormat = new Date(parseInt(darr[2]), parseInt(darr[1]) - 1, parseInt(darr[0]));
                date = ISOFormat.toISOString()
                setBirthDate(moment(date).format('YYYY-MM-DD'))
            }
            if (userData.birthplace)
                setCountry(userData.birthplace[0].meta_value)
            if (userData.birthcity)
                setCity(userData.birthcity[0].meta_value)
            if (userData.billing_phone)
                setPhone(userData.billing_phone[0].meta_value)

            setReady(true)
        }
        if (userData && userData.first_name && gotData) {
            getUserData()
        }
        if(gotData && user) {
            setName(user.first_name)
            setSurName(user.last_name)
            setReady(true)
        }
    }, [gotData])

    const onSubmit = async data => {

        setLoading(true)

        if (name.trim() === "")
            setErrorName(true)
        else setErrorName(false)
        if (surname.trim() === "")
            setErrorSurname(true)
        else setErrorSurname(false)
        if (phone.trim() === "")
            setErrorPhone(true)

        else if (name.trim() !== "" && surname.trim() !== "" && phone.trim() !== "") {

            const formData = new FormData();

            formData.append('name', name)
            formData.append('surname', surname)
            if (country.trim() !== '')
                formData.append('country', country)
            if (birthDate.trim() !== '')
                formData.append('birth_date', birthDate)
            if (city.trim() !== '')
                formData.append('city', city)
            formData.append('phone', phone)
            if (file !== '')
                formData.append('file', file)



            await fetch("/back-end/public/api/addData", {
                method: "POST",
                body: formData
            })



            if (data.password && data.password.trim() !== "") {
                const formDataPassword = new FormData();

                formDataPassword.append('password', data.password)

                await fetch("/back-end/public/api/changePassword", {
                    method: "POST",
                    body: formDataPassword
                })
            }


            if (user && user.user_type === 'USR')
                history.replace("/home")

            else if (user && user.user_type === 'ADM')
                history.replace("/admin/corsi");


        }

        setLoading(false)
    }



    const Imagefile = {
        beforeUpload: file => {
            setFile(file)
            setImage(URL.createObjectURL(file))
            return false;
        }
    };

    function removedImage() {
        setImage(null)
        setFile(null)
    }


    function showPassword() {
        if (isPassword === 'password')
            setIsPassword("text")
        else setIsPassword("password")
    }

    return (
        <Container maxWidth="lg" >
            <Grid container spacing={3} justify='center'>
                <Grid item xs={12} lg={8} >

                    <Paper className={maxHeightPaper} >
                        <form className={classes.root} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">

                            <Grid item xs={12} lg={12} style={{ display: 'flex', justifyContent: 'space-betwwen' }}>
                                <Typography style={{ padding: '10px 0 10px 20px' }} variant="h6" className={classes.title}>
                                Dettagli account
                                </Typography>
                            </Grid>
                            <Divider variant='fullWidth' />
                            {ready ?
                                <Grid item xs={12} lg={12}>
                                    <Grid item xs={12} lg={12} style={{ display: 'flex', justifyContent: 'space-between', padding: '20px 0 20px 20px' }}>
                                        <Grid item xs={12} sm={5} lg={9} style={{
                                            display: 'grid', gridGap: '20px'
                                        }}>
                                            <TextField
                                                variant="outlined"
                                                size="small"
                                                id="first_name"
                                                label='Nome'
                                                required
                                                defaultValue={userData && userData.first_name ? userData.first_name[0].meta_value : (name ? name : null)}
                                                InputLabelProps={{
                                                    style: { color: '#3f51b5' },
                                                    classes: {
                                                        asterisk: classes.asterisk
                                                    }

                                                }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <AccountCircle fontSize="small" style={{ color: '#212121' }} />
                                                        </InputAdornment>
                                                    ),
                                                    classes: {
                                                        disabled: classes.profileText,
                                                        input: classes.profileText,

                                                    }

                                                }}
                                                onChange={(e) => setName(e.target.value)}
                                                error={errorName ? true : false}
                                                helperText={errorName ? 'Il campo è obbligatorio' : null}
                                            />
                                            <TextField
                                                variant="outlined"
                                                size="small"
                                                id="last_name"
                                                label='Cognome'
                                                required
                                                defaultValue={userData && userData.last_name ? userData.last_name[0].meta_value : (surname ? surname : null)}
                                                InputLabelProps={{
                                                    style: { color: '#3f51b5' },
                                                    classes: {
                                                        asterisk: classes.asterisk
                                                    }

                                                }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <AccountCircle fontSize="small" style={{ color: '#212121' }} />
                                                        </InputAdornment>
                                                    ),
                                                    classes: {
                                                        disabled: classes.profileText,
                                                        input: classes.profileText,

                                                    }

                                                }}
                                                onChange={(e) => setSurName(e.target.value)}
                                                error={errorSurname ? true : null}
                                                helperText={errorSurname ? 'Il campo è obbligatorio' : null}
                                            />
                                            <TextField
                                                variant="outlined"
                                                size="small"
                                                id="date"
                                                label='Data di nascita'
                                                InputLabelProps={{
                                                    style: { color: '#3f51b5' },
                                                    classes: {
                                                        asterisk: classes.asterisk
                                                    }

                                                }}
                                                type='date'
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <CalendarTodayIcon fontSize="small" style={{ color: '#212121' }} />
                                                        </InputAdornment>
                                                    ),
                                                    classes: {
                                                        disabled: classes.profileText,
                                                        input: classes.profileText,

                                                    }

                                                }}
                                                onChange={(e) => setBirthDate(e.target.value)}
                                                defaultValue={birthDate ? birthDate : null}
                                            />
                                            <TextField
                                                variant="outlined"
                                                size="small"
                                                id="birth_country"
                                                label='Luogo di nascita'
                                                InputLabelProps={{
                                                    style: { color: '#3f51b5' },
                                                    classes: {
                                                        asterisk: classes.asterisk
                                                    }

                                                }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <PublicIcon fontSize="small" style={{ color: '#212121' }} />
                                                        </InputAdornment>
                                                    ),
                                                    classes: {
                                                        disabled: classes.profileText,
                                                        input: classes.profileText,

                                                    }

                                                }}
                                                onChange={(e) => setCountry(e.target.value)}
                                                defaultValue={userData && userData.birthplace ? userData.birthplace[0].meta_value : null}
                                            />
                                            <TextField
                                                variant="outlined"
                                                size="small"
                                                id="birth_city"
                                                label='Birth city'
                                                InputLabelProps={{
                                                    style: { color: '#3f51b5' },
                                                    classes: {
                                                        asterisk: classes.asterisk
                                                    }

                                                }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <HomeIcon fontSize="small" style={{ color: '#212121' }} />
                                                        </InputAdornment>
                                                    ),
                                                    classes: {
                                                        disabled: classes.profileText,
                                                        input: classes.profileText,

                                                    }

                                                }}
                                                onChange={(e) => setCity(e.target.value)}
                                                defaultValue={userData && userData.birthcity ? userData.birthcity[0].meta_value : null}
                                            />
                                            <TextField
                                                variant="outlined"
                                                size="small"
                                                id="phone"
                                                label='telefono'
                                                type='tel'
                                                required
                                                InputLabelProps={{
                                                    style: { color: '#3f51b5' },
                                                    classes: {
                                                        asterisk: classes.asterisk
                                                    }

                                                }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <PhoneIcon fontSize="small" style={{ color: '#212121' }} />
                                                        </InputAdornment>
                                                    ),
                                                    classes: {
                                                        disabled: classes.profileText,
                                                        input: classes.profileText,

                                                    }

                                                }}
                                                onChange={(e) => setPhone(e.target.value)}
                                                defaultValue={userData && userData.billing_phone ? userData.billing_phone[0].meta_value : null}
                                                error={errorPhone ? true : null}
                                                helperText={errorPhone ? 'Il campo è obbligatorio' : null}
                                            />
                                            <InputLabel htmlFor="age-native-helper">Cambia password</InputLabel>
                                            <TextField
                                                {...register('password')}
                                                variant="outlined"
                                                size="small"
                                                id="pass"
                                                label='Password'
                                                type={isPassword}
                                                InputLabelProps={{
                                                    style: { color: '#3f51b5' },
                                                    classes: {
                                                        asterisk: classes.asterisk
                                                    }

                                                }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            {isPassword === "password" ?
                                                                <VisibilityOffIcon fontSize="small" style={{ color: '#212121', cursor: 'pointer' }} onClick={() =>
                                                                    showPassword()} /> :
                                                                <VisibilityIcon fontSize="small" style={{ color: '#212121', cursor: 'pointer' }} onClick={() =>
                                                                    showPassword()} />}
                                                        </InputAdornment>
                                                    ),
                                                    classes: {
                                                        disabled: classes.profileText,
                                                        input: classes.profileText,

                                                    }

                                                }}
                                            />
                                            <TextField
                                                {...register('confirme_password', {
                                                    validate: {
                                                        passwordMatch: value => (value === getValues().password) || 'The passwords do not match',
                                                    }
                                                })}
                                                variant="outlined"
                                                size="small"
                                                id="confirm_pass"
                                                label='Conferma password'
                                                type={isPassword}
                                                InputLabelProps={{
                                                    style: { color: '#3f51b5' },
                                                    classes: {
                                                        asterisk: classes.asterisk
                                                    }

                                                }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            {isPassword === "password" ?
                                                                <VisibilityOffIcon fontSize="small" style={{ color: '#212121', cursor: 'pointer' }} onClick={() =>
                                                                    showPassword()} /> :
                                                                <VisibilityIcon fontSize="small" style={{ color: '#212121', cursor: 'pointer' }} onClick={() =>
                                                                    showPassword()} />}
                                                        </InputAdornment>
                                                    ),
                                                    classes: {
                                                        disabled: classes.profileText,
                                                        input: classes.profileText,

                                                    }

                                                }}

                                                error={errors?.confirme_password?.type === 'passwordMatch' && true}
                                                helperText={errors?.confirme_password?.type === 'passwordMatch' && errors?.confirme_password?.message}
                                            />

                                        </Grid>
                                        <Grid item xs={12} sm={5} lg={4}>
                                            <Grid item xs={12} lg={12} style={{
                                                textAlign: 'center', padding: '10px'
                                            }} >
                                                <Avatar src={image}
                                                    style={{ lineHeight: '60px' }} size={100} icon={<UserOutlined style={{ fontSize: '55px', marginTop: '20px' }} />} />

                                                <div style={{ marginTop: '1rem' }}>
                                                    <Upload
                                                        {...Imagefile}
                                                        listType="picture"
                                                        maxCount={1}
                                                        accept='image/*'
                                                        onRemove={removedImage} >
                                                        <Button icon={<UploadOutlined />} >Upload Immagine</Button>
                                                    </Upload>
                                                </div>
                                            </Grid>

                                        </Grid>

                                    </Grid>
                                    <Grid item xs={12} style={{ textAlign: 'center' }} >
                                        <Button
                                            htmlType="submit"
                                            type="primary"
                                            className={classes.button}
                                            loading={loading}
                                            style={{ width: '120px' }}
                                        >
                                            Salva
                                        </Button>
                                    </Grid>
                                </Grid> : null}
                        </form>

                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
}

export default withRouter(UserProfile);
