import { makeStyles } from '@material-ui/core/styles';


export const drawerWidth = 240;

export const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
    },

    demo: {
        backgroundColor: theme.palette.background.paper,
    },
    table: {
        minWidth: 650,

    },
    formControl: {
        margin: theme.spacing(1),
        width: '100%'
    },

    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    paper: {
        width: '100%',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    large: {
        width: theme.spacing(18),
        height: theme.spacing(18),
    },
    icon: {
        width: 20,
        height: 20,
        marginBottom: '4px'
    },
    BreadcrumbsPaper: {
        minHeight: '120px',
        height: 'fit-content',
        gridRow: 2,
        display: 'grid',
        padding: '10px 20px 10px 20px',
    },
    profileText: {
        color: '#212121',
    },
    asterisk: {
        color: "red"
    },
    inputImage: {
        display: 'none',
    },

    media: {
        height: 140,
    },
    cardHeight: {
        maxWidth: 345,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    }
    , heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },

    modulesName: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',

    }, 
    iframePPt: {
        [theme.breakpoints.up('md')]: {
            height:'550px',
            width:'900px'
          },
          [theme.breakpoints.down('md')]: {
            height:'550px',
            width:'800px'
          },
          [theme.breakpoints.down('sm')]: {
            height:'450px',
            width:'600px'
          },
          [theme.breakpoints.down('xs')]: {
            height:'330px',
            width:'330px'
          },
    }, 
    nextPrev:{
        [theme.breakpoints.down('sm')]: {
            display:"none"
          },
    }

}
)
);
