import { withRouter } from "react-router-dom"
import React, { useContext, useState, useEffect } from "react";
import Context from '../Context'
import Container from '@material-ui/core/Container';
import clsx from 'clsx';
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "../components/MainTheme.js";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Divider } from '@material-ui/core';
import { Avatar, Popconfirm } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';
import InputAdornment from '@material-ui/core/InputAdornment';
import AccountCircle from '@material-ui/icons/AccountCircle';
import HomeIcon from '@material-ui/icons/Home';
import PhoneIcon from '@material-ui/icons/Phone';
import PublicIcon from '@material-ui/icons/Public';
import Text from "../components/Text";
import MuiAlert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar';
import { Button, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { useForm } from "react-hook-form";
import VisibilityIcon from '@material-ui/icons/Visibility';
import InputLabel from '@material-ui/core/InputLabel';
import Breadcrumb from "../components/Breadcrumb";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

function Settings(props) {

    const classes = useStyles();

    const { user } = useContext(Context)
    const { register, handleSubmit, getValues, reset, formState: { errors } } = useForm()

    const { register: register2, handleSubmit: handleSubmit2, reset: reset2, formState: { errors: errors2 } } = useForm()

    const maxHeightPaper = clsx(classes.paper, classes.maxHeight);

    const moment = require('moment');

    const [open, setOpen] = useState(false);
    const [successSubmit, setSuccessSubmit] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [error, setError] = useState(false);
    const [adminProfile, setAdminProfile] = useState([]);
    const [disable, setDisable] = useState(true)
    const [savedPassword, setSavedPassword] = useState(false);

    const [name, setName] = useState("");
    const [surname, setSurName] = useState("");
    const [users, setUsers] = useState([]);

    const [loading, setLoading] = useState(false);
    const [errorName, setErrorName] = useState(false);
    const [errorSurname, setErrorSurname] = useState(false);
    const [errorPhone, setErrorPhone] = useState(false);
    const [isPassword, setIsPassword] = useState("password");
    const [errorNetwork, setErrorNetwork] = useState(false);


    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    useEffect(() => {
        getData()
    }, [])

  
    useEffect(() => {

        if (adminProfile.length > 0)
            adminProfile.map((item) => {
                setName(item.first_name)
                setSurName(item.last_name)

            })

    }, [adminProfile])

    async function getData() {


        if (user) {
            await fetch(`/back-end/public/api/getAdminProfile`).then(
                response => response.json()).then(
                    json => setAdminProfile(json)
                )

            await fetch(`/back-end/public/api/getAdminUsers`).then(
                response => response.json()).then(
                    json => setUsers(json)
                )
        }

    }
    const addAdmin = async data => {

        setLoading(true)

        const formData = new FormData();

        formData.append('firstName', data.first_name)
        formData.append('lastName', data.last_name)
        formData.append('email', data.email)
        formData.append('user_type', 'ADM')
        formData.append('email_verified', '1')

        await fetch("/back-end/public/api/register", {
            method: "POST",
            body: formData
        }).then((response) => response.json()).then((rpns) => {
            if (rpns[0].msg === "success") {
                setEmailSent(true)
                reset2();
                getData()
            } else if (rpns[0].msg === "error") {
                setError(true)
            } else {
                setErrorNetwork(true) 
            }
        })

        setLoading(false)
    }

    const onSubmit = async data => {
        setLoading(true)

        const formDataPassword = new FormData();

        formDataPassword.append('password', data.password)
        formDataPassword.append('user_id', user.id)

        await fetch("/back-end/public/api/changeAdminPassword", {
            method: "POST",
            body: formDataPassword
        }).then(
            response => response.json()).then(
                json => {
                    if (json.msg === 'success') {
                        setSavedPassword(true)
                        reset();
                    }
                    else setError(true)
                }
            )

        setLoading(false)
    }
    async function submit() {

        setLoading(true)

        if (name.trim() === "")
            setErrorName(true)
        else setErrorName(false)
        if (surname.trim() === "")
            setErrorSurname(true)
        else setErrorSurname(false)

        if (name.trim() !== "" && surname.trim() !== "") {

            const formData = new FormData();

            formData.append('name', name)
            formData.append('surname', surname)

            await fetch("/back-end/public/api/updateUserData", {
                method: "POST",
                body: formData
            }).then((response) => response.json()).then((json) => {
                if (json.msg === "success") {
                    setAdminProfile([])
                    getData()
                    setDisable(true)
                    setSuccessSubmit(true)
                } else {
                    getData()
                    setDisable(true)
                    setError(true)
                }
            })

        }
        setLoading(false)
    }

    const handleCloseSubmit = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSavedPassword(false)
        setSuccessSubmit(false);
        setEmailSent(false);
    }
    const handleError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setError(false);
        setErrorNetwork(false);
    }
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    function showPassword() {
        if (isPassword === 'password')
            setIsPassword("text")
        else setIsPassword("password")
    }

    async function deleteAdmin(id)
    {
        await fetch(`/back-end/public/api/deleteAdmin/${id}`, {
            method: "DELETE"
        }).then(() => getData())
    }

    return (
        <Container maxWidth="lg">
            <Grid container spacing={3} >

                <Grid item xs={12} lg={6}>
                    <Breadcrumb />

                    <Paper className={maxHeightPaper}>
                        <Grid item xs={12} lg={12} style={{ display: 'flex', justifyContent: 'space-betwwen' }}>
                            <Typography style={{ padding: '10px 0 10px 20px' }} variant="h6" className={classes.title}>

                            Dettagli account
                            </Typography>

                            <Snackbar open={successSubmit} autoHideDuration={3000} onClose={handleCloseSubmit} >
                                <Alert onClose={handleCloseSubmit} severity="success">
                                Il profile è stato aggiornato correttamente
                                </Alert>
                            </Snackbar>
                            <Snackbar open={emailSent} autoHideDuration={3000} onClose={handleCloseSubmit} >
                                <Alert onClose={handleCloseSubmit} severity="success">
                                Email inviata
                                </Alert>
                            </Snackbar>
                            <Snackbar open={savedPassword} autoHideDuration={3000} onClose={handleCloseSubmit} >
                                <Alert onClose={handleCloseSubmit} severity="success">
                                La password è stata aggiornata
                                </Alert>
                            </Snackbar>
                            <Snackbar open={error} autoHideDuration={3000} onClose={handleError} >
                                <Alert onClose={handleError} severity="error">
                                    ASi è verificato un errore, riprova
                                </Alert>
                            </Snackbar>
                            <Snackbar open={errorNetwork} autoHideDuration={2000} onClose={handleError} >
                                <Alert onClose={handleError} severity="error">
                                Si è verificato un errore, riprova
                                </Alert>
                            </Snackbar>
                        </Grid>

                        <Divider variant='fullWidth' />
                        {adminProfile.length > 0 ?
                            <form noValidate autoComplete="off">
                                {adminProfile.filter(item => item !== null).map((item, index) =>

                                    <Grid key={index} item xs={12} lg={12} style={{ display: 'flex', justifyContent: 'space-between', padding: '20px 20px 20px 20px' }}>
                                        <Grid item xs={12} sm={12} lg={12} style={{
                                            display: 'grid', gridGap: '20px'
                                        }} >

                                            <TextField
                                                variant="outlined"
                                                size="small"
                                                id="first-name"
                                                label='Nome'
                                                defaultValue={item.first_name}
                                                required
                                                InputLabelProps={{
                                                    style: { color: '#3f51b5' },
                                                    classes: {
                                                        asterisk: classes.asterisk
                                                    }

                                                }}
                                                onChange={(e) => setName(e.target.value)}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <UserOutlined fontSize="small" style={{ color: '#212121' }} />
                                                        </InputAdornment>
                                                    ),
                                                    classes: {
                                                        disabled: classes.profileText,
                                                        input: classes.profileText,

                                                    }

                                                }}
                                                error={errorName ? true : false}
                                                helperText={errorName ? 'Il campo è obbligatorio' : null}
                                            />

                                            <TextField
                                                variant="outlined"
                                                size="small"
                                                id="last-name"
                                                label='Cognome'
                                                defaultValue={item.last_name}
                                                required
                                                InputLabelProps={{
                                                    style: { color: '#3f51b5' },
                                                    classes: {
                                                        asterisk: classes.asterisk
                                                    }

                                                }}
                                                onChange={(e) => setSurName(e.target.value)}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <UserOutlined fontSize="small" style={{ color: '#212121' }} />
                                                        </InputAdornment>
                                                    ),
                                                    classes: {
                                                        disabled: classes.profileText,
                                                        input: classes.profileText,

                                                    }

                                                }}
                                                error={errorSurname ? true : null}
                                                helperText={errorSurname ? 'Il campo è obbligatorio' : null}

                                            />

                                            <TextField
                                                size="small"
                                                id="email"
                                                label='E-mail'
                                                disabled={true}
                                                value={user.email}

                                            />


                                            <Grid item xs={12} style={{ textAlign: 'center' }} >
                                                <Button
                                                    onClick={submit}
                                                    type="primary"
                                                    className={classes.button}
                                                    loading={loading}
                                                    style={{ width: '120px' }}
                                                >
                                                    Aggiorna
                                                </Button>
                                            </Grid>

                                        </Grid>

                                    </Grid>

                                )}  </form> : null}
                    </Paper>
                </Grid>
                {adminProfile.length > 0 ?
                    <Grid item xs={12} lg={5}>
                        <Grid item xs={12} lg={12}>
                            <Paper className={maxHeightPaper} style={{ marginTop: '25px' }}>
                                <Grid item xs={12} lg={12} style={{ display: 'flex', justifyContent: 'space-betwwen' }}>
                                    <Typography style={{ padding: '10px 0 10px 20px' }} variant="h6" className={classes.title}>
                                    Cambia password
                                    </Typography>
                                </Grid>
                                <Divider variant='fullWidth' />
                                <form className={classes.root} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                                    <Grid item xs={12} sm={5} lg={12} style={{
                                        display: 'grid', gridGap: '20px', padding: '20px 20px 20px 20px'
                                    }}>
                                        <TextField
                                            {...register('password', { required: true })}
                                            variant="outlined"
                                            size="small"
                                            id="password"
                                            label='Password'
                                            type={isPassword}
                                            InputLabelProps={{
                                                style: { color: '#3f51b5' },
                                                classes: {
                                                    asterisk: classes.asterisk
                                                }

                                            }}
                                            required
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        {isPassword === "password" ?
                                                            <VisibilityOffIcon fontSize="small" style={{ color: '#212121', cursor: 'pointer' }} onClick={() =>
                                                                showPassword()} /> :
                                                            <VisibilityIcon fontSize="small" style={{ color: '#212121', cursor: 'pointer' }} onClick={() =>
                                                                showPassword()} />}
                                                    </InputAdornment>
                                                ),
                                                classes: {
                                                    disabled: classes.profileText,
                                                    input: classes.profileText,

                                                }

                                            }}
                                            error={errors?.password?.type === "required" && true}
                                            helperText={errors?.password?.type === "required" && 'Il campo è obbligatorio'}
                                        />
                                        <TextField
                                            {...register('confirme_password', {
                                                required: true,
                                                validate: {
                                                    passwordMatch: value => (value === getValues().password) || 'The passwords do not match',
                                                }
                                            })}
                                            required
                                            variant="outlined"
                                            size="small"
                                            id="confirme_password"
                                            label='Conferma password'
                                            type={isPassword}
                                            InputLabelProps={{
                                                style: { color: '#3f51b5' },
                                                classes: {
                                                    asterisk: classes.asterisk
                                                }

                                            }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        {isPassword === "password" ?
                                                            <VisibilityOffIcon fontSize="small" style={{ color: '#212121', cursor: 'pointer' }} onClick={() =>
                                                                showPassword()} /> :
                                                            <VisibilityIcon fontSize="small" style={{ color: '#212121', cursor: 'pointer' }} onClick={() =>
                                                                showPassword()} />}
                                                    </InputAdornment>
                                                ),
                                                classes: {
                                                    disabled: classes.profileText,
                                                    input: classes.profileText,

                                                }

                                            }}

                                            error={(errors?.confirme_password?.type === 'passwordMatch' && true) || (errors?.confirme_password?.type === "required" && true)}
                                            helperText={(errors?.confirme_password?.type === 'passwordMatch' && errors?.confirme_password?.message) ||
                                                (errors?.confirme_password?.type === "required" && 'Il campo è obbligatorio')}
                                        />
                                    </Grid>

                                    <Grid item xs={12} style={{ textAlign: 'center', paddingBottom: '20px' }} >
                                        <Button
                                            htmlType="submit"
                                            type="primary"
                                            className={classes.button}
                                            loading={loading}
                                            style={{ width: '120px' }}
                                        >
                                            Aggiorna
                                        </Button>
                                    </Grid>
                                </form>
                            </Paper>
                        </Grid>
                    </Grid> : null}
                {adminProfile.length > 0 ?
                    <Grid item xs={12} lg={4}>
                        <Grid item xs={12} lg={12}>
                            <Paper className={maxHeightPaper}>
                                <Grid item xs={12} lg={12} style={{ display: 'flex', justifyContent: 'space-betwwen' }}>
                                    <Typography style={{ padding: '10px 0 10px 20px' }} variant="h6" className={classes.title}>
                                        Add admin
                                    </Typography>

                                </Grid>

                                <Divider variant='fullWidth' />
                                <form className={classes.root} onSubmit={handleSubmit2(addAdmin)} noValidate autoComplete="off"  >
                                    {adminProfile.filter(item => item !== null).map((item, index) =>

                                        <Grid key={index} item xs={12} lg={12} style={{ display: 'flex', justifyContent: 'space-between', padding: '20px 20px 20px 20px' }}>
                                            <Grid item xs={12} sm={12} lg={12} style={{
                                                display: 'grid', gridGap: '20px'
                                            }} >

                                                <TextField
                                                    {...register2('first_name', { required: true })}
                                                    variant="outlined"
                                                    size="small"
                                                    id="firstName"
                                                    label='Nome'
                                                    required
                                                    InputLabelProps={{
                                                        style: { color: '#3f51b5' },
                                                        classes: {
                                                            asterisk: classes.asterisk
                                                        }

                                                    }}
                                                    error={errors2?.first_name?.type === "required" && true}
                                                    helperText={errors2?.first_name?.type === "required" && 'Il campo è obbligatorio'}
                                                />

                                                <TextField
                                                    {...register2('last_name', { required: true })}
                                                    variant="outlined"
                                                    size="small"
                                                    id="lastName"
                                                    label='Cognome'
                                                    required
                                                    InputLabelProps={{
                                                        style: { color: '#3f51b5' },
                                                        classes: {
                                                            asterisk: classes.asterisk
                                                        }

                                                    }}

                                                    error={errors2?.last_name?.type === "required" && true}
                                                    helperText={errors2?.last_name?.type === "required" && 'Il campo è obbligatorio'}
                                                />

                                                <TextField
                                                    {...register2('email', { required: true })}
                                                    variant="outlined"
                                                    size="small"
                                                    id="emailAdmin"
                                                    label='Email'
                                                    required
                                                    InputLabelProps={{
                                                        style: { color: '#3f51b5' },
                                                        classes: {
                                                            asterisk: classes.asterisk
                                                        }

                                                    }}
                                                    error={errors2?.email?.type === "required" && true}
                                                    helperText={errors2?.email?.type === "required" && 'Il campo è obbligatorio'}
                                                />
                                                <Grid item xs={12} style={{ textAlign: 'center' }} >
                                                    <Button
                                                        htmlType="submit"
                                                        type="primary"
                                                        className={classes.button}
                                                        loading={loading}
                                                        style={{ width: '120px' }}
                                                    >
                                                        Aggiungi
                                                    </Button>
                                                </Grid>

                                            </Grid>

                                        </Grid>

                                    )}  </form>
                            </Paper>

                        </Grid>
                    </Grid>
                    : null}

                {adminProfile.length > 0 ?
                    <Grid item xs={12} lg={12}>
                        <Paper className={maxHeightPaper}>
                            <Grid item xs={12} lg={12} style={{ display: 'flex', justifyContent: 'space-betwwen' }}>
                                <Typography style={{ padding: '10px 0 10px 20px' }} variant="h6" className={classes.title}>
                                Amministratore
                                </Typography>

                            </Grid>

                            <Divider variant='fullWidth' />
                        </Paper>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell >#</TableCell>
                                        <TableCell >Nome</TableCell>
                                        <TableCell >Cognome</TableCell>
                                        <TableCell >Email</TableCell>
                                        <TableCell>Data invio</TableCell>
                                        <TableCell>Elimina</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {users.map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell >{index + 1}
                                            </TableCell>
                                            <TableCell >
                                                {item.first_name}
                                            </TableCell>
                                            <TableCell >
                                                {item.last_name}
                                            </TableCell>
                                            <TableCell >
                                                {item.email}
                                            </TableCell>
                                            <TableCell>
                                                {moment(item.created_at).format('DD/MM/YYYY, h:mm:ss a')}
                                            </TableCell>
                                          
                                            <TableCell>
                                                  <Popconfirm
                                                    onConfirm={() => deleteAdmin(item.id)}
                                                    title="Sei sicuro? " okText="Si" cancelText="No">
                                                    <Button type="link"  style={{padding: "0px"}}>Elimina</Button>
                                                </Popconfirm>
                                            </TableCell> 
                                         
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid> : null}

            </Grid >
        </Container >

    );
}

export default withRouter(Settings);
