import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import clsx from 'clsx';
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { useForm } from "react-hook-form";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useStyles } from "../components/MainTheme.js";
import MuiAlert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar';
import { Button, Popconfirm } from 'antd';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 'fit-content',
      marginLeft: "5.6rem"
    },
  },
};


export default function Register() {


  const classes = useStyles();
  const { register, handleSubmit, reset, formState: { errors } } = useForm()

  const [studentNotEnrolled, setStudentNotEnrolled] = useState([]);
  const maxHeightPaper = clsx(classes.paper, classes.maxHeight);
  const [successSubmit, setSuccessSubmit] = useState(false);
  const [error, setError] = useState(false);
  const [errorNetwork, setErrorNetwork] = useState(false);
  const [sending, setSending] = useState(false);

  const [courses, setCourses] = useState([]);
  const [coursesID, setCoursesID] = useState([]);
  var coursesIDArray = [];
  const moment = require('moment');

  useEffect(() => {
    getData()
  }, [])

  async function getData() {

    await fetch("/back-end/public/api/getUserSentEmail").then(
      response => response.json()).then(
        json => setStudentNotEnrolled(json)
      )

    await fetch("/back-end/public/api/courses").then(
      response => response.json()).then(
        json => setCourses(json)
      )

  }
  const [selectedCourses, setSelectedCourses] = useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    for (var i = 0; i < value.length; i++)
      coursesIDArray.push(courses.filter(item => item.title === value[i]).map((item) => { return item.id })[0])

    setSelectedCourses(
      typeof value === 'string' ? value.split(',') : value,
    );
    setCoursesID(coursesIDArray)
  };

  const onSubmit = async data => {

    setSending(true)

    const formData = new FormData();

    formData.append('firstName', data.firstName)
    formData.append('lastName', data.lastName)
    formData.append('email', data.email)
    formData.append('courses', JSON.stringify(selectedCourses))
    formData.append('coursesID', JSON.stringify(coursesID))

    await fetch("/back-end/public/api/register", {
      method: "POST",
      body: formData
    }).then((response) => response.json()).then((rpns) => {
      if (rpns[0].msg === "success") {
        setSuccessSubmit(true)
        reset();
        setSelectedCourses([])
        getData()
        setSending(false)
      } else if (rpns[0].msg === "error") {
        setSending(false)
        setError(true)
      } else {
        setSending(false)
        setErrorNetwork(true)
      }
    })
  }

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const style = {
    padding: '10px',
  }

  const handleCloseSubmit = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSuccessSubmit(false);
  }
  const handleError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setError(false);
    setErrorNetwork(false);
  }

  async function deleteAdmin(id) {
    await fetch(`/back-end/public/api/deleteAdmin/${id}`, {
      method: "DELETE"
    }).then(() => getData())
  }

  return (
    <Container maxWidth="lg">
      <Grid container spacing={3} justify='center'>

        <Snackbar open={successSubmit} autoHideDuration={4000} onClose={handleCloseSubmit} >
          <Alert onClose={handleCloseSubmit} severity="success">
            Email inviata correttamente!
          </Alert>
        </Snackbar>
        <Snackbar open={error} autoHideDuration={2000} onClose={handleError} >
          <Alert onClose={handleError} severity="error">
            Hai già inviato una mail!
          </Alert>
        </Snackbar>
        <Snackbar open={errorNetwork} autoHideDuration={2000} onClose={handleError} >
          <Alert onClose={handleError} severity="error">
          Qualcosa è andato storto, riprova
          </Alert>
        </Snackbar>

        <Grid item xs={12} lg={4} >
          <Paper className={maxHeightPaper} style={style} >
            <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
              <Grid item xs={12} lg={12}>
                <TextField
                  {...register('firstName', {
                    required: true,
                  })}
                  fullWidth
                  placeholder="Nome"
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size='small'
                  variant="outlined"
                  required
                  id="firstName"
                  label="Nome"
                  type="text"
                  name="firstName"
                  error={(errors?.firstName?.type === "required" && true)}
                  helperText={(errors?.firstName?.type === "required" && 'Questo campo è obbligatorio')}
                />
                <TextField
                  {...register('lastName', {
                    required: true,
                  })}
                  fullWidth
                  placeholder="Cognome"
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size='small'
                  variant="outlined"
                  required
                  id="lastName"
                  label="Cognome"
                  name="lastName"
                  type="text"
                  error={(errors?.lastName?.type === "required" && true)}
                  helperText={(errors?.lastName?.type === "required" && 'Questo campo è obbligatorio')}
                />
                <FormControl className={classes.formControl}
                  size='small'>
                  <InputLabel id="demo-multiple-checkbox-label">Corso</InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={selectedCourses}
                    onChange={handleChange}
                    input={<OutlinedInput label="Corso" />}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                  >
                    {courses.map((item) => (
                      <MenuItem key={item.id} value={item.title}>
                        <Checkbox checked={selectedCourses.indexOf(item.title) > -1} />
                        <ListItemText primary={item.title} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  {...register('email', {
                    required: true,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "indirizzo email non valido"
                    }
                  })}
                  fullWidth
                  placeholder="email"
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size='small'
                  variant="outlined"
                  required
                  id="email"
                  label="E-Mail"
                  name="email"
                  type='email'
                  autoComplete="email"
                  error={(errors?.email?.type === "required" && true) || errors?.email?.message}
                  helperText={(errors?.email?.type === "required" && 'Questo campo è obbligatorio') || (errors?.email?.message && "invalid email address")}
                />

              </Grid>
              <Grid item xs={12} style={{ justifyContent: 'center', display: 'grid' }}>
                <Button
                  htmlType="submit"
                  type='primary'
                  className={classes.submit}
                  loading={sending} >
                  Aggiungi Corsista
                </Button>
              </Grid>
            </form>

          </Paper>
        </Grid>

        <Grid item xs={12} lg={8}>
          <Paper className={maxHeightPaper}>
            <TableContainer component={Paper}>
              <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell >#</TableCell>
                    <TableCell >Email inviata</TableCell>
                    <TableCell>Data invio</TableCell>
                    <TableCell>Email verificata</TableCell>
                    <TableCell>Elimina</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {studentNotEnrolled.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell >{index + 1}
                      </TableCell>
                      <TableCell >
                        {item.email}
                      </TableCell>
                      <TableCell>
                        {moment(item.created_at).format('DD/MM/YYYY, h:mm:ss a')}
                      </TableCell>
                      <TableCell>
                        {item.email_verified === 1 ? 'Si' : 'No'}
                      </TableCell>
                      <TableCell>
                        <Popconfirm
                          onConfirm={() => deleteAdmin(item.id)}
                          title="Sei sicuro?" okText="Si" cancelText="No">
                          <Button type="link" style={{ padding: "0px" }}>Elimina</Button>
                        </Popconfirm>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </Container >

  );
}
