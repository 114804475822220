import { withRouter } from "react-router-dom"
import React, { useState, useEffect } from "react";
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import { useStyles } from "../components/MainTheme.js";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import Grid from '@material-ui/core/Grid';

function UpdateModule(props) {


    const module_id = props.module_id
    const courseType = props.courseType
    const open = props.open

    const classes = useStyles();

    const [file, setFile] = useState(null);
    const [module, setModule] = useState(null);
    const [url, setUrl] = useState(null);

    const [loading, setLoading] = useState(false);


    const [download, setDownload] = useState({ checkedB: false });



    useEffect(() => {
        getData()
    }, [open])

    async function getData() {
        if (module_id)
            await fetch("/back-end/public/api/getModule/" + module_id).then(
                response => response.json()).then(
                    json => {
                        setModule(json)
                        console.log(json)
                        if(json.type === 'pdf')
                        {
                            if (json.download === 0)
                            setDownload({ ...download, checkedB: false });
                        else setDownload({ ...download, checkedB: true });
                    }
                    })
    }

    const handleChange = (event) => {
        setDownload({ ...download, [event.target.name]: event.target.checked });
    };

    async function update() {

        setLoading(true)

        const formData = new FormData();

        formData.append('module_name', module.module_name)
        formData.append('type', module.type)
        formData.append('id', module_id)
        if (module.time)
            formData.append('time', module.time)

        if(module.type === 'pdf')
        {
            formData.append('download', + download.checkedB)
            if (file)
            formData.append('file', file)
        }
        else if(module.type === 'ppt' || module.type === 'video')
        {
            formData.append("module_path", module.module_path);
        }
        

        await fetch("/back-end/public/api/updateModule", {
            method: "POST",
            body: formData
        }).then(
            response => response.json()).then(
                json => {
                    if (json.msg === 'success') {
                        setModule(null)
                        setLoading(false)
                        props.module_id_clear(null)
                        props.close(false)
                    }
                    else {
                        setLoading(false)
                    }
                }

            )

    }

    const handleClose = () => {
        setModule(null)
        setLoading(false)
        props.module_id_clear(null)
        props.close(false)
    };

    const pdf = {
        beforeUpload: file => {
            setFile(file)
            return false;
        }
    };

    return module ? (

        <Dialog key={props.module_id} fullWidth disableBackdropClick disableEscapeKeyDown open={open} onClose={handleClose}>
            <DialogTitle>Aggiorna module</DialogTitle>
            <DialogContent>
                <Grid item xs={12} sm={12} lg={12}>
                     <TextField
                        fullWidth
                        id="outlined-full-width"
                        label="Nome modulo"
                        placeholder="Nome modulo"
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        size='small'
                        variant="outlined"
                        required
                        defaultValue={module.module_name}
                        onChange={(e) => setModule({ ...module, module_name: e.target.value })}
                    />

                  {module.type === 'pdf'?
                    <Grid item xs={12} lg={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Grid item xs={12} lg={8}>
                            <Upload
                                {...pdf}
                                listType="pdf"
                                maxCount={1}
                                accept='pdf/*'
                                required >
                                <Button icon={<UploadOutlined />}>Aggiorna pdf</Button>
                            </Upload>

                        </Grid>
                        <Grid item sx={12} lg={4} style={{ display: 'flex' }}>
                            <Switch
                                checked={download.checkedB}
                                onChange={handleChange}
                                color="primary"
                                name="checkedB"
                                size="small"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                            <Typography>
                            Abilita lo scaricamento
                            </Typography>
                        </Grid>
                    </Grid> : null}

                    {module.type === 'video' || module.type === 'ppt'?
                        <TextField
                        fullWidth
                        id="outlined-full-width"
                        label="Url"
                        placeholder="Url"
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        size="small"
                        variant="outlined"
                        required
                        defaultValue={module.module_path}
                        onChange={(e) => setModule({ ...module, module_path: e.target.value })}
                     
                    /> : null}

                    {courseType && courseType.type === 'Mandatory' && (module.type === 'pdf' || module.type === 'ppt') ?
                        <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                            <Grid item xs={6} sm={4} lg={4}>
                                <TextField
                                    id="outlined-full-width"
                                    label="Time in minutes"
                                    placeholder="Time"
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    size='small'
                                    variant="outlined"
                                    required
                                    type="number"
                                    InputProps={{ inputProps: { min: 0 } }}
                                    defaultValue={module.time}
                                    onChange={(e) => setModule({ ...module, time: e.target.value })}

                                />
                            </Grid>
                            <Grid item xs={6} sm={4} lg={4}>
                                <span style={{ marginLeft: '1rem' }}> Minimum time to read</span>
                            </Grid>
                        </Grid> :
                        null}

                </Grid>
            </DialogContent>
            <DialogActions>
                <Button disabled={loading} onClick={handleClose} >
                    Cancella
                </Button>
                <Button
                    loading={loading}
                    onClick={update}
                    type="primary"
                    className={classes.button} >
                    Salva
                </Button>
            </DialogActions>
        </Dialog>
    ) : null
}

export default withRouter(UpdateModule);
