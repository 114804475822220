import { withRouter, useParams, useHistory } from "react-router-dom"
import React, { useState, ReactElement, useEffect } from "react";
import Container from '@material-ui/core/Container';
import Breadcrumb from "../components/Breadcrumb";
import Grid from '@material-ui/core/Grid';
import { useStyles } from "../components/MainTheme.js";
import { Button} from "antd";


function ReadPdf(props) {

    const classes = useStyles();

    const { module_id } = useParams()
    const { course_id } = useParams()
    const [url, setUrl] = useState(null)

    const { section_id } = useParams();
    const history = useHistory();
    const [modules, setModules] = useState(null);
  


    useEffect(() => {
        getData()
    }, [])

    async function getData() {

        await fetch(`/back-end/public/api/getModulePathAdmin/${module_id}`).then(
            response => response.json()).then(
                path => setUrl(`${path.module_path}`)
            )
            await fetch(`/back-end/public/api/getModulesAdmin/${section_id}`)
            .then((response) => response.json())
            .then((json) => setModules(json));

    }

    function nextModule()
  {
    var index;
    var id;
    var type;
    index = modules && modules.findIndex(x => x.id === parseInt(module_id)) +1;
    modules && modules.map((item,i)=>{
        if(i === index)
            {
                id = item.id
                  type = item.type  }
        return true;
    })
   
    if(type === 'pdf')
    history.push({
        pathname: `/admin/corsi/${course_id}/sezioni/${section_id}/moduli/${id}/pdf`,
    });



      if(type === 'video')
      history.push({
        pathname: `/admin/corsi/${course_id}/sezioni/${section_id}/moduli/${id}/video`,
    });
    if(type === 'ppt')
    {history.push({
        pathname: `/admin/corsi/${course_id}/sezioni/${section_id}/moduli/${id}/ppt`,
    });
    window.location.reload(false);}
  }
  function prevModule()
  {
    var index;
    var id;
    var type;
    index = modules && modules.findIndex(x => x.id === parseInt(module_id)) -1;
   modules && modules.map((item,i)=>{
        if(i === index)
            {
                id = item.id
                  type = item.type  }
        return true;
    })

   
    if(type === 'pdf')
    history.push({
        pathname: `/admin/corsi/${course_id}/sezioni/${section_id}/moduli/${id}/pdf`,
    });
  

      if(type === 'video')
      history.push({
        pathname: `/admin/corsi/${course_id}/sezioni/${section_id}/moduli/${id}/video`,
    });
    if(type === 'ppt')
   { history.push({
        pathname: `/admin/corsi/${course_id}/sezioni/${section_id}/moduli/${id}/ppt`,
    });
    window.location.reload(false);}
  }

    return (
        <Container maxWidth="lg">
            <Grid container spacing={1} justify='center' >
            <Grid item xs={12} md={8} lg={9} >
            <Breadcrumb />
                </Grid>
               {url ?
                <Grid item xs={12} lg={9} style={{display:'flex', justifyContent:'end'}}>
          {modules && modules.findIndex(x => x.id === parseInt(module_id)) === 0 ? null : <Button size="small" type="primary" onClick={prevModule} style={{marginRight:'20px'}} 
         >precedente</Button>}
          {modules && modules.map(x => x.id).lastIndexOf(parseInt(module_id))+1 === modules.length ? null 
          : <Button size="small" type="primary" onClick={nextModule}>Prossimo modulo</Button>}
        </Grid> : null}
              <Grid item xs={12} md={12} lg={9} >
              
              {url ?
                  <iframe className={classes.iframePPt} src={url} frameborder="0" allowfullscreen="true" 
              mozallowfullscreen="true" webkitallowfullscreen="true">

              </iframe> : null}
              </Grid>
            {url ?
             <Grid item xs={12} lg={9} style={{display:'flex', justifyContent:'end'}}>
          {modules && modules.findIndex(x => x.id === parseInt(module_id)) === 0 ? null : <Button size="small" type="primary" onClick={prevModule} style={{marginRight:'20px'}} 
         >precedente</Button>}
          {modules && modules.map(x => x.id).lastIndexOf(parseInt(module_id))+1 === modules.length ? null 
          : <Button size="small" type="primary" onClick={nextModule}>Prossimo modulo</Button>}
        </Grid> : null}
            </Grid>
        </Container>
    );
}

export default withRouter(ReadPdf);
