import React from "react";
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Spin } from 'antd';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';


export default function LoadingPage() {


    return (
        <div>
            <AppBar position="static" style={{ backgroundColor: '#2999FB' }}>
                <Toolbar variant="dense">
                </Toolbar>
            </AppBar>
            <Container maxWidth="lg">
                <Grid container spacing={3} justify='center'>
                    <Grid item xs={12} lg={12} style={{ display: 'flex', justifyContent: 'center', alignItems: "center", minHeight: '100vh' }}>
                        <Spin size='large' />
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}
