import React, { useContext, useState, useEffect } from "react";
import Container from '@material-ui/core/Container';
import { withRouter, useHistory, useParams } from "react-router-dom"
import Grid from '@material-ui/core/Grid';
import Breadcrumb from "../components/Breadcrumb";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useStyles } from "../components/MainTheme.js";
import Paper from '@material-ui/core/Paper';
import { Result } from 'antd';
import { SmileOutlined } from '@ant-design/icons';
import Checkbox from '@material-ui/core/Checkbox';
import { Divider } from '@material-ui/core';
import clsx from 'clsx';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { Button } from 'antd';
import Context from '../Context'

function TestResult() {
    const classes = useStyles();

    const { section_id } = useParams()
    const { course_id } = useParams()
    const history = useHistory();

    const [questions, setQuestion] = useState([]);
    const [userAnswers, setUserAnswers] = useState([]);
    const [minutes, setMinutes] = useState('0')
    const maxHeightPaper = clsx(classes.paper, classes.maxHeight);
    const [answers, setAnswer] = useState([]);
    const [answerScore, setScoreAnswers] = useState([])
    const [testScore, setTestScore] = useState('')

    const [passedTest, setPassedTest] = useState(null)
    const [testTitle, setTestTitle] = useState(null)

    const { user } = useContext(Context)

    useEffect(() => {
        let isMounted = true;
        if (isMounted) getData()
        return () => { isMounted = false };
    }, [])

    async function getData() {

        if (section_id === '0') {
            await fetch(`/back-end/public/api/getUserTestTimeFinal/${course_id}`).then(
                response => response.json()).then(
                    json => {
                        if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                        else setMinutes(json)
                    }
                )

            await fetch(`/back-end/public/api/getPublishedQuestionFinal/${course_id}`).then(
                response => response.json()).then(
                    json => {
                        if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                        else setQuestion(json)
                    }
                )

            await fetch(`/back-end/public/api/getAnswerFinal/${course_id}`).then(
                response => response.json()).then(
                    json => {
                        if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                        else setAnswer(json)
                    }
                )


            if (user) {
                await fetch(`/back-end/public/api/getTestScoreFinal/${course_id}`).then(
                    response => response.json()).then(
                        json => {
                            if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                            else setScoreAnswers(json)
                        }
                    )

                await fetch(`/back-end/public/api/getTotalUserScoreQuestionFinal/${course_id}`).then(
                    response => response.json()).then(
                        json => {
                            if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                            else setTestScore(json)
                        }
                    )

                await fetch(`/back-end/public/api/getStudentAnswersFinal/${course_id}`).then(
                    response => response.json()).then(
                        json => {
                            if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                            else setUserAnswers(json)
                        }
                    )
            }

            await fetch(`/back-end/public/api/getTestTitleFinalUser/${course_id}`)
                .then((response) => response.json())
                .then(json => {
                    if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                    else {
                        setPassedTest(json.if_pass_test)
                        setTestTitle(json.title)
                    }
                }
                )

        }
        else {
            await fetch(`/back-end/public/api/getUserTestTime/${course_id}/${section_id}`).then(
                response => response.json()).then(
                    json => {
                        if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                        else setMinutes(json)
                    }
                )

            await fetch(`/back-end/public/api/getPublishedQuestion/${course_id}/${section_id}`).then(
                response => response.json()).then(
                    json => {
                        if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                        else setQuestion(json)
                    }
                )

            await fetch(`/back-end/public/api/getAnswer/${course_id}/${section_id}`).then(
                response => response.json()).then(
                    json => {
                        if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                        else setAnswer(json)
                    }
                )


            if (user) {
                await fetch(`/back-end/public/api/getTestScore/${course_id}/${section_id}`).then(
                    response => response.json()).then(
                        json => {
                            if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                            else setScoreAnswers(json)
                        }
                    )

                await fetch(`/back-end/public/api/getTotalUserScoreQuestion/${course_id}/${section_id}`).then(
                    response => response.json()).then(
                        json => {
                            if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                            else setTestScore(json)
                        }
                    )

                await fetch(`/back-end/public/api/getStudentAnswers/${course_id}/${section_id}`).then(
                    response => response.json()).then(
                        json => {
                            if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                            else setUserAnswers(json)
                        }
                    )
            }

            await fetch(`/back-end/public/api/getTestTitleUser/${course_id}/${section_id}`)
                .then((response) => response.json())
                .then(json => {
                    if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                    else {
                        setPassedTest(json.if_pass_test)
                        setTestTitle(json.title)
                    }
                }
                )
        }

    }

    const answerStyle = {
        backgroundColor: '#f3f3f3', display: 'flex', alignItems: 'center',
        marginBottom: '10px', borderRadius: '12px',
        padding: '0px 15px 0 0', width: 'fit-content',
        marginLeft: '10px',
        marginTop: '15px'
    }
    return (
        <div >
            <Container maxWidth="lg">
                <Grid container spacing={3} justify='center'>
                    <Grid item xs={12} sm={8} lg={8}>
                        {questions.length > 0 && answers.length > 0 ? <Breadcrumb /> : null}

                        {questions.length > 0 && answers.length > 0 ?
                            <Grid container spacing={1}>
                                
                        <Grid item xs={12} sm={12} lg={12}>
                            <Paper style={{ textAlign: 'center', padding: '10px' }}>
                            <Typography variant="h4">  {testTitle}</Typography>   
                            </Paper>

                        </Grid>
                                {questions.map((item, index) => (

                                    <Grid key={index} item xs={12} sm={8} lg={12}>
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Grid item lg={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <div style={{ display: 'flex' }}>
                                                        <Typography variant="h6" className={classes.title} style={{ marginRight: '1em' }}>
                                                            {index + 1}/{questions.length}
                                                        </Typography>
                                                        <Typography variant="h5" className={classes.title} >
                                                            {item.question}
                                                        </Typography>
                                                    </div>
                                                    <Typography variant="h6" className={classes.title} >
                                                        {item.score} punti
                                                    </Typography>
                                                </Grid>
                                            </AccordionSummary>
                                            <Divider variant='fullWidth' />
                                            {answers.filter(id => (id.question_id === item.id) || (id.question_final_id === item.id)).map((item, index) => (
                                                <AccordionDetails key={index} style={{
                                                    padding: '0px 15px 0px 0px',
                                                    width: 'fit-content',
                                                    marginLeft: '10px',
                                                    alignItems: 'center'
                                                }}>
                                                    {item.correct_answer === 1 ?
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <CheckCircleIcon style={{ color: 'green' }} />
                                                            <div style={answerStyle}>
                                                                <Checkbox
                                                                    checked={userAnswers.filter(ans => ans.user_answers === item.answers).length > 0
                                                                        ? true : false
                                                                    }
                                                                    disabled={true}
                                                                    id="answer"
                                                                    color="primary"
                                                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                                />
                                                                <Typography className={classes.title}>
                                                                    {item.answers}
                                                                </Typography>
                                                            </div>
                                                        </div> :
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <CancelIcon style={{ color: 'red' }} />
                                                            <div style={answerStyle}>
                                                                <Checkbox
                                                                    checked={userAnswers.filter(ans => ans.user_answers === item.answers).length > 0
                                                                        ? true : false
                                                                    }
                                                                    disabled={true}
                                                                    id="answer"
                                                                    color="primary"
                                                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                                />
                                                                <Typography className={classes.title}>
                                                                    {item.answers}
                                                                </Typography>
                                                            </div>
                                                        </div>}
                                                </AccordionDetails>
                                            ))}
                                        </Accordion>
                                    </Grid>))}
                            </Grid> : null}
                    </Grid>
                    {questions.length > 0 ?
                        <Grid item xs={12} sm={4} lg={3} style={{ marginTop: '2rem' }}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} lg={12}>
                                    <Paper className={maxHeightPaper} style={{ textAlign: 'center' }}>

                                        <Grid item >
                                            <div style={{ backgroundColor: 'lawngreen' }}>
                                                <Typography variant="h6" >
                                                    Tempo
                                                </Typography>
                                            </div>
                                            <Typography variant="h6" style={{ padding: '8px 0 8px 0px' }}>
                                                {minutes < 10 ? 0 : null}{minutes}:00 Minutes
                                            </Typography>
                                        </Grid>
                                        <Divider variant='fullWidth' />
                                        <Grid item style={{ padding: '8px 0 8px 0px' }} >
                                            <Typography variant="h6" >
                                            Domande:  {questions.length}
                                            </Typography>


                                            <Typography variant="h6" >
                                            Punteggio totale : {testScore}
                                            </Typography>
                                        </Grid>

                                    </Paper>
                                </Grid>
                                {answerScore.length > 0 &&  passedTest?
                                    <Grid item lg={12}>
                                        <Paper >
                                            <Result style={{ padding: '27px' }}
                                                status='success'
                                                icon={<SmileOutlined />}
                                                title={`${passedTest}`}
                                                subTitle={`Punteggio: ${Math.round((answerScore * 100) / testScore)}%`}
                                                extra={section_id === '0' ? <Button type="primary">Richiedi certificato</Button> : null}
                                            />
                                        </Paper>
                                    </Grid> : null}

                              
                            </Grid>
                        </Grid> : null}
                </Grid>

            </Container>
        </div>
    );

}

export default withRouter(TestResult);