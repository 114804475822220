import React, { useState, useEffect } from "react";
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { useStyles } from "../components/MainTheme.js";
import Grid from '@material-ui/core/Grid';
import { Upload, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useForm } from "react-hook-form";
import MuiAlert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import ControlledEditor from "../components/EditorContainer"
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {  useParams } from "react-router-dom"

function SectionPage() {

    const classes = useStyles();

    const { course_id } = useParams()
    const [image, setImage] = useState(null);
    const [displayImage, setDisplayImage] = useState(null);
    const [downloadImage, setDonwloadImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [saved, setSaved] = useState(false);
    const [notSaved, setNotSaved] = useState(false);
    const [showUploadList, setShowUploadList] = useState(true);
    const [openDail, setOpenDail] = useState(false);
    const [title, setTitle] = useState(null);
    const [description, setDescription] = useState(null);
    const [displayTitle, setDisplayTitle] = useState(null);
    const [downlaodTitle, setDonwloadTitle] = useState(null);


    const { handleSubmit } = useForm()


    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    useEffect(() => {
        getData()
    }, [])

    async function getData() {

        await fetch(`/back-end/public/api/getCourseInfo/${course_id}`).then(
            response => response.json()).then(
                (json) => {
                    setTitle(json.title)
                    setDescription(json.description)
                    
                }
            )
            
        await fetch("/back-end/public/api/getBackgroundImage/section").then(
            response => response.json()).then(
                (json) => {
                    setDonwloadTitle(json.title)
                    if (json.image_path)
                        setDonwloadImage(json.image_path.replace('\\', "/"))
                }
            )

           

    }

    const styles = {
        paperContainer: {
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
            backgroundColor: '#6c6e7d',
            height: '550px',
            marginTop: '-3rem',
            borderRadius: '0',
            color: '#fff',
            padding: '10px 70px 10px 70px',
            fontSize: '40px',
            display: 'flex',
            justifyContent: "center",
            alignItems: 'center',
            backgroundImage: `${displayImage ? `url(${displayImage})` :
                downloadImage ? `url(/back-end/public/${downloadImage})` : null}`
        }
    };
    const file = {
        beforeUpload: file => {
            setImage(file)
            setDisplayImage(URL.createObjectURL(file))
            setShowUploadList(true)
            return false;
        }
    };

    function removedImage() {
        setDisplayImage(null)
        setImage(null)

    }

    const onSubmit = async data => {

        setLoading(true)

        const formData = new FormData();
        if (image)
            formData.append('file', image)
        if (title)
            formData.append('title', title)
            formData.append('page', 'section')
        await fetch("/back-end/public/api/addBackgroundImage", {
            method: "POST",
            body: formData
        }).then(
            response => response.json()).then(
                json => {
                    if (json.msg === 'success') {
                        setSaved(true)
                        setShowUploadList(false)
                        getData()
                        reset()
                    } else {

                        setNotSaved(true)
                    }
                }
            ).catch(err => {
                setNotSaved(true)
            })
        setLoading(false)
    }

    const handleError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setNotSaved(false)
        setSaved(false)
    }
    const handleClose = () => {
        setOpenDail(false)
        reset()
    }

    const handleOk = () => {
        setOpenDail(false)
    }

    const handleCallback = (childData) => {
        setDisplayTitle(childData)
        setTitle(childData)
    }

    const openDailog = () => {
        setOpenDail(true)
    }

    const reset = () => {
        setDisplayTitle(null)
        setTitle(null)
        setImage(null)
        setDisplayImage(null)
    }

    return (
        <div>
            <Grid item sx={12} sm={12} lg={12}>
                <Paper style={styles.paperContainer}>
                <Grid item style={{ textAlign: 'center' }}>
                    {title ? <Typography variant="h4" color="inherit">{title}</Typography> : null}
                    {description ? 
                    <Grid item sx={12} sm={12} lg={12}>
               <Typography variant="h6" color="inherit" style={{fontWeight: '400', fontSize: '1rem'}}> 
               <div dangerouslySetInnerHTML={{ __html: description }} >
                    </div> </Typography>
                    </Grid> :null}
                </Grid>

                </Paper>
            </Grid>
            <Container maxWidth="lg">
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} lg={12}>
                        <Button
                            htmlType="submit"
                            type="primary"
                            className={classes.button}
                            style={{
                                width: '120px',
                            }}
                            onClick={openDailog}
                        >
                            Modifica
                        </Button>
                        <Button
                            onClick={onSubmit}
                            style={{
                                width: '120px',
                            }}
                            form="my-form-id"
                            type="primary"
                            className={classes.button}
                            loading={loading}
                            disabled={displayTitle || displayImage ? false : true}
                        >
                            Salva
                        </Button>
                        <Button
                            style={{
                                width: '120px',
                            }}
                            form="my-form-id"
                            type="primary"
                            className={classes.button}
                            disabled={displayTitle || displayImage ? false : true}
                            onClick={reset}
                            loading={loading}
                        >
                            Reset
                        </Button>
                    </Grid>
                </Grid>
                <Snackbar open={notSaved} autoHideDuration={3000} onClose={handleError} >
                    <Alert onClose={handleError} severity="error">
                    Qualcosa è andato storto, riprova
                    </Alert>
                </Snackbar>
                <Snackbar open={saved} autoHideDuration={3000} onClose={handleError} >
                    <Alert onClose={handleError} severity="success">
                    Salvato!
                    </Alert>
                </Snackbar>
            </Container>
            <Dialog fullWidth disableBackdropClick disableEscapeKeyDown open={openDail} onClose={handleClose}>
                <DialogTitle>Cambia Immagine Sfondo</DialogTitle>
                <DialogContent>
                    {/* <Grid item xs={12} sm={12} lg={12}>
                        <InputLabel htmlFor="age-native-helper">Title</InputLabel>
                        <ControlledEditor description={handleCallback} content={downlaodTitle || displayTitle} />
                    </Grid> */}
                    <Grid item sx={12} sm={12} lg={12}>
                        <Upload
                            {...file}
                            listType="picture"
                            maxCount={1}
                            accept='image/*'
                            onRemove={removedImage}
                            showUploadList={showUploadList}
                        >
                            <Button icon={<UploadOutlined />} >Upload Immagine</Button>
                        </Upload>
                    </Grid>
                </DialogContent>


                <DialogActions>
                    <Button onClick={handleClose} >
                    Cancella
                    </Button>
                    <Button
                        form="my-form-id"
                        type="primary"
                        className={classes.button}
                        onClick={handleOk}
                    >
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </div >

    );
}

export default SectionPage;
