import "../App.css";
import React, { useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import { withRouter, useHistory, useParams } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import { useStyles } from "../components/MainTheme.js";
import Grid from "@material-ui/core/Grid";
import Breadcrumb from "../components/Breadcrumb";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Popconfirm, Button, Upload, Menu, Dropdown } from "antd";
import {
  UploadOutlined,
  FilePdfOutlined,
  EditOutlined,
  DeleteOutlined,
  FilePptOutlined,
  YoutubeOutlined,
} from "@ant-design/icons";
import AddTest from "../AdminPages/AddTest";
import UpdateModule from "./UpdateModule";
import UpdateVideo from "./UpdateVideo";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

function SectionModule(props) {
  const { section_id } = useParams();
  const { course_id } = useParams();

  const classes = useStyles();
  const history = useHistory();

  const [title, setTitle] = useState(null);
  const [pptUrl, setPptUrl] = useState(null);
  const [file, setFile] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const [questionCount, setQuestionCount] = useState(null);
  const [testScore, setTestScore] = useState("");
  const [totalTime, setTotalTime] = useState(null);
  const [sectionName, setSectionName] = useState(null);
  const [testTitle, setTestTitle] = useState(null);

  const [state, setState] = useState({
    checkedB: false,
  });
  const [switchCheck, setSwitchCheck] = useState("0");

  const [modules, setModules] = useState([]);
  const [courseType, setCourseType] = useState(null);
  const [time, setTime] = useState(null);
  const [fileType, setFileType] = useState("pdf");

  const [videoId, setVideoId] = useState(null);
  const [moduleId, setModuleId] = useState(null);
  const [openModUpdate, setOpenModUpdate] = useState(false);
  const [openVidUpdate, setOpenVidUpdate] = useState(false);
  const [disableDelete, setDisableDelete] = useState(false);
  const [showTest, setShowTest] = useState(false);

  const [error, setError] = useState(false);
  const [errorTime, setErrorTime] = useState(false);
  const [errorPdf, setErrorPdf] = useState(false);
  const [errorPpt, setErrorPpt] = useState(false);
  const [errorVideo, setErrorVideo] = useState(false);

  const [loadingModule, setLoadingModule] = useState(false);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) getData();
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    setError(false);
    setErrorPdf(false);
    setErrorPpt(false);
    setErrorTime(false);
    setErrorVideo(false);
  }, [fileType]);

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
    if (state.checkedB) setSwitchCheck(1);
    else setSwitchCheck(0);
  };

  // async function getTotalTime() {
  //     await fetch("/back-end/public/api/getTotalTime/" + course_id)
  //         .then((response) => response.json())
  //         .then((json) => setTotalTime(json));
  // }

  async function getData() {
    await fetch("/back-end/public/api/getUserCourseType/" + course_id)
      .then((response) => response.json())
      .then((json) => {
        setCourseType(json);
        // if (json.type === "Mandatory") {
        //     getTotalTime();
        // }
      });

    await fetch("/back-end/public/api/getSectionNameAdmin/" + section_id)
      .then((response) => response.json())
      .then((json) => setSectionName(json));

    await fetch("/back-end/public/api/getModulesAdmin/" + section_id)
      .then((response) => response.json())
      .then((json) => setModules(json));

      await fetch(`/back-end/public/api/getTestTitle/${course_id}/${section_id}`)
      .then((response) => response.json())
      .then(json =>{ if(json.title)
      
          setTestTitle(json.title)}
        );

    await fetch(`/back-end/public/api/getTotalScoreQuestionAdmin/${course_id}/${section_id}`)
      .then((response) => response.json())
      .then((json) => setTestScore(json));

    await fetch(`/back-end/public/api/countQuestions/${course_id}/${section_id}`)
      .then((response) => response.json())
      .then((json) => setQuestionCount(json))
      .then(() => setShowTest(true));

   
  }

  async function deleteModule(id) {
    await fetch("/back-end/public/api/deleteModule/" + id, {
      method: "DELETE",
    }).then(() => getData(), setDisableDelete(false));
  }

  async function addmodule() {
    setLoadingModule(true);

    if (!title) {
      setError(true);
      setLoadingModule(false);
    } else setError(false);
    if (!file) {
      setErrorPdf(true);
      setLoadingModule(false);
    } else setErrorPdf(false);

    if (!pptUrl) {
      setErrorPpt(true);
      setLoadingModule(false);
    } else setErrorPpt(false);

    if (!videoUrl) {
      setErrorVideo(true);
      setLoadingModule(false);
    } else setErrorVideo(false);

    if (
      courseType &&
      courseType.type === "Mandatory" &&
      !time &&
      (fileType === "pdf" || fileType === "ppt")
    ) {
      setErrorTime(true);
      setLoadingModule(false);
    } else if (fileType === "pdf" && title && file) {
      const formData = new FormData();

      formData.append("type", "pdf");
      formData.append("course_id", course_id);
      formData.append("file", file);
      formData.append("module_name", title);
      formData.append("section_id", section_id);
      formData.append("download", switchCheck);
      if (time) formData.append("time", time);

      await fetch("/back-end/public/api/addmodule", {
        method: "POST",
        body: formData,
      }).then(() => {
        handleClose();
        getData();
        setState({ ...state, checkedB: false });
      });
    } else if (fileType === "ppt" && pptUrl) {
      const formData = new FormData();

      formData.append("type", fileType);
      formData.append("course_id", course_id);
      formData.append("module_name", title);
      formData.append("module_path", pptUrl);
      formData.append("section_id", section_id);
      if (time) formData.append("time", time);

      await fetch("/back-end/public/api/addmodule", {
        method: "POST",
        body: formData,
      }).then(() => {
        handleClose();
        getData();
      });
    } else if (fileType === "video" && videoUrl) {
      const formData = new FormData();

      formData.append("type", fileType);
      formData.append("course_id", course_id);
      formData.append("module_name", title);
      formData.append("module_path", videoUrl);
      formData.append("section_id", section_id);

      if (courseType && courseType.type === "Mandatory")
        formData.append("time", 0);

      await fetch("/back-end/public/api/addmodule", {
        method: "POST",
        body: formData,
      }).then(() => {
        handleClose();
        getData();
      });
    }

    setLoadingModule(false);
  }

  const handleClose = () => {
    setTitle(null);
    setPptUrl(null);
    setFile(null);
    setTime(null);
    setVideoUrl(null);
    if (error || errorTime || errorPdf || errorPpt || errorVideo) {
      setError(false);
      setErrorPdf(false);
      setErrorTime(false);
      setErrorPpt(false);
      setErrorVideo(false);
    }
    if (openMod) setOpenMod(false);
    if (openVid) setOpenVid(false);
  };
  const [openMod, setOpenMod] = useState(false);
  const [openVid, setOpenVid] = useState(false);

  function goToVideoPage(video_id) {
    history.push({
      pathname: `/admin/corsi/${course_id}/sezioni/${section_id}/moduli/${video_id}/video`,
    });
  }

  function goToModule(item) {
    history.push({
      pathname: `/admin/corsi/${course_id}/sezioni/${section_id}/moduli/${item.id}/pdf`,
    });
  }

  function goToModulePPT(item) {
    history.push({
      pathname: `/admin/corsi/${course_id}/sezioni/${section_id}/moduli/${item.id}/ppt`,
    });
  }

  const pdf = {
    beforeUpload: (file) => {
      setFile(file);
      return false;
    },
  };

  function changeColor(e) {
    e.target.style.color = "#1890ff";
  }
  function onMouseLeave(e) {
    e.target.style.color = "rgba(0, 0, 0, 0.87)";
  }

  function addTest() {
    history.push({
      pathname: `/admin/corsi/${course_id}/sezioni/${section_id}/moduli/inserisci-domande`,
    });
  }
  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={() => setOpenMod(true)}>
      Aggiungi modulo
      </Menu.Item>
      <Menu.Item key="2" onClick={() => addTest()}>
      Aggiungi test
      </Menu.Item>
    </Menu>
  );
  const UpdModCallback = (childData) => {
    setOpenModUpdate(childData);
  };
  const handleCallbackModule = (childData) => {
    setModuleId(childData);
    getData();
  };

  const UpdVidCallback = (childData) => {
    setOpenVidUpdate(childData);
  };
  const handleCallbackVideo = (childData) => {
    setVideoId(childData);
    getData();
  };

  const handleChangeFileType = (event) => {
    setFileType(event.target.value);
  };

  function updateTestTitle()
  {
    history.push({
        pathname: `/admin/corsi/${course_id}/sezioni/${section_id}/moduli/aggiorna-nome-del-test`,
      });
  }
  return (
    <Container maxWidth="lg">
      <Breadcrumb />

      <Grid item xs={12} sm={12} lg={12}>
        <Dropdown overlay={menu} placement="bottomLeft">
          <Button
            size="large"
            className={classes.button}
            type="primary"
            style={{ maxWidth: "160px", marginBottom: "2rem" }}
          >
            Aggiungi risorsa
          </Button>
        </Dropdown>
      </Grid>

      {moduleId ? (
        <UpdateModule
          courseType={courseType}
          open={openModUpdate}
          module_id_clear={handleCallbackModule}
          module_id={moduleId}
          close={UpdModCallback}
        />
      ) : null}

      {videoId ? (
        <UpdateVideo
          open={openVidUpdate}
          video_id_clear={handleCallbackVideo}
          video_id={videoId}
          close={UpdVidCallback}
        />
      ) : null}

      <Dialog
        fullWidth
        disableBackdropClick
        disableEscapeKeyDown
        open={openMod}
        onClose={handleClose}
      >
        <DialogTitle>Aggiungi modulo</DialogTitle>
        <DialogContent>
          <Grid item xs={12} sm={12} lg={12}>
            <Grid item xs={12} lg={5}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Tipologia</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Tipologia"
                  size="small"
                  value={fileType}
                  onChange={handleChangeFileType}
                >
                  <MenuItem value="pdf">PDF</MenuItem>
                  <MenuItem value="ppt">PPT</MenuItem>
                  <MenuItem value="video">VIDEO</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <TextField
              fullWidth
              id="outlined-full-width"
              label="Nome modulo"
              placeholder="Nome modulo"
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              variant="outlined"
              required
              onChange={(e) => setTitle(e.target.value)}
              error={error}
              helperText={error ? "Il campo è obbligatorio" : null}
            />
            {fileType === "video" ? (
              <TextField
                fullWidth
                id="outlined-full-width"
                label="Url"
                placeholder="Url"
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
                variant="outlined"
                required
                onChange={(e) => setVideoUrl(e.target.value)}
                error={errorVideo}
                helperText={errorVideo ? "Il campo è obbligatorio" : null}
              />
            ) : null}
            {fileType === "pdf" ? (
              <Grid
                item
                xs={12}
                lg={12}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Grid item xs={12} lg={8}>
                  <Upload
                    {...pdf}
                    listType="pdf"
                    maxCount={1}
                    accept="pdf/*"
                    required
                  >
                    <Button icon={<UploadOutlined />}>Upload pdf</Button>
                  </Upload>
                  {errorPdf ? (
                    <Typography
                      variant="h7"
                      style={{
                        marginTop: "4px",
                        fontSize: "0.75rem",
                        marginLeft: "14px",
                        color: "#f44336",
                      }}
                    >
                      Il campo è obbligatorio
                    </Typography>
                  ) : null}
                </Grid>
                <Grid item sx={12} lg={4} style={{ display: "flex" }}>
                  <Switch
                    checked={state.checkedB}
                    onChange={handleChange}
                    color="primary"
                    name="checkedB"
                    size="small"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                  <Typography>Abilita lo scaricamento</Typography>
                </Grid>
              </Grid>
            ) : null}
            {fileType === "ppt" ? (
              <TextField
                fullWidth
                id="outlined-full-width"
                label="Url"
                placeholder="Url"
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
                variant="outlined"
                required
                onChange={(e) => setPptUrl(e.target.value)}
                error={errorPpt}
                helperText={errorPpt ? "Il campo è obbligatorio" : null}
              />
            ) : null}

            {courseType &&
            courseType.type === "Mandatory" &&
            (fileType === "pdf" || fileType === "ppt") ? (
              <Grid
                item
                xs={12}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Grid item xs={6} sm={4} lg={4}>
                  <TextField
                    id="outlined-full-width"
                    label="Time in minutes"
                    placeholder="Time"
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    size="small"
                    variant="outlined"
                    required
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                    onChange={(e) => setTime(e.target.value)}
                    error={errorTime}
                    helperText={errorTime ? "Il campo è obbligatorio" : null}
                  />
                </Grid>
                <Grid item xs={6} sm={4} lg={4}>
                  <span style={{ marginLeft: "1rem" }}>
                    {" "}
                    Tempo minimo di lettura
                  </span>
                </Grid>
              </Grid>
            ) : null}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button disabled={loadingModule} onClick={handleClose}>
          Cancella
          </Button>
          <Button
            loading={loadingModule}
            onClick={addmodule}
            type="primary"
            className={classes.button}
          >
            Salva
          </Button>
        </DialogActions>
      </Dialog>

      <Grid item xs={12} sm={12} lg={12}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} lg={9}>
            <div>
              <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell>Modulo</TableCell>
                      <TableCell>Abilita lo scaricamento</TableCell>
                      {courseType && courseType.type === "Mandatory" ? (
                        <TableCell>Tempo</TableCell>
                      ) : null}
                      <TableCell>Modifica</TableCell>
                      <TableCell>Elimina</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {modules.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>
                          {item.type === "pdf" ? (
                            <div onClick={() => goToModule(item)}>
                              <FilePdfOutlined
                                style={{
                                  color: "red",
                                  fontSize: "20px",
                                  display: "content !important",
                                }}
                              />
                              <a
                                onMouseOver={changeColor}
                                onMouseLeave={onMouseLeave}
                              >
                                {" "}
                                {item.module_name}{" "}
                              </a>
                            </div>
                          ) : null}
                          {item.type === "ppt" ? (
                            <div onClick={() => goToModulePPT(item)}>
                              <FilePptOutlined
                                style={{
                                  color: "#ff9800",
                                  fontSize: "20px",
                                  display: "content !important",
                                }}
                              />
                              <a
                                onMouseOver={changeColor}
                                onMouseLeave={onMouseLeave}
                              >
                                {" "}
                                {item.module_name}{" "}
                              </a>
                            </div>
                          ) : null}
                          {item.type === "video" ? (
                            <div onClick={() => goToVideoPage(item.id)}>
                              <YoutubeOutlined
                                style={{
                                  color: "red",
                                  fontSize: "20px",
                                  display: "content !important",
                                }}
                              />
                              <a
                                onMouseOver={changeColor}
                                onMouseLeave={onMouseLeave}
                              >
                                {" "}
                                {item.module_name}{" "}
                              </a>
                            </div>
                          ) : null}
                        </TableCell>
                        {item.download === 0 ? (
                          <TableCell>falso</TableCell>
                        ) : (
                          <TableCell>Vero</TableCell>
                        )}
                        {courseType && courseType.type === "Mandatory" ? (
                          <TableCell>{item.time}</TableCell>
                        ) : null}
                        <TableCell>
                          <Button
                            disabled={openModUpdate}
                            onClick={() => {
                              setModuleId(item.id);
                              setOpenModUpdate(true);
                            }}
                            icon={<EditOutlined style={{ fontSize: "20px" }} />}
                          />
                        </TableCell>

                        <TableCell>
                          <Popconfirm
                            disabled={disableDelete}
                            onConfirm={() => {
                              setDisableDelete(true);
                              deleteModule(item.id);
                            }}
                            title="Sei sicuro?"
                            okText="Si"
                            cancelText="No"
                          >
                            <Button
                              disabled={disableDelete}
                              icon={
                                <DeleteOutlined style={{ fontSize: "18px" }} />
                              }
                            />
                          </Popconfirm>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            {/* <div>
                            {courseType && courseType.type === "Mandatory" && totalTime ? (
                                <Grid item xs={10} sm={12} lg={9}>
                                    <TableContainer component={Paper}>
                                        <Table size="small" aria-label="a dense table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>#</TableCell>
                                                    <TableCell>Total time</TableCell>
                                                    <TableCell>{totalTime} minutes</TableCell>
                                                </TableRow>
                                            </TableHead>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            ) : null}
                        </div> */}
          </Grid>
          <Grid item xs={12} sm={12} lg={3}>
            <Grid item xs={12} lg={12}>
              <Paper style={{ padding: "16px", textAlign: "center" }}>
                <Typography variant="h6">{sectionName}</Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Paper>
                <div style={{ textAlign: "center", background: "limegreen" }}>
                  <Typography variant="h5">Test</Typography>
                </div>
                {questionCount ? (
                  <div style={{ textAlign: "center", padding: "10px 30px" }}>
                    <Typography variant="h6">
                    Domande: {questionCount}
                    </Typography>
                    <Typography variant="h6">Punteggio: {testScore}</Typography>
                  </div>
                ) : (
                  <Typography
                    variant="h6"
                    style={{ textAlign: "center", padding: "10px 30px" }}
                  >
                    Nessun test correlate
                  </Typography>
                )}
              </Paper>
            </Grid>
          </Grid>
          {testTitle ? (
            <Grid item sx={12} sm={12} lg={12}>
                <Paper>
                <div style={{ textAlign: "center", background: "limegreen" }}>
                  <Typography variant="h5">Nome del test</Typography>
                </div>
                    </Paper>
              <Paper
                style={{
                  padding: "10px 15px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h6"> {testTitle} </Typography>
                <Button icon={<EditOutlined onClick={()=> updateTestTitle()} style={{ fontSize: "20px" }} />} />
              </Paper>
            </Grid>
          ) : null}
        </Grid>

        {showTest ? (
          <Grid item sx={12} sm={12} lg={12}>
            <AddTest course_id={course_id} section_id={section_id} />
          </Grid>
        ) : null}
      </Grid>
    </Container>
  );
}

export default withRouter(SectionModule);
