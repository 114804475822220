import "../App.css";
import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import { withRouter, useParams, useHistory } from "react-router-dom"
import Grid from '@material-ui/core/Grid';
import Breadcrumb from "../components/Breadcrumb";
import Video from "./Video";
//import Context from '../Context'
import { Button, message} from "antd";

// const useStyles = makeStyles((theme) => ({
//     playerWrapper: {
//         width: "100%",

//         position: "relative",
//         // "&:hover": {
//         //   "& $controlsWrapper": {
//         //     visibility: "visible",
//         //   },
//         // },
//     },

//     controlsWrapper: {
//         visibility: "hidden",
//         position: "absolute",
//         marginBottom: '5px',
//         left: 0,
//         right: 0,
//         bottom: 0,
//         background: "rgba(0,0,0,0.4)",
//         display: "flex",
//         flexDirection: "column",
//         justifyContent: "space-between",
//     },
//     topControls: {
//         display: "flex",
//         justifyContent: "flex-end",
//         padding: theme.spacing(2),
//     },
//     middleControls: {
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "center",
//     },
//     bottomWrapper: {
//         display: "flex",
//         flexDirection: "column",

//         // background: "rgba(0,0,0,0.6)",
//         // height: 60,
//         padding: theme.spacing(2),
//     },

//     bottomControls: {
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "space-between",
//         // height:40,
//     },

//     button: {
//         margin: theme.spacing(1),
//     },
//     controlIcons: {
//         color: "#777",

//         fontSize: 50,
//         transform: "scale(0.9)",
//         "&:hover": {
//             color: "#fff",
//             transform: "scale(1)",
//         },
//     },

//     bottomIcons: {
//         color: "#999",
//         "&:hover": {
//             color: "#fff",
//         },
//     },

//     volumeSlider: {
//         width: 100,
//     },
//     root: {
//         fontSize: '16px',
//         color: '#fff',
//         background: '#2a2a2a',
//         width: '100%',
//         position: 'relative',
//         overflow: 'auto',
//         maxHeight: '346px',

//     },
//     listItem: {
//         '&:hover': {
//             backgroundColor: '#595959'
//         }
//     },
//     listSection: {
//         backgroundColor: 'inherit',
//     },
//     ul: {
//         backgroundColor: 'inherit',
//         padding: 0,

//     },
//     videoImage: {
//         paddingRight: '10px',
//     }
// }));


function VideoPage(props) {

    const { module_id } = useParams()
    const { section_id } = useParams()
    const { course_id } = useParams()

    const history = useHistory();
    const [modules, setModules] = useState(null);
    const [finishedVideo, setFinishedVideo] = useState(false);


    const [urlVideo, setVideoUrl] = useState(null);
    // const [videoId, setVideoId] = useState(video_id);
    //const [coursePicture, setCoursePicture] = useState([]);
   // const [allowedVideo, setAllowedVideo] = useState([])
    const [courseType, setCourseType] = useState(null);
    const [moduleStatus, setModuleStatus] = useState([])

    // useEffect(() => {
    //     getData()

    // }, [videoId])

    useEffect(() => {
        getData()

    }, [])

    useEffect(() => {
            getAllowedResources()

            const token = setTimeout(getAllowedResources, 30000)
            return function cleanUp() {
                clearTimeout(token);
            }

    }, [])

    const error = () => {
        message.error({
          content: 'Devi finire di visualizzare la risorsa prima di procedure al modulo successive',
          style: {
            marginTop: '5vh',
          },
        });
      };


    async function getData() {
        // await fetch(`/back-end/public/api/getVideoUrl/${course_id}/${videoId}`).then(
        //     response => response.json()).then(
        //         json => {
        //             if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
        //             else setVideoUrl(json.url)
        //         }
        //     )
        // await fetch(`/back-end/public/api/getvideos/${course_id}/${section_id}`).then(
        //     response => response.json()).then(
        //         json => {
        //             if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
        //             else setVideos(json)
        //         }
        //     )

        await fetch(`/back-end/public/api/getModulePath/${course_id}/${module_id}`).then(
            response => response.json()).then(
                path => {
                    if (path.msg === "not authorized") history.push({ pathname: '/not-found' })
                    else setVideoUrl(path.module_path)
                }
            )

        await fetch(`/back-end/public/api/getCourseType/${course_id}`).then(
            response => response.json()).then(
                json => {
                    if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                    else setCourseType(json.type)
                }
            )

            await fetch(`/back-end/public/api/getModules/${course_id}/${section_id}`).then(
                response => response.json()).then(
                    json => {
                        if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                        else setModules(json)
                    }
                )

        // await fetch(`/back-end/public/api/getCoursePicture/${course_id}`).then(
        //     response => response.json()).then(
        //         json => {
        //             if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
        //             else setCoursePicture(json)
        //         }
        //     )

        // if (user) {

        //     await fetch(`/back-end/public/api/getAllowedVideo/${course_id}/${section_id}`).then(
        //         response => response.json()).then(
        //             json => {
        //                 if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
        //                 else setAllowedVideo(json)
        //             })
        // }
    }

    // function changeVideo(id) {
    //     window.history.replaceState({ section_id }, null, `./${id}`)
    //     setVideoId(id)
    // }

    async function getAllowedResources() {
        await fetch(`/back-end/public/api/getModuleStatus/${course_id}/${section_id}`)
            .then(
                response => response.json()).then(
                    json => {
                        if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                        else setModuleStatus(json)
                    }
                )
    }
  
    function nextModule()
    {
       
        if( (courseType && courseType === 'Normal'  &&  moduleStatus.filter(function (val) {
            return (val.module_id ===  parseInt(module_id) && val.finished_reading === 1); }).length > 0) 
            || ((courseType && courseType === 'Mandatory' &&  moduleStatus.filter(function (val) {
            return (val.module_id ===  parseInt(module_id) && val.finished_reading === 1 && val.time === 0);
        }).length > 0)) || (courseType && courseType === 'Normal'  && finishedVideo))

    {  var index;
      var id;
      var type;
     
      index = modules && modules.findIndex(x => x.id === parseInt(module_id)) +1;
      modules && modules.map((item,i)=>{
          if(i === index)
              {
                  id = item.id
                    type = item.type  }
          return true;
      })
    
      if(type === 'pdf')
      history.push({
          pathname: `/home/corsi/${course_id}/moduli/${section_id}/pdf/${id}`,
      });
   
  
  
        if(type === 'video')
      {  history.push({
          pathname: `/home/corsi/${course_id}/moduli/${section_id}/video/${id}`,
      });
      window.location.reload(false);
  }
      if(type === 'ppt')
     history.push({
          pathname: `/home/corsi/${course_id}/moduli/${section_id}/ppt/${id}`,
      })
    }else error()
    }
    function prevModule()
    {
        var index;
        var id;
        var type;
        index = modules && modules.findIndex(x => x.id === parseInt(module_id)) -1;
       modules && modules.map((item,i)=>{
            if(i === index)
                {
                    id = item.id
                      type = item.type  }
            return true;
        })
    
       
        if(type === 'pdf')
        history.push({
            pathname: `/home/corsi/${course_id}/moduli/${section_id}/pdf/${id}`,
        });
     
    
    
          if(type === 'video')
        {  history.push({
            pathname: `/home/corsi/${course_id}/moduli/${section_id}/video/${id}`,
        });
        window.location.reload(false);
    }
        if(type === 'ppt')
       history.push({
            pathname: `/home/corsi/${course_id}/moduli/${section_id}/ppt/${id}`,
        });
       
    }

    const handleCallback = (childData) => {
       setFinishedVideo(childData)
      }

    return (

        <Container maxWidth="lg">
            <Grid container spacing={1} >
                <Grid container justify='center' item xs={12} lg={12} >
                    <Grid item xs={12} lg={8}>
                        <Breadcrumb />
                    </Grid>
                    {urlVideo ? 
                    <Grid item xs={12} lg={8} style={{display:'flex', justifyContent:'end', marginBottom:'5px'}}>
         {modules && modules.findIndex(x => x.id === parseInt(module_id)) === 0 ? null  
         : <Button size="small" type="primary" style={{marginRight:'20px'}} onClick={prevModule}>Precedente</Button>}
         
          {modules && modules.map(x => x.id).lastIndexOf(parseInt(module_id))+1 === modules.length  ? null
           : <Button size="small" type="primary" onClick={nextModule}>Prossimo modulo</Button>}
       
        </Grid> : null}
                    <Grid item xs={12} lg={8}>

                        <Video finishedVideo={handleCallback} url={urlVideo} id={module_id} />

                    </Grid>
                    {/* <Grid item xs={12} lg={4} style={{
                        flex: '1',
                        background: '#2a2a2a',
                        // height: '99.9%'
                    }}>
                        <div style={{
                            display: 'flex', justifyContent: 'space-between',
                            padding: '20px 30px',
                            background: '#252525',
                            color: '#fff',
                            fontSize: '15px'
                        }}>
                            <Typography variant='h6' style={{ color: "#fff", fontWeight: 'bold' }}>Lessons</Typography>
                            <span >{videos.length} Lessons</span>
                        </div>
                        <List className={classes.root} >
                            <li className={classes.listSection} >
                                <ul className={classes.ul}>
                                    {videos.map((item, index) => (
                                        <ListItem
                                            className={classes.listItem}
                                            disabled={index <= allowedVideo.length || index === 0 ? false :
                                                courseType && courseType === 'Mandatory' ? true : false}
                                            key={index} button onClick={() => changeVideo(item.id)}
                                            style={{
                                                display: 'flex',
                                                alignItems: 'end',

                                            }}>
                                            <div className={classes.videoImage}>
                                                {videoId === item.id ?
                                                    <Typography style={{
                                                        color: 'rgb(255, 199, 2)',
                                                        fontSize: '1rem',
                                                    }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
                                                            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                                        </svg>
                                                    </Typography> :
                                                    <Typography style={{
                                                        color: '#fff',
                                                        fontSize: '1rem',
                                                        fontWeight: 700,
                                                        marginRight: '10px'
                                                    }}>
                                                        {index + 1}
                                                    </Typography>}

                                            </div>
                                            <div className={classes.videoImage}>
                                                <img alt="" src={coursePicture.length > 0 ? '/' + coursePicture[0].replace('\\', "/") : null} style={{
                                                    display: 'block',
                                                    width: '70px',
                                                    height: 'auto',
                                                    maxWidth: 'none',
                                                    margin: '0 0 0 0',
                                                }}></img>
                                            </div>
                                            <Typography variant='h6' style={{
                                                marginTop: '0px',
                                                marginBottom: '.25rem',
                                                fontWeight: '500',
                                                color: '#fff',
                                            }} >
                                                {item.video_name}
                                            </Typography>

                                        </ListItem>
                                    ))}
                                </ul>
                            </li>

                        </List>
                    </Grid> */}
                     {urlVideo ? 
                    <Grid item xs={12} lg={8} style={{display:'flex', justifyContent:'end', marginTop:'5px'}}>
          {modules && modules.findIndex(x => x.id === parseInt(module_id)) === 0 ? null 
          :<Button size="small" type="primary" style={{marginRight:'20px'}} onClick={prevModule}>Precedente</Button>}
         {modules && modules.map(x => x.id).lastIndexOf(parseInt(module_id))+1 === modules.length  ? null 
         : <Button size="small" type="primary" onClick={nextModule}>Prossimo modulo</Button>}
        </Grid> : null}
                </Grid>
            </Grid>

        </Container >
    );
}

export default withRouter(VideoPage);
