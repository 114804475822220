import { withRouter, useHistory, useParams } from "react-router-dom"
import React, { useContext, useState, useEffect } from "react";
import Context from '../Context'
import Container from '@material-ui/core/Container';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Viewer, SpecialZoomLevel  } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { Worker } from '@react-pdf-viewer/core';
import Breadcrumb from "../components/Breadcrumb";
import Grid from '@material-ui/core/Grid';
import { Typography } from "@material-ui/core";
import { useStyles } from "../components/MainTheme.js";
import { Button , message} from "antd";

function ReadModule(props) {

    const { user } = useContext(Context)
    const classes = useStyles();

    const { section_id } = useParams()
    const { module_id } = useParams()
    const { course_id } = useParams()
    const history = useHistory();

    const [downloadcheck, setDownload] = useState('');
    const [url, setUrl] = useState(null)

    const [courseType, setCourseType] = useState(null);
    const current_page = JSON.parse(localStorage.getItem(`${user.id}_module_${module_id}`));

    const [seconds, setSeconds] = useState(0)
    const [minutes, setMinutes] = useState(0)
    const [moduleTime, setModuleTime] = useState(0)

  const [modules, setModules] = useState(null);

    const [check, setCheck] = useState(false)
    const [moduleStatus, setModuleStatus] = useState([])


    localStorage.setItem(`${user.id}_url`, JSON.stringify(props.location.pathname));



    function updateTime() {

        if (minutes === 0 && seconds === 0) {
            localStorage.setItem(`${user.id}_${module_id}_scnd`, JSON.stringify(0));
        }
        else {
            if (seconds === 0) {
                setMinutes(minutes => minutes - 1);
                setSeconds(59);
            } else {
                setSeconds(seconds => seconds - 1);
                if (minutes === 0 && seconds > 0) {
                    localStorage.setItem(`${user.id}_${module_id}_scnd`, JSON.stringify(seconds));
                }
            }
        }
    }


    const error = () => {
        message.error({
          content: 'Devi finire di visualizzare la risorsa prima di procedure al modulo successive',
          style: {
            marginTop: '5vh',
          },
        });
      };

    const renderToolbar = (Toolbar: ((props: ToolbarProps) => ReactElement)) => (
        <Toolbar>
            {
                (slots: ToolbarSlot) => {
                    const {
                        CurrentPageInput, Download, EnterFullScreen, GoToNextPage, GoToPreviousPage,
                        NumberOfPages, Zoom, ZoomIn,
                        ZoomOut,
                    } = slots;
                    return (
                        <div
                            style={{
                                alignItems: 'center',
                                display: 'flex',
                            }}
                        >

                            <div className={classes.nextPrev} style={{ padding: '0px 2px' }}>
                                <GoToPreviousPage />
                            </div>
                            <div style={{ padding: '0px 2px' }}>
                                <CurrentPageInput /> / <NumberOfPages />
                            </div>
                            <div className={classes.nextPrev} style={{ padding: '0px 2px' }}>
                                <GoToNextPage />
                            </div>
                            <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
                                <ZoomIn />
                            </div>
                            <div style={{ padding: '0px 2px' }}>
                                <Zoom />
                            </div>
                            <div style={{ padding: '0px 2px' }}>
                                <ZoomOut />
                            </div>
                            <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
                                <EnterFullScreen />
                            </div>


                            {downloadcheck && downloadcheck === 1 ?
                                <div style={{ padding: '0px 2px' }}>
                                    <Download />
                                </div> : null}

                        </div>
                    )
                }
            }
        </Toolbar>
    );


    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        sidebarTabs: defaultTabs => [defaultTabs[0]],
        renderToolbar


    });
    async function updateStatusTime() {

        if (courseType && check && courseType.type === 'Mandatory' && user && user.user_type === 'USR' && minutes >= 0) {

            const formData = new FormData();
            formData.append('course_id', course_id)
            formData.append('module_id', module_id)
            formData.append('time', minutes)

            await fetch("/back-end/public/api/updateStatusTime", {
                method: "POST",
                body: formData
            })
        }


    }


    useEffect(() => {
        if (url && check && courseType && courseType.type === 'Mandatory' && user.user_type === 'USR' && user && minutes >= 0) {
            updateStatusTime()
            getAllowedResources()
        }
    }, [minutes])
   

    useEffect(() => {
        if (courseType && courseType.type === 'Mandatory' && user.user_type === 'USR') {
            const token = setTimeout(updateTime, 1000)
            return function cleanUp() {
                clearTimeout(token);
            }
        }
    })

  

    useEffect(() => {
        getData()

    }, [])

    useEffect(() => {

        if (user.user_type === 'USR' && url)
            setUserStatus()
        async function getModuleTimeLeft() {
            await fetch(`/back-end/public/api/getModuleTimeLeft/${course_id}/${module_id}`).then(
                response => response.json()).then(
                    time => {
                        if (time.length === 0) {
                            getModuleTime(time)
                        }

                        else {
                            setMinutes(time)
                            if (time === 0) {
                                setSeconds(JSON.parse(localStorage.getItem(`${user.id}_${module_id}_scnd`)))
                            } else { setSeconds(59) }

                            setCheck(true)
                        }
                    }
                )

            await fetch(`/back-end/public/api/getModuleTime/${course_id}/${module_id}`).then(
                response => response.json()).then(
                    time => {
                        if (time.msg === "not authorized") history.push({ pathname: '/not-found' })
                        else setModuleTime(time)


                    }
                )
        }
        if (courseType && courseType.type === 'Mandatory' && user.user_type === 'USR' && user && url) {

            getModuleTimeLeft()
            getAllowedResources()
        
        }
    }, [courseType, url])


    async function setUserStatus() {
        
        if (user) {
            const formData = new FormData();
            formData.append('module_id', module_id)
            formData.append('section_id', section_id)
            formData.append('course_id', course_id)

            await fetch("/back-end/public/api/setUserStatus", {
                method: "POST",
                body: formData
            })
        }

    }

    async function getData() {

        await fetch(`/back-end/public/api/getModulePath/${course_id}/${module_id}`).then(
            response => response.json()).then(
                path => {
                    if (path.msg === "not authorized") history.push({ pathname: '/not-found' })
                    else setUrl(`/back-end/public/api/download/${path.module_path}/${course_id}`)
                }
            )
        await fetch(`/back-end/public/api/checkDownload/${course_id}/${module_id}`).then(
            response => response.json()).then(
                download => {
                    if (download.msg === "not authorized") history.push({ pathname: '/not-found' })
                    else setDownload(download)
                }
            )
        await fetch(`/back-end/public/api/getCourseType/${course_id}`).then(
            response => response.json()).then(
                json => {
                    if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                    else setCourseType(json)
                }

            )

            await fetch(`/back-end/public/api/getModules/${course_id}/${section_id}`).then(
                response => response.json()).then(
                    json => {
                        if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                        else setModules(json)
                    }
                )

               
    }

    async function getModuleTime(time) {
        if (time.length === 0) {
            await fetch(`/back-end/public/api/getModuleTime/${course_id}/${module_id}`).then(
                response => response.json()).then(
                    time => {
                        if (time.msg === "not authorized") history.push({ pathname: '/not-found' })
                        else setMinutes(time)


                    }
                ).then(() => setCheck(true))
        }
    }

    async function updateStatus() {
        //finished reading
        if (user) {
            const formData = new FormData();
            formData.append('course_id', course_id)
            formData.append('module_id', module_id)

            await fetch("/back-end/public/api/updateStatus", {
                method: "POST",
                body: formData
            }).then(()=>  getAllowedResources())

        }

    }
    function handlPageChange(event) {
        localStorage.setItem(`${user.id}_module_${module_id}`, JSON.stringify({ page: event.currentPage }))
        if (user && event.currentPage === event.doc['_pdfInfo'].numPages - 1 && user.user_type === 'USR')
            updateStatus()
    }

    async function getAllowedResources() {
        await fetch(`/back-end/public/api/getModuleStatus/${course_id}/${section_id}`)
            .then(
                response => response.json()).then(
                    json => {
                        if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                        else setModuleStatus(json)
                    }
                )
    }

    function nextModule()
  {
   

 if((courseType && courseType.type === 'Normal'  &&  moduleStatus.filter(function (val) {
    return (val.module_id ===  parseInt(module_id) && val.finished_reading === 1); }).length > 0) || 
    (courseType && courseType.type === 'Mandatory' &&  moduleStatus.filter(function (val) {
    return (val.module_id ===  parseInt(module_id) && val.finished_reading === 1 && val.time === 0);
}).length > 0 && JSON.parse(localStorage.getItem(`${user.id}_${module_id}_scnd`) === '0')))
 {   
     
    var index;
    var id;
    var type;
    index = modules && modules.findIndex(x => x.id === parseInt(module_id)) +1;
    modules && modules.map((item,i)=>{
        if(i === index)
            {
                id = item.id
                  type = item.type  }
        return true;
    })

   
    if(type === 'pdf')
    {history.push({
        pathname: `/home/corsi/${course_id}/moduli/${section_id}/pdf/${id}`,
    });
    window.location.reload(false);
}

      if(type === 'video')
      history.push({
        pathname: `/home/corsi/${course_id}/moduli/${section_id}/video/${id}`,
    });
    if(type === 'ppt')
    history.push({
        pathname: `/home/corsi/${course_id}/moduli/${section_id}/ppt/${id}`,
    });
}
else {
    error()
}
  
  }
  function prevModule()
  {
    var index;
    var id;
    var type;
    index = modules && modules.findIndex(x => x.id === parseInt(module_id)) -1;
   modules && modules.map((item,i)=>{
        if(i === index)
            {
                id = item.id
                  type = item.type  }
        return true;
    })

   
    if(type === 'pdf')
    {history.push({
        pathname: `/home/corsi/${course_id}/moduli/${section_id}/pdf/${id}`,
    });
    window.location.reload(false);
}

      if(type === 'video')
      history.push({
        pathname: `/home/corsi/${course_id}/moduli/${section_id}/video/${id}`,
    });
    if(type === 'ppt')
    history.push({
        pathname: `/home/corsi/${course_id}/moduli/${section_id}/ppt/${id}`,
    });
  
  }
   
    return (
        <Container maxWidth="lg">
            <Grid container spacing={1} justify="center">
                <Grid item xs={12} lg={10} style={{ display: 'flex', justifyContent: 'space-between' }} >
                    <Breadcrumb />
                    {courseType && courseType.type === 'Mandatory' && minutes >= '0' ?
                        <Grid item xs={4} sm={3} lg={3} style={{ textAlign: 'center' }}>
                            <Typography variant='h6' style={{ paddingLeft: '10px', paddingRight: '10px', backgroundColor: 'gold' }}>
                                {minutes < 10 ? 0 : null}{minutes}:{seconds < 10 ? 0 : null}{seconds} / {moduleTime < 10 ? 0 : null}{moduleTime} minuti
                            </Typography>
                        </Grid> : null}
                </Grid>
                {url ?
        <Grid item xs={12} lg={10} style={{display:'flex', justifyContent:'end'}}>
        {modules && modules.findIndex(x => x.id === parseInt(module_id)) === 0 ? null 
        : <Button size="small" type="primary" onClick={prevModule} style={{marginRight:'20px'}}>Precedente</Button>}
         {modules && modules.map(x => x.id).lastIndexOf(parseInt(module_id))+1 === modules.length  ? null 
         : <Button size="small" type="primary" onClick={nextModule}>Prossimo modulo</Button>}
        </Grid> : null}
                {url ? <Grid item xs={12} lg={10}>
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                        <div style={{
                            border: '1px solid rgba(0, 0, 0, 0.3)',
                            height: '480px',
                        }}>

                            <Viewer
                                fileUrl={url}
                                defaultScale={SpecialZoomLevel.ActualSize}
                                plugins={[
                                    defaultLayoutPluginInstance

                                ]}
                                onPageChange={handlPageChange}
                                initialPage={current_page ? current_page.page : 0}
                            />
                        </div>
                    </Worker>
                </Grid> : null}
                {url ?
        <Grid item xs={12} lg={10} style={{display:'flex', justifyContent:'end'}}>
          {modules && modules.findIndex(x => x.id === parseInt(module_id)) === 0 ? null
          : <Button size="small" type="primary" onClick={prevModule} style={{marginRight:'20px'}}>Precedente</Button>}
         {modules && modules.map(x => x.id).lastIndexOf(parseInt(module_id))+1 === modules.length  ? null
         : <Button size="small" type="primary" onClick={nextModule}>Prossimo modulo</Button>}
        </Grid> : null}

            </Grid>

        </Container>

    );
}

export default withRouter(ReadModule);
