import "../App.css";
import React, { useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { useHistory, useParams} from "react-router-dom";
import InputLabel from "@material-ui/core/InputLabel";
import NativeSelect from "@material-ui/core/NativeSelect";
import FormHelperText from "@material-ui/core/FormHelperText";
import { useStyles } from "../components/MainTheme.js";
import { Upload, Button, Image, message, Input } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import ControlledEditor from "../components/EditorContainer";
import { useForm } from "react-hook-form";
import MuiAlert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar';

function UpdateTestTitleFeedback() {

    const { course_id } = useParams()
    const { section_id } = useParams()

    const classes = useStyles();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [testPassed, setTestPassed] = useState(null);
    const [testNotPassed, setTestNotPassed] = useState(null);
    const [title, setTitle] = useState(null);
    const [error, setError] = useState(false);
    const [notSaved, setNotSaved] = useState(false);

    
    const { TextArea } = Input;
    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    
  useEffect(() => {
    getData()

  }, [])

  async function getData() {

    if(section_id === '0')  { 
        await fetch(`/back-end/public/api/getTestTitleFinal/${course_id}`).then(
      response => response.json()).then(
        json => {
            setTestPassed(json.if_pass_test)
            setTestNotPassed(json.if_didnt_pass_test)
          setTitle(json.title)
        }
      )
    }
      else
      {
        await fetch(`/back-end/public/api/getTestTitle/${course_id}/${section_id}`).then(
            response => response.json()).then(
              json => {
                  setTestPassed(json.if_pass_test)
                  setTestNotPassed(json.if_didnt_pass_test)
                setTitle(json.title)
      }
            )
    }
  }
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = async (data) => {
        setLoading(true);

        if((testPassed && testPassed.trim().length !== 7 )&& ( testNotPassed  && testNotPassed.trim().length !== 7))
          {  
              
            const formData = new FormData();
           
            formData.append("course_id", course_id);
            formData.append("section_id", section_id);
            formData.append("title", data.title);
            formData.append("test_passed", testPassed);
            formData.append("test_not_passed", testNotPassed);
           
            if(section_id === '0') { 
                await fetch("/back-end/public/api/updateTestTitleFinal", {
                method: "POST",
                body: formData,
            }).then(response => response.json()).then( json => {
                if (json.msg === 'success') {
                    setLoading(false)
                    history.goBack(1)
                  }else {
                    setLoading(false)
                    setNotSaved(true)
                  }
            });
        }else{
            await fetch("/back-end/public/api/updateTestTitle", {
                method: "POST",
                body: formData,
            }).then(response => response.json()).then( json => {
                if (json.msg === 'success') {
                    setLoading(false)
                    history.goBack(1)
                  }else {
                    setLoading(false)
                    setNotSaved(true)
                  }
            });     
            }
        
        }else {

         setError(true)
        setLoading(false);
    
        }
    };


    const handleCallback = (data) => {
        setTestPassed(data.target.value);
    };

    const handleCallback2 = (data) => {
        setTestNotPassed(data.target.value);
    };

    function cancel() {
        history.goBack(1)
    }

    const handleError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setError(false);
        setNotSaved(false);
    }

    return (
        <Container maxWidth="lg">
            <Grid item xs={12} sm={12} lg={12}>
                <Paper style={{ padding: "16px" }}>
                    <Typography
                        variant="h6"
                        className={classes.title}
                        style={{ marginBottom: "1rem" }}
                    >
                        Nome del test
                    </Typography>
                   {title ?
                    <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                        <Grid container spacing={3}>
                            <Grid item sx={12} sm={12} lg={12}>
                                <TextField
                                    {...register("title", { required: true })}
                                    size="small"
                                    fullWidth
                                    variant="outlined"
                                    required
                                    id="standard-helperText"
                                    label="Nome del test"
                                    defaultValue={title}
                                    error={errors?.title?.type === "required" && true}
                                    helperText={
                                        errors?.title?.type === "required" &&
                                        "Il campo è obbligatorio"
                                    }
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} lg={12}>
                                <InputLabel htmlFor="age-native-helper">
                                Feedback superamento test
                                </InputLabel>
                               <TextArea defaultValue={testPassed} onChange={handleCallback} rows={3} />
                            </Grid>

                            <Grid item xs={12} sm={12} lg={12}>
                                <InputLabel htmlFor="age-native-helper">
                                Feedback se test non superato
                                </InputLabel>
                                <TextArea defaultValue={testNotPassed} onChange={handleCallback2} rows={3} />
                            </Grid>
                            <Grid item sx={12} sm={12} lg={12}
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "flex-end",
                                }}
                            >
                                <Button style={{ width: "120px" }} 
                                className={classes.button}
                                onClick={cancel}
                                disabled={loading}>
                                    Cancella 
                                </Button>
                                <Button
                                    htmlType="submit"
                                    type="primary"
                                    className={classes.button}
                                    style={{
                                        width: "120px",
                                    }}
                                    loading={loading}
                                >
                                    Salva
                                </Button>
                            </Grid>
                        </Grid>
                    </form> : null}
                </Paper>
                <Snackbar open={error} autoHideDuration={2000} onClose={handleError} >
                                    <Alert onClose={handleError} severity="error">
                                        Please, add a feedBack
                                    </Alert>
                                </Snackbar>

                                <Snackbar open={notSaved} autoHideDuration={2000} onClose={handleError} >
                                    <Alert onClose={handleError} severity="error">
                                       Error, please try again!
                                    </Alert>
                                </Snackbar>
            </Grid>
        </Container>
    );
}

export default UpdateTestTitleFeedback;
