import React, { useContext, useState, useEffect } from "react";
import Container from '@material-ui/core/Container';
import Context from '../Context'
import Paper from '@material-ui/core/Paper';
import { withRouter, useHistory, useParams } from "react-router-dom"
import Typography from '@material-ui/core/Typography';
import { useStyles } from "../components/MainTheme.js";
import Grid from '@material-ui/core/Grid';
import Breadcrumb from "../components/Breadcrumb";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import DoneIcon from '@material-ui/icons/Done';
import Tooltip from '@material-ui/core/Tooltip';
import { Button, Progress, Modal, Result } from 'antd';
import { ExclamationCircleOutlined, SmileOutlined , FrownOutlined, LockOutlined } from '@ant-design/icons';

function UserModule(props) {

    const { user } = useContext(Context)
    const { course_id } = useParams()

    const classes = useStyles();
    const [sections, setSections] = useState([]);
    const [coursePicture, setCoursePicture] = useState('');
    const history = useHistory();

    const [courseTitle, setCourseTitle] = useState(null);
    const [courseDescription, setCourseDescription] = useState(null);
    const [sectionStatus, setSectionStatus] = useState([]);
    const [passFinalTest, setPassFianlTest] = useState(false)
    const [percent, setPercent] = useState(0);
    const [minutes, setMinutes] = useState(null)
    const [countQuestions, setQuestion] = useState(null);

    const [answerScore, setScoreAnswers] = useState([])
    const [testScore, setTestScore] = useState('')
    const [passedTest, setPassedTest] = useState(null)
    const [didNotPassed, setDidNotPassed] = useState(null)
    const [timesPasseTest, setTimesPasseTest] = useState(null);

    const [allowedSections, setAllowedSections] = useState([])

    const [image, setImage] = useState(null)

    const { confirm } = Modal;


    useEffect(() => {
        getData()
    }, [])

    
    useEffect(()=>{

        if(answerScore.length > 0 && testScore && answerScore && (((answerScore*100)/testScore)  < 70))
           {
               addUserTestFaild()
               checkTimesPasseTest()
            }

            if(answerScore.length > 0 && testScore && answerScore && (((answerScore*100)/testScore)  >= 70))
                 deleteUserTestFaild()

    },[testScore, answerScore])

    async function checkTimesPasseTest()
    {
        await fetch(`/back-end/public/api/checkTimesPasseTestFinal/${course_id}`).then(
            response => response.json()).then(
                json => {
                    if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                    else {
                        setTimesPasseTest(json)
                    }
                }
            )
    }

    
    async function addUserTestFaild()
    {
        
        const formData = new FormData();
        formData.append('course_id', course_id)

        await fetch("/back-end/public/api/addUserTestFaildFinal", {
            method: "POST",
            body: formData
        })
    }
    async function deleteUserTestFaild()
    {
        
        const formData = new FormData();
        formData.append('course_id', course_id)

        await fetch("/back-end/public/api/deleteUserTestFaildFinal", {
            method: "POST",
            body: formData
        })
    }


    async function getData() {
        await fetch(`/back-end/public/api/getSections/${course_id}`).then(
            response => response.json()).then(
                json => {
                    if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                    else setSections(json)
                }
            )

            await fetch(`/back-end/public/api/getAllowedSections/${course_id}`).then(
                response => response.json()).then(
                    json => {
                        if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                        else setAllowedSections(json)
                    })

        await fetch(`/back-end/public/api/getCoursePicture/${course_id}`).then(
            response => response.json()).then(
                json => {
                    if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                    else setCoursePicture(json)
                }
            )
        await fetch(`/back-end/public/api/courseTitleDescription/${course_id}`).then(
            response => response.json()).then(
                json => {
                    if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                    else {
                        setCourseTitle(json.title)
                        setCourseDescription(json.description)
                    }
                }
            )

        await fetch(`/back-end/public/api/getCountQuestionFinal/${course_id}`).then(
            response => response.json()).then(
                json => {
                    if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                    setQuestion(json)
                    if (json > 0) {
                        checkIfOtherTestPassed()
                        getScore()
                    }
                }
            )
          
            await fetch(`/back-end/public/api/getTestTitleFinalUser/${course_id}`)
            .then((response) => response.json())
            .then(json =>{ 
                if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                else 
              {  setPassedTest(json.if_pass_test)
                   
                    setDidNotPassed(json.if_didnt_pass_test)
                }
            }
            )


        if (user) {
            await fetch(`/back-end/public/api/getSectionStatus/${course_id}`)
                .then(
                    response => response.json()).then(
                        json => {
                            if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                            else setSectionStatus(json)
                        }
                    )
        }

        await fetch("/back-end/public/api/getBackgroundImage/section").then(
            response => response.json()).then(
                (json) => setImage(json.image_path.replace('\\', "/"))
            )
    }
    
    async function getScore() {

        if (user) {
            await fetch(`/back-end/public/api/getTestScoreFinal/${course_id}`).then(
                response => response.json()).then(
                    json => {
                        if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                        else setScoreAnswers(json)
                    }
                )

            await fetch(`/back-end/public/api/getTotalUserScoreQuestionFinal/${course_id}`).then(
                response => response.json()).then(
                    json => {
                        if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                        else setTestScore(json)
                    }
                )

        }

       

    }

    async function checkIfOtherTestPassed() {

        await fetch(`/back-end/public/api/checkIfOtherTestPassed/${course_id}`)
            .then(
                response => response.json()).then(
                    json => {
                        if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                        else if (json.msg === 'pass_final_test') {
                            setPassFianlTest(true)
                        }
                    }
                )
    }

    useEffect(() => {

        calculPercent()

    }, [sectionStatus])



    useEffect(() => {

        async function getUserTime() {
            await fetch(`/back-end/public/api/getUserTestTimeFinal/${course_id}`).then(
                response => response.json()).then(
                    json => {
                        if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                        else if (json.msg !== 'not found') setMinutes(json)
                    }
                )
        }
        if (percent === 100) {
            getUserTime()
        }

    }, [percent])

    function calculPercent() {

        let moduleStatusLength = sectionStatus.filter(function (val) { return (val.finished_section === 1); }).length

        let sectionsLength = sections.length

        let per = ((moduleStatusLength) * 100) / sectionsLength

        setPercent(Math.floor(per))

    }

    function passeTest() {
        history.push({
            pathname: `/home/corsi/${course_id}/0/test`,

        })
    }

    

    function showConfirm() { 
        
        if (countQuestions && countQuestions > 0) {
            if (passFinalTest) {
                confirm({
                    title: 'Importante!',
                    icon: <ExclamationCircleOutlined />,
                    content: (
                        <div>
                            <p>Il tempo a disposizione è di: {minutes} minuti</p>
                    <p>Assicurati di essere connesso ad una connessione internet stabile</p>
                    <p>Puoi selezionare una o più risposte che repute esatte</p>
                        </div>
                    ),
                    cancelText: 'Cancella',
                    onOk() {
                        passeTest()
                    },


                })
            } else canNotPassTest()

        } else info()

    }

    function canNotPassTest() {
        Modal.info({
            title: 'Importante!',
            content: (
                <div>
                    <p>Devi superare prima I test intermedi</p>
                </div>
            ),
            onOk() { },
        });
    }

    function info() {
        Modal.info({
            title: 'Importante!',
            content: (
                <div>
                    <p>Il test finale sarà presto disponibile</p>
                </div>
            ),
            onOk() { },
        });
    }

    function showConfirm2() {
      
        if(timesPasseTest < 3 && timesPasseTest) { 
            confirm({
               title: 'Importante!',
               icon: <ExclamationCircleOutlined />,
               content: (
                   <div>
                     
                       <p>Questo è il: {timesPasseTest +1 } tentative che effettui per il test</p>
                     <p>Il tempo a disposizione è di: {minutes} minuti</p>
                     <p>Assicurati di essere connesso ad una connessione internet stabile</p>
                    <p>Puoi selezionare una o più risposte che repute esatte</p>
                   </div>
               ),
               cancelText: 'Cancella',
               onOk() {
                   passeTest()
               },
   
   
           })
          }else if(timesPasseTest){
              Modal.warning({
                  title: 'Importante!',
                  content: (
                      <div>
                          <p>Hai sbagliato il test 3 volte</p>
                          <p>non hai più tentative a disposizione</p>
                          <p>contattaci per richiedere lo sblocco </p>
                      </div>
                  ),
          })
      }
       }

    function testResult() {
        history.push({
            pathname: `/home/corsi/${course_id}/0/test-risultati`,

        })
    }
    const styles = {
        paperContainer: {
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
            backgroundColor: '#391085',
            height: '600px',
            marginBottom: '2rem',
            padding: '10px 70px 10px 70px',
            borderRadius: '0',
            color: '#fff',
            marginTop: '-3rem',
            fontSize: '40px',
            display: 'flex',
            justifyContent: "center",
            alignItems: 'center',
            flexDirection: 'column',
            backgroundImage: `${image ? `url(/back-end/public/${image})` : null}`
        }
    };

    function goToSection(item) {
        history.push({
            pathname: `/home/corsi/${course_id}/moduli/${item.id}`,
        })
    }
   
    return (
        <div>
            <Paper style={styles.paperContainer}>
            <Grid item style={{ textAlign: 'center' }}>
                    {courseTitle ? <Typography variant="h4" color="inherit">{courseTitle}</Typography> : null}
                    {courseDescription ? 
                    <Grid item sx={12} sm={12} lg={12}>
               <Typography variant="h6" color="inherit" style={{fontWeight: '400', fontSize: '1rem'}}> 
               <div dangerouslySetInnerHTML={{ __html: courseDescription }} >
                    </div> </Typography>
                    </Grid> :null}
                </Grid>
            </Paper>

            <Container maxWidth="lg">
                <Grid container spacing={3} justify='center'>
                    <Grid item xs={12} sm={12} lg={8}>
                        <Breadcrumb />
                        <Paper>
                            <Grid item style={{ display: 'flex', padding: '20px 30px', justifyContent: 'space-between' }}>
                                <Typography variant='h5' style={{
                                    color: '#252525',
                                    fontWeight: '600',
                                }}>Sezioni</Typography>
                                <span style={{
                                    fontSize: '22px'
                                }}>{sections.length}</span>
                            </Grid>
                            <List component="nav" aria-label="main mailbox folders">
                                {sections.map((item, index) => (
                                    <ListItem key={index} button onClick={() => goToSection(item)}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'flex-start'
                                        }}
                                        disabled={(index <= allowedSections.length) || index === 0 ? false : true}>
                                        <div className={classes.videoImage}>
                                            <img alt='' src={coursePicture ? '/back-end/public/' + coursePicture[0].replace('\\', "/") : null}
                                                style={{
                                                    display: 'block',
                                                    width: '125px',
                                                    marginRight: '20px',
                                                    height: '80px'
                                                }}></img>
                                        </div>
                                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>

                                            <Typography variant='h6' style={{
                                                marginTop: '0px',
                                                marginBottom: '.25rem',
                                                fontWeight: '500',
                                                color: '#000000',
                                            }} >
                                                {item.section_name}
                                            </Typography>
                                            {sectionStatus.filter(function (val) {
                                                return (val.section_id === item.id && val.finished_section === 1);
                                            }).length > 0 ?
                                                <DoneIcon style={{ color: '#33eb91' }} />
                                                : null}
                                               
                                                {(index <= allowedSections.length) || index === 0 ? false : 
                                                <LockOutlined style={{ fontSize: '25px' }} />}
                                        </div>
                                    </ListItem>

                                ))}
                            </List>
                        </Paper>
                    </Grid>
                    <Grid container spacing={3} item xs={12} sm={12} lg={3} style={{ height: '100%', marginTop: '2rem' }}>
                        {courseTitle ?
                            <Grid item sm={8} lg={12}>
                                <Paper style={{ padding: '16px', textAlign: 'center' }}>
                                    <Typography variant='h6' style={{ marginBottom: '1rem' }}>
                                        {courseTitle}
                                    </Typography>
                                    <Progress percent={percent} />
                                    {answerScore.length === 0 ?
                                        <Tooltip arrow={true}
                                            disableHoverListener={percent === 100 ? true : false} placement="top"
                                            title={percent === 100 ? "Avvia test " : "Devi visualizzare tutti I Contenuti prima  di effettuare il test finale"}>
                                            <span>
                                                <Button
                                                    onClick={() => showConfirm()}
                                                    type='primary' style={{
                                                        marginTop: '1rem',

                                                    }} disabled={percent === 100 ? false : true} >
                                                    Vai al test finale
                                                </Button>
                                            </span>
                                        </Tooltip> : null}
                                </Paper>
                            </Grid> : null}

                        {answerScore.length > 0 && testScore && answerScore && (((answerScore*100)/testScore)  >= 70) && passedTest ?
                            <Grid item lg={12}>
                                <Paper >
                                     <Result style={{ padding: '27px' }}
                                        status='success'
                                        icon={<SmileOutlined />} 
                                        title={`${passedTest}`}
                                        subTitle={`Punteggio: ${Math.round((answerScore*100)/testScore)}%`}
                                        extra={<Button type="primary" onClick={testResult}>Controlla I risultati</Button>}
                                    /> 
                                </Paper>
                            </Grid> : null}
                            {answerScore.length > 0 && testScore && answerScore && (((answerScore*100)/testScore)  < 70) && didNotPassed?
                            <Grid item lg={12}>
                                <Paper >
                                <Result style={{ padding: '27px' }}
                                    status='error'
                                    icon={<FrownOutlined  />}
                                    title= {`${didNotPassed}`}
                                    subTitle={`Punteggio: ${Math.round((answerScore*100)/testScore)}%`}
                                    extra={<Button
                                        onClick={() => showConfirm2()}
                                        type='primary' style={{
                                            marginTop: '1rem',

                                        }} disabled={percent === 100 ? false : true} >
                                        Riprova test
                                    </Button> }

                                />
                                </Paper>
                            </Grid> : null}
                    </Grid>

                </Grid>
            </Container>
        </div >
    );
}

export default withRouter(UserModule);
