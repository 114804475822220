import React, { useContext, useState, useEffect } from "react";
import Container from '@material-ui/core/Container';
import Context from '../Context'
import Paper from '@material-ui/core/Paper';
import { withRouter, useHistory, useParams } from "react-router-dom"
import Typography from '@material-ui/core/Typography';
import { useStyles } from "../components/MainTheme.js";
import Breadcrumb from "../components/Breadcrumb";
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import DoneIcon from '@material-ui/icons/Done';
import Tooltip from '@material-ui/core/Tooltip';
import { Result, Progress, Button, Modal } from 'antd';
import { SmileOutlined, ExclamationCircleOutlined, LockOutlined , FrownOutlined} from '@ant-design/icons';



function UserSection(props) {

    const { section_id } = useParams()
    const { course_id } = useParams()

    const { user } = useContext(Context)

    const classes = useStyles();
    const history = useHistory();
    const [modules, setModules] = useState([]);
    const [section_name, setSectionName] = useState("");
    //const [videos, setVideos] = useState([]);
    const [countQuestions, setQuestion] = useState(null);
    const [courseType, setCourseType] = useState(null);

    const [timesPasseTest, setTimesPasseTest] = useState(null);

    const [answerScore, setScoreAnswers] = useState([])
    const [testScore, setTestScore] = useState('')
    const [moduleStatus, setModuleStatus] = useState([]);
    //const [videoStatus, setVideoStatus] = useState([]);
    const [minutes, setMinutes] = useState(null)

    const [percent, setPercent] = useState(0);

    const [passedTest, setPassedTest] = useState(null)
    const [didNotPassed, setDidNotPassed] = useState(null)

    const [allowedModule, setAllowedModule] = useState([])
    const [allowedModuleNormal, setAllowedModuleNormal] = useState([])
    //const [allowedVideo, setAllowedVideo] = useState([])

    const { confirm } = Modal;

    let time_left = JSON.parse(localStorage.getItem(`${user.id}_scnd`))


    const [coursePicture, setCoursePicture] = useState(null);


    useEffect(() => {
        getData()
        if (user.user_type === 'USR')
            setUserSectionStatus()
    }, [])

    useEffect(()=>{

        if(answerScore.length > 0 && testScore && answerScore && (((answerScore*100)/testScore)  < 70))
           {
                addUserTestFaild()
                checkTimesPasseTest()
            }

            if(answerScore.length > 0 && testScore && answerScore && (((answerScore*100)/testScore)  >= 70))
                 deleteUserTestFaild()

    },[testScore, answerScore])

    async function checkTimesPasseTest()
    {
        await fetch(`/back-end/public/api/checkTimesPasseTest/${course_id}/${section_id}`).then(
            response => response.json()).then(
                json => {
                    if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                    else {
                        setTimesPasseTest(json)
                    }
                }
            )
    }

    async function deleteUserTestFaild()
    {
        
        const formData = new FormData();
        formData.append('section_id', section_id)
        formData.append('course_id', course_id)

        await fetch("/back-end/public/api/deleteUserTestFaild", {
            method: "DELETE",
            body: formData
        })
    }


    async function addUserTestFaild()
    {
        
        const formData = new FormData();
        formData.append('section_id', section_id)
        formData.append('course_id', course_id)

        await fetch("/back-end/public/api/addUserTestFaild", {
            method: "POST",
            body: formData
        })
    }

    async function setUserSectionStatus() {

        const formData = new FormData();
        formData.append('section_id', section_id)
        formData.append('course_id', course_id)

        await fetch("/back-end/public/api/setUserSectionStatus", {
            method: "POST",
            body: formData
        })


    }

    useEffect(() => {


        async function updateSectionStatus() {
            if ((moduleStatus.length > 0) ) {
                if (moduleStatus.filter(function (val) {
                    return (val.finished_reading === 1);
                }).length === modules.length) {

                    const formData = new FormData();
                    formData.append('section_id', section_id)
                    formData.append('course_id', course_id)

                    await fetch("/back-end/public/api/updateSectionStatus", {
                        method: "POST",
                        body: formData
                    })


                }
            }
        }

        updateSectionStatus()

        calculPercent()

    }, [moduleStatus])

    function calculPercent() {

        let moduleStatusLength = moduleStatus.filter(function (val) { return (val.finished_reading === 1); }).length

        // let videoStatusLength = modules.length && videoStatus.filter(function (val) { return (val.finished_watching === 1); }).length

        // let length = videoStatusLength + moduleStatusLength

        // let total = modules.length + videos.length

        let per = ((moduleStatusLength) * 100) / modules.length

        setPercent(Math.floor(per))

    }
    async function getData() {

        await fetch(`/back-end/public/api/getCourseTitlePicture/${course_id}`).then(
            response => response.json()).then(
                json => {
                    if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                    else {
                        setCoursePicture(json.picture_path)
                        setCourseType(json.type)
                    }
                }
            )

        await fetch(`/back-end/public/api/getSectionName/${course_id}/${section_id}`).then(
            response => response.json()).then(
                json => {
                    if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                    else setSectionName(json)
                }
            )

        await fetch(`/back-end/public/api/getModules/${course_id}/${section_id}`).then(
            response => response.json()).then(
                json => {
                    if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                    else setModules(json)
                }
            )

        // await fetch(`/back-end/public/api/getvideos/${course_id}/${section_id}`).then(
        //     response => response.json()).then(
        //         json => {
        //             if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
        //             else setVideos(json)
        //         }
        //     )

         await fetch(`/back-end/public/api/getCountQuestion/${course_id}/${section_id}`).then(
            response => response.json()).then(
                json => {
                    if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                    setQuestion(json)
                    if (json > 0) {
                        getScore()
                    }
                }
            )

        // await fetch(`/back-end/public/api/allowPassTest/${section_id}/${user.id}`).then(
        //     response => response.json()).then(
        //         json => {
        //             setAllowPassTest(json)
        //         }
        //     )

        await fetch(`/back-end/public/api/getModuleStatus/${course_id}/${section_id}`)
            .then(
                response => response.json()).then(
                    json => {
                        if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                        else setModuleStatus(json)
                    }
                )

        // await fetch(`/back-end/public/api/getVideoStatus/${course_id}/${section_id}`)
        //     .then(
        //         response => response.json()).then(
        //             json => {
        //                 if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
        //                 else setVideoStatus(json)
        //             }
        //         )


        await fetch(`/back-end/public/api/getTestTitleUser/${course_id}/${section_id}`)
        .then((response) => response.json())
        .then(json =>{ 
            if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
            else 
          {  setPassedTest(json.if_pass_test)
               
                setDidNotPassed(json.if_didnt_pass_test)
            }
        }
        )

        if (courseType === 'Mandatory') {
            getAllowedResources()
        }



        // if (courseType === 'Mandatory') {
        //     getTotalTime()
        // }


        // await fetch(`/back-end/public/api/getModuleTimeLeftAll/${section_id}`).then(
        //     response => response.json()).then(
        //         time => {
        //             setTimeLeft(time)
        //         })

    }

    // async function getTotalTime() {
    //     await fetch(`/back-end/public/api/getTotalTime/${course_id}`).then(
    //         response => response.json()).then(
    //             json => setTotalTime(json)

    //         )
    // }

    async function getAllowedResources() {
        await fetch(`/back-end/public/api/getAllowedModule/${course_id}/${section_id}`).then(
            response => response.json()).then(
                json => {
                    if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                    else setAllowedModule(json)
                })

                await fetch(`/back-end/public/api/getAllowedModuleNormal/${course_id}/${section_id}`).then(
                    response => response.json()).then(
                        json => {
                            if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                            else setAllowedModuleNormal(json)
                        })

    }

    useEffect(() => {


        async function getUserTestTime() {
            await fetch(`/back-end/public/api/getUserTestTime/${course_id}/${section_id}`).then(
                response => response.json()).then(
                    json => {
                        if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                        else setMinutes(json)
                    }
                )
        }
        if (percent === 100) {
            getUserTestTime()

        }

        getAllowedResources()

    }, [percent, time_left])


    

    async function getScore() {

        if (user) {
            await fetch(`/back-end/public/api/getTestScore/${course_id}/${section_id}`).then(
                response => response.json()).then(
                    json => {
                        if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                        else setScoreAnswers(json)
                    }
                )

            await fetch(`/back-end/public/api/getTotalUserScoreQuestion/${course_id}/${section_id}`).then(
                response => response.json()).then(
                    json => {
                        if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                        else setTestScore(json)
                    }
                )
        }
    }
    function goToModule(item) {

        if(item.type === 'pdf')
        history.push({
            pathname: `/home/corsi/${course_id}/moduli/${section_id}/pdf/${item.id}`,
        })
        else if(item.type === 'ppt')
        history.push({
            pathname: `/home/corsi/${course_id}/moduli/${section_id}/ppt/${item.id}`,
        })
        else if(item.type === 'video')
        history.push({
            pathname: `/home/corsi/${course_id}/moduli/${section_id}/video/${item.id}`,
        })
    }

    function goToVideo(item) {
        // history.push({
        //     pathname: `/home/course-sections/${course_id}/modules/${section_id}/videos/${item.id}`,

        // })
    }


    function passeTest() {
        history.push({
            pathname: `/home/corsi/${course_id}/moduli/${section_id}/test`,

        })
    }


    function testResult() {
        history.push({
            pathname: `/home/corsi/${course_id}/moduli/${section_id}/test-risultati`,

        })
    }

    function showConfirm() {
      
       confirm({
            title: 'Importante!',
            icon: <ExclamationCircleOutlined />,
            content: (
                <div>
                    <p>Il tempo a disposizione è di: {minutes} minuti</p>
                    <p>Assicurati di essere connesso ad una connessione internet stabile</p>
                    <p>Puoi selezionare una o più risposte che repute esatte</p>
                </div>
            ),
            cancelText: 'Cancella',
            onOk() {
                passeTest()
            },
           


        });
    }

    function showConfirm2() {
      
      if(timesPasseTest < 3 && timesPasseTest) { 
          confirm({
             title: 'Importante!',
             icon: <ExclamationCircleOutlined />,
             content: (
                 <div>
                     <p>Questo è il: {timesPasseTest +1 } tentative che effettui per il test</p>
                     <p>Il tempo a disposizione è di: {minutes} minuti</p>
                     <p>Assicurati di essere connesso ad una connessione internet stabile</p>
                    <p>Puoi selezionare una o più risposte che repute esatte</p>
                 </div>
             ),
             cancelText: 'Cancella',
             onOk() {
                 passeTest()
             },
 
 
         })
        }else if(timesPasseTest){
            Modal.warning({
                title: 'Importante!',
                content: (
                    <div>
                        <p>Hai sbagliato il test 3 volte</p>
                        <p>non hai più tentative a disposizione</p>
                        <p>contattaci per richiedere lo sblocco</p>
                    </div>
                ),
        })
    }
     }



    return (
        <div >
            <Container maxWidth="lg">

                <Grid container spacing={3} justify='center'>

                    <Grid item xs={12} sm={8} lg={8}>
                        <Breadcrumb />
                        <Paper>
                            <Grid item style={{ display: 'flex', padding: '20px 30px', justifyContent: 'space-between' }}>
                                <Typography variant='h5' style={{
                                    color: '#252525',
                                    fontWeight: '600',
                                }}>Moduli</Typography>
                                <span style={{
                                    fontSize: '22px'
                                }}>{modules.length}</span>
                            </Grid>
                            <List component="nav" aria-label="main mailbox folders">
                                {modules.map((item, index) => (
                                    <ListItem key={index} button onClick={() => goToModule(item)}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'flex-start'
                                        }}
                                        disabled={courseType && courseType === 'Mandatory' ?
                                            (time_left === 0 && index <= allowedModule.length) || index === 0 ? false : true
                                            
                                            : (index <= allowedModuleNormal.length) || index === 0 ? false : true}

                                    >
                                        <div className={classes.videoImage}>
                                            <img alt='' src={coursePicture ? '/back-end/public/' + coursePicture.replace('\\', "/") : null}
                                                style={{
                                                    display: 'block',
                                                    width: '125px',
                                                    marginRight: '20px',
                                                    height:'80px'
                                                }}></img>
                                        </div>
                                        <Grid item xs={12} className={classes.modulesName}>
                                            <Typography variant='h6' style={{
                                                marginTop: '0px',
                                                marginBottom: '.25rem',
                                                fontWeight: '500',
                                                color: '#000000',
                                            }} >
                                                {item.module_name}
                                            </Typography>
                                            {moduleStatus.filter(function (val) {
                                                return (val.module_id === item.id && val.finished_reading === 1);
                                            }).length > 0 ?
                                                <DoneIcon style={{ color: '#33eb91' }} />
                                                : null}

                                            {courseType && courseType === 'Mandatory' ?
                                                (time_left === 0 && index <= allowedModule.length) || index === 0 ? false :
                                                    <LockOutlined style={{ fontSize: '25px' }} />
                                                : (index <= allowedModuleNormal.length) || index === 0 ? false : 
                                                <LockOutlined style={{ fontSize: '25px' }} />}
                                        </Grid>
                                    </ListItem>

                                ))}
                               
                            </List>
                        </Paper>
                    </Grid>
                    <Grid container spacing={3} item xs={12} sm={3} lg={3} style={{ height: '100%', marginTop: '2rem' }}>
                    <Grid item xs={12} sm={12} lg={12}>
                            <Paper style={{ padding: '16px', textAlign: 'center' }}>
                                <Typography variant='h6'>
                                    {section_name}
                                </Typography>

                                {countQuestions > 0 && answerScore.length === 0 ?
                                    <Tooltip arrow={true}
                                        disableHoverListener={percent === 100 ? true : false} placement="top"
                                        title={percent === 100 ? "Pass test" : "You have to read all moudules to pass the test!"}>
                                        <span>
                                            <Button
                                                onClick={() => showConfirm()}
                                                type='primary' style={{
                                                    marginTop: '1rem',

                                                }}
                                                disabled={percent === 100 ? false : true}
                                            >
                                              Avvia test 
                                            </Button>
                                        </span>
                                    </Tooltip> : null}
                             </Paper>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={12}>
                            <Card>
                                {coursePicture ? <CardMedia
                                    style={{ minHeight: '200px' }}
                                    className={classes.media}
                                    image={coursePicture ? '/back-end/public/' + coursePicture.replace('\\', "/") : null}
                                /> : null}
                                <CardContent style={{ paddingTop: '30px' }}>

                                    <Typography gutterBottom variant="h6" component="h2">
                                        {moduleStatus.filter(function (val) { return (val.finished_reading === 1); }).length
                                        } di {modules.length} moduli completati
                                    </Typography>
                                    <Progress percent={percent} />
                                </CardContent>

                            </Card>
                        </Grid>
                        {answerScore.length > 0 && testScore && answerScore && (((answerScore*100)/testScore)  >= 70) && passedTest?
                            <Grid item lg={12}>
                                <Paper >
                                    <Result style={{ padding: '27px' }}
                                        status='success'
                                        icon={<SmileOutlined />}
                                        title={`${passedTest}`}
                                        subTitle={`Punteggio: ${Math.round((answerScore*100)/testScore)}%`}
                                        extra={<Button type="primary" onClick={testResult}>Controlla I risultati</Button>}

                                    />
                                </Paper>
                            </Grid> : null}
                            {answerScore.length > 0 && testScore && answerScore && (((answerScore*100)/testScore)  < 70) && didNotPassed ?
                            <Grid item lg={12}>
                                <Paper >
                                <Result style={{ padding: '27px' }}
                                    status='error'
                                    icon={<FrownOutlined  />}
                                    title={`${didNotPassed}`}
                                    subTitle={`Punteggio: ${Math.round((answerScore*100)/testScore)}%`}
                                    extra={<Button
                                        onClick={() => showConfirm2()}
                                        type='primary' style={{
                                            marginTop: '1rem',

                                        }} disabled={percent === 100 ? false : true} >
                                       Riprova test
                                    </Button> }

                                />
                                </Paper>
                            </Grid> : null}
                   

                    </Grid>
                </Grid>
            </Container>
        </div >

    );
}

export default withRouter(UserSection);
