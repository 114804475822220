import "../App.css";
import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import { withRouter, useHistory, useParams } from "react-router-dom"
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Breadcrumb from "../components/Breadcrumb";
import Grid from '@material-ui/core/Grid';
import { useStyles } from "../components/MainTheme.js";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Popconfirm } from 'antd';
import UpdateSection from './UpdateSection'
import { Button, Menu, Dropdown } from 'antd';
import { EditOutlined, DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import AddTest from "../AdminPages/AddTest"
import Typography from "@material-ui/core/Typography";

function AddModule(props) {

    const { course_id } = useParams()

    const classes = useStyles();
    const [sections, setSections] = useState([]);

    const [section, setSection] = useState("");

    const [openSec, setOpenSec] = useState(false);

    const [disableDelete, setDisableDelete] = useState(false);
    const [questionCount, setQuestionCount] = useState(null);
    const [testScore, setTestScore] = useState(null);

    const [courseName, setCourseName] = useState(null);

    const history = useHistory();

    const [openSecUpdate, setOpenSecUpdate] = useState(false);
    const [sectionId, setSectionId] = useState(null);

    const [showTest, setShowTest] = useState(false);

    const [error, setError] = useState(false);
    const [testTitle, setTestTitle] = useState(null);



    useEffect(() => {
        getData()

    }, [])



    async function addSection() {
        setDisableDelete(true)

        if (section === '') {
            setError(true)
            setDisableDelete(false)
        }

        else {
            const formData = new FormData();
            formData.append('section_name', section)
            formData.append('course_id', course_id)

            await fetch("/back-end/public/api/addSection", {
                method: "POST",
                body: formData
            }).then(() => {
                handleClose()
                getData()
            })

        }

    }

    async function deleteSection(id) {
        setDisableDelete(true)
        await fetch("/back-end/public/api/deleteSection/" + id, {
            method: "DELETE"
        }).then(() => getData(), setDisableDelete(false))
    }
 
    async function getData() {

        await fetch("/back-end/public/api/getAdminSections/" + course_id).then(
            response => response.json()).then(
                json => setSections(json)
            );

            await fetch(`/back-end/public/api/getCourseName/${course_id}`)
            .then((response) => response.json())
            .then((json) => setCourseName(json.title));

            await fetch(`/back-end/public/api/getTestTitleFinal/${course_id}`)
      .then((response) => response.json())
      .then(json =>{ if(json.title)
      
        setTestTitle(json.title)}
      );


            await fetch(`/back-end/public/api/countQuestionsFinal/${course_id}`)
            .then((response) => response.json())
            .then((json) => setQuestionCount(json))
            .then(() => setShowTest(true));      

      await fetch(`/back-end/public/api/getTotalScoreQuestionFinalAdmin/${course_id}`)
      .then((response) => response.json())
      .then((json) => setTestScore(json));


    }

    const handleClose = () => {
        setDisableDelete(false)
        setSection('')
        if (error) {
            setError(false)

        }
        if (openSec)
            setOpenSec(false)

    };

    function addTest() {
        history.push({
            pathname: `/admin/corsi/${course_id}/sezioni/0/inserisci-domande`,
        })
    }


    const menu = (
        <Menu>
            <Menu.Item key='1' onClick={() => setOpenSec(true)}>

            Aggiungi sezione 
            </Menu.Item>
            <Menu.Item key='2' onClick={() => addTest()} >

            Aggiungi test finale
            </Menu.Item>

        </Menu>
    )

    const UpdSecCallback = (childData) => {
        setOpenSecUpdate(childData)
    }
    const handleCallbackSection = (childData) => {
        setSectionId(childData)
        getData()
    }

    function goToModule(item) {
        history.push({
            pathname: `/admin/corsi/${course_id}/sezioni/${item.id}/moduli`,
        })
    }

    function updateTestTitle()
    {
      history.push({
          pathname: `/admin/corsi/${course_id}/sezioni/0/moduli/aggiorna-nome-del-test`,
        });
    }
    return (
        <Container maxWidth="lg">
             <Breadcrumb />

{sectionId ? <UpdateSection open={openSecUpdate} section_id_clear={handleCallbackSection}
    section_id={sectionId} close={UpdSecCallback} /> : null}


<Grid item xs={12} sm={12} lg={12}>
    <Dropdown overlay={menu} placement="bottomLeft">
        <Button size='large' className={classes.button} type="primary" style={{ maxWidth: "160px", marginBottom: '2rem' }}>Aggiungi risorsa</Button>
    </Dropdown>
</Grid>

<Dialog fullWidth disableBackdropClick disableEscapeKeyDown open={openSec} onClose={handleClose}>
                    <DialogTitle>Crea sezione</DialogTitle>

                    <DialogContent>
                        <Grid item xs={12} sm={12} lg={12}>
                            <TextField
                                fullWidth
                                id="outlined-full-width"
                                label="Nome sezione"
                                placeholder="Nome sezione"

                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                size='small'
                                variant="outlined"
                                required
                                onChange={(e) => setSection(e.target.value)}
                                error={error}
                                helperText={error ? 'Il campo è obbligatorio' : null}
                            />
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} >
                        Cancella
                        </Button>
                        <Button
                            loading={disableDelete}
                            form="my-form-id"
                            type="primary"
                            className={classes.button}
                            onClick={addSection}  >
                            Salva
                        </Button>
                    </DialogActions>
                </Dialog>
              
                <Grid item xs={12} sm={12} lg={12}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} lg={9}>
            <div>
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>

                                    <TableCell >Sezione</TableCell>
                                    <TableCell >Nome sezione</TableCell>
                                    <TableCell >Modifica nome</TableCell>
                                    <TableCell >Aggiungi modulo</TableCell>
                                    <TableCell >Elimina</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sections.map((item, index) => (
                                    <TableRow key={item.id} >
                                        <TableCell > {index + 1}
                                        </TableCell>

                                        <TableCell >
                                            {item.section_name}

                                        </TableCell>
                                        <TableCell >

                                            <Button icon={<EditOutlined style={{ fontSize: "20px" }} />}
                                                disabled={openSecUpdate} onClick={() => {
                                                    setSectionId(item.id)
                                                    setOpenSecUpdate(true)
                                                }} />
                                        </TableCell>

                                        <TableCell >
                                            <Button icon={<PlusCircleOutlined style={{ fontSize: "20px" }} />}
                                                onClick={() => goToModule(item)} />

                                        </TableCell>

                                        <TableCell>
                                            <Popconfirm disabled={disableDelete} onConfirm={() => deleteSection(item.id)}
                                                title="Are you sure？" okText="Yes" cancelText="No">
                                                <Button disabled={disableDelete} icon={<DeleteOutlined style={{ fontSize: "18px" }} />} />

                                            </Popconfirm>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    </div>
                    </Grid>
          <Grid item xs={12} sm={12} lg={3}>
            <Grid item xs={12} lg={12}>
              <Paper style={{ padding: "16px", textAlign: "center" }}>
                <Typography variant="h6">{courseName}</Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Paper>
                <div style={{ textAlign: "center", background: "limegreen" }}>
                  <Typography variant="h5">Test</Typography>
                </div>
                {questionCount ? (
                  <div style={{ textAlign: "center", padding: "10px 30px" }}>
                    <Typography variant="h6">
                     Domande: {questionCount}
                    </Typography>
                    <Typography variant="h6">Punteggio: {testScore}</Typography>
                  </div>
                ) : (
                  <Typography
                    variant="h6"
                    style={{ textAlign: "center", padding: "10px 30px" }}
                  >
                    Nessun test correlate
                  </Typography>
                )}
              </Paper>
            </Grid>
          </Grid>
          {testTitle ? (
            <Grid item sx={12} sm={12} lg={12}>
                <Paper>
                <div style={{ textAlign: "center", background: "limegreen" }}>
                  <Typography variant="h5">Nome del test</Typography>
                </div>
                    </Paper>
              <Paper
                style={{
                  padding: "10px 15px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h6"> {testTitle} </Typography>
                <Button icon={<EditOutlined onClick={()=> updateTestTitle()} style={{ fontSize: "20px" }} />} />
              </Paper>
            </Grid>
          ) : null}
        </Grid>

                {showTest ? <Grid item sx={12} sm={12} lg={12}>
                    <AddTest course_id={course_id} section_id={0} />
                </Grid> : null}
            </Grid >
        </Container >

    );
}

export default withRouter(AddModule);
