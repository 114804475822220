import "../App.css";
import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { useHistory } from "react-router-dom";
import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/NativeSelect';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useStyles } from "../components/MainTheme.js";
import { Upload, Button, Image, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import ControlledEditor from "../components/EditorContainer"
import { useForm } from "react-hook-form";

function AddCourse() {

  const classes = useStyles();
  const history = useHistory();
  const [title, setTitle] = useState("");
  const [image, setImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState("");
  const [courseType, setCourseType] = useState('Normal');
  const [displayImage, setDisplayImage] = useState(null);

  const { register, handleSubmit, formState: { errors } } = useForm()

  const onSubmit = async data => {

    setLoading(true)

    if (!displayImage) {
      error()
      setLoading(false)

    } else {
      const formData = new FormData();
      formData.append('title', title)
      formData.append('type', courseType)
      if (image)
        formData.append('file', image)
      if (description && description.trim().length !== 7)
        formData.append('description', description)


      await fetch("/back-end/public/api/addcourse", {
        method: "POST",
        body: formData
      }).then(() => setLoading(false), history.push("/admin/corsi"))
    }

  }

  const handleChangeType = (event) => {
    setCourseType(event.target.value)
  };

  const file = {
    beforeUpload: file => {
      setImage(file)
      setDisplayImage(URL.createObjectURL(file))
      return false;
    }
  };

  function removedImage() {
    setDisplayImage(null)
  }

  const handleCallback = (childData) => {
    setDescription(childData)
  }

  async function error() {
    await message.error('You have to add course image', [3]);
  }

  return (

    <Container maxWidth="lg">
      <Grid item xs={12} sm={12} lg={12}>
        <Paper style={{ padding: '16px' }}>
          <Typography variant="h6" className={classes.title} style={{ marginBottom: '1rem' }}>
          Inserisci Corso
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
            <Grid container spacing={3}>
              <Grid item sx={12} sm={12} lg={12}>
                <TextField
                  {...register('title', { required: true })}
                  size="small"
                  fullWidth
                  variant='outlined'
                  required
                  id="standard-helperText"
                  label="Nome del Corso"
                  onChange={(e) => setTitle(e.target.value)}
                  error={errors?.title?.type === "required" && true}
                  helperText={errors?.title?.type === "required" && 'Il campo è obbligatorio'}
                />
              </Grid>
              <Grid item sx={12} sm={12} lg={12}>
                <InputLabel htmlFor="age-native-helper">Tipologia Corso</InputLabel>
                <NativeSelect
                  required
                  fullWidth
                  style={{ width: '30%' }}
                  value={courseType}
                  onChange={handleChangeType}
                  inputProps={{
                    name: 'type',
                  }}
                >
                  <option value='Normal'>Corsi sola visualizzazione</option>
                  <option value='Mandatory'>Formazione Obbligatoria (con Timer)</option>
                </NativeSelect>
                <FormHelperText>Seleziona la tipologia</FormHelperText>

              </Grid>
              <Grid item xs={12} sm={12} lg={12}>
                <InputLabel htmlFor="age-native-helper">Descrizione Corso</InputLabel>
                <ControlledEditor description={handleCallback} />
              </Grid>
              {displayImage ?
                <Grid item xs={12} sm={12} lg={12}>
                  <Image
                    width={300}
                    src={displayImage}
                  />
                </Grid> : null}
              <Grid item sx={12} sm={12} lg={12} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                <Upload
                  {...file}
                  listType="picture"
                  maxCount={1}
                  accept='image/*'
                  onRemove={removedImage}
                >
                  <Button icon={<UploadOutlined />} >Immagine del Corso</Button>
                </Upload>


                <Button
                  htmlType="submit"
                  type="primary"
                  className={classes.button}
                  style={{
                    width: '120px',
                  }}
                  loading={loading}
                >
                  Salva
                </Button>
              </Grid>
            </Grid>
          </form>

        </Paper>
      </Grid>

    </Container>

  );
}

export default AddCourse;
