import "../App.css";
import React, { Fragment, useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import TableContainer from '@material-ui/core/TableContainer';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useHistory, withRouter } from "react-router-dom"
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { useStyles } from "../components/MainTheme.js";
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MuiAlert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar';
import { Popconfirm, Button } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import Box from '@material-ui/core/Box';

function AddTest(props) {

    const course_id = props.course_id
    const section_id = props.section_id

    const classes = useStyles();
    const history = useHistory();
    const [answers, setAnswer] = useState([]);
    const [addTimeBtn, setAddTimeBtn] = useState(false);
    const [published, setPublished] = useState(false);
    const [questions, setQuestion] = useState([]);
    const [time, setTime] = useState("");
    const [getTime, setGetTime] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [disabledBtn, setDisabledBtn] = useState(false);
    const [disabledQuestBtn, setDisabledQuestBtn] = useState(false);
    const [error, setError] = useState(false);
    const [minQuesError, setMinQuesError] = useState(false);
    const [disabledPublish, setDisabledPublish] = useState(false);

    const [errorTime, setErrorTime] = useState(false);
    const [loading, setLoading] = useState(false);

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    useEffect(() => {
        getData()
    }, [])


    async function getData() {

        getQuestionsAnswers()

        getTestTime()

    
    }

    async function getQuestionsAnswers() {
      
      if(section_id === 0)
      {
        await fetch(`/back-end/public/api/getQuestionsFinalAdmin/${course_id}`).then(
            response => response.json()).then(
                json => setQuestion(json)
            )

        await fetch(`/back-end/public/api/getAdminAnswerFinal/${course_id}`).then(
            response => response.json()).then(
                json => setAnswer(json)
            )

        }
      
      
        else{  
            
            await fetch(`/back-end/public/api/getQuestionsAdmin/${course_id}/${section_id}`).then(
            response => response.json()).then(
                json => setQuestion(json)
            )

        await fetch(`/back-end/public/api/getAdminAnswer/${course_id}/${section_id}`).then(
            response => response.json()).then(
                json => setAnswer(json)
            )}

    }

    async function getTestTime() {

        if(section_id === 0)
        {
            await fetch(`/back-end/public/api/getTestTimeFinal/${course_id}`).then(
                response => response.json()).then(
                    json => {
                        if (json.length > 0) {
                            setAddTimeBtn(false)
                            setTime(json[0].time)
                        }
                        else setAddTimeBtn(true)
                        setGetTime(json)
                        setDisabledBtn(false)
    
                    }
                )
            
            }
        

      else{ 
           await fetch(`/back-end/public/api/getTestTime/${course_id}/${section_id}`).then(
            response => response.json()).then(
                json => {
                    if (json.length > 0) {
                        setAddTimeBtn(false)
                        setTime(json[0].time)
                    }
                    else setAddTimeBtn(true)
                    setGetTime(json)
                    setDisabledBtn(false)

                }
            )}
    }

    async function deleteQuestion(id) {
        setDisabledQuestBtn(true)
        if(section_id === 0)
        {
            await fetch("/back-end/public/api/deleteQuesFinal/" + id, {
                method: "DELETE"
            }).then(
                response => response.json()).then(
                    () => getQuestionsAnswers()
                )
        }
       else{ await fetch("/back-end/public/api/deleteQues/" + id, {
            method: "DELETE"
        }).then(
            response => response.json()).then(
                () => getQuestionsAnswers()
            )}
        setDisabledQuestBtn(false)

    }

    function UpdateQuestion(row) {
        history.push({
            pathname: `/admin/corsi/${course_id}/sezioni/${section_id}/moduli/aggiorna-domande`,
            state: {
                question_id: row.id,
            }
        })
    }

    async function publishTest() { 
        setDisabledPublish(true)
        if (getTime.length > 0 && questions.length >= 2) {
            if (getTime[0].time === 0) setError(true)
            else {
                const formData = new FormData();
                formData.append('section_id', section_id)
                formData.append('course_id', course_id)

                if(section_id === 0)
                {
                    await fetch("/back-end/public/api/publishTestFinal", {
                        method: "POST",
                        body: formData
                    }).then(() => setPublished(true), getData())
                }
                else{
                    await fetch("/back-end/public/api/publishTest", {
                    method: "POST",
                    body: formData
                }).then(() => setPublished(true), getData())
            }
            }
        }
        else if (questions.length < 2) {
            setMinQuesError(true)
        }
        else if (getTime.length === 0) {
            setError(true)
        }
        setDisabledPublish(false)
    }

    function Row(props) {

        const { row } = props;
        const [open, setOpen] = useState(false);
        const classes = useStyles();

        return (
            <Fragment>
                <TableRow hover className={classes.root} >
                    <TableCell >
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {row.question}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {row.score}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {answers.filter(question => question.question_id === row.id)
                            .map((item, index) => (index)).length || answers.filter(question => question.question_final_id === row.id)
                            .map((item, index) => (index)).length}
                    </TableCell>
                    <TableCell >
                        <Button onClick={() => UpdateQuestion(row)}>
                            <EditOutlined style={{ fontSize: "20px" }} />
                        </Button>

                    </TableCell>
                    <TableCell >
                        <Popconfirm onConfirm={() => deleteQuestion(row.id)}
                            title="Sei sicuro?" okText="Si" cancelText="No">
                            <Button disabled={disabledQuestBtn} >
                                <DeleteOutlined style={{ fontSize: "18px" }} />
                            </Button>
                        </Popconfirm>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box margin={1}>
                                <Box margin={1}>
                                    <Typography variant="h6" gutterBottom component="div">
                                      Risposte
                                    </Typography>
                                    <Table size="small" aria-label="purchases">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Risposte</TableCell>
                                                <TableCell>Punteggio</TableCell>
                                                <TableCell align="right">Risposte esatte</TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {answers.filter(question => question.question_id === row.id || question.question_final_id === row.id)
                                                .map((item) => (
                                                    <TableRow key={item.id}>
                                                        <TableCell component="th" scope="row">
                                                            {item.answers}
                                                        </TableCell>
                                                        <TableCell>{item.score}</TableCell>
                                                        {item.correct_answer ?
                                                            <TableCell align="right" style={{ color: '#00a152', fontWeight: '800' }}>
                                                                Vero
                                                            </TableCell>
                                                            :
                                                            <TableCell style={{ color: '#aa2e25', fontWeight: '800' }} align="right">falso</TableCell>}


                                                    </TableRow>
                                                ))}
                                        </TableBody>
                                    </Table>
                                </Box>


                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </Fragment>
        );
    }

    async function addtime() {
        setLoading(true)
        if (time === '') {
            setErrorTime(true)
            setLoading(false)
        }
        else if (time !== '') {

            const formData = new FormData();
            formData.append('course_id', course_id)
            formData.append('section_id', section_id)
            formData.append('time', time)

            if(section_id === 0)
            {
                await fetch("/back-end/public/api/addTestTimeFinal", {
                    method: "POST",
                    body: formData
                }).then(() => setTime(''), setOpenDialog(false), getTestTime(), setErrorTime(false), setLoading(false))
            }
            else{
                await fetch("/back-end/public/api/addTestTime", {
                method: "POST",
                body: formData
            }).then(() => setTime(''), setOpenDialog(false), getTestTime(), setErrorTime(false), setLoading(false))
        }

        }
    }
    async function updatetime() {

        const formData = new FormData();
        formData.append('section_id', section_id)
        formData.append('course_id', course_id)
        if (time === '')
            formData.append('time', getTime[0].time)
        else
            formData.append('time', time)

        if(section_id === 0)
        {
            await fetch("/back-end/public/api/updateTestTimeFinal", {
                method: "POST",
                body: formData
            }).then(() => setTime(''), setOpenDialog(false), getTestTime(), setErrorTime(false))
        }
        
         else   {
             await fetch("/back-end/public/api/updateTestTime", {
            method: "POST",
            body: formData
        }).then(() => setTime(''), setOpenDialog(false), getTestTime(), setErrorTime(false))}


    }

    async function deleteTime() {
        setDisabledBtn(true)
       
       if(section_id === 0)
       {
        await fetch("/back-end/public/api/deleteTestTimeFinal/" + course_id, {
            method: "DELETE"
        }).then(
            response => response.json()).then(
                (response) => {
                    if (response.msg === 'success') {
                        getTestTime()
                        setTime('')
                        setErrorTime(false)
                    }
                }
            )
       }
        else{ await fetch("/back-end/public/api/deleteTestTime/" + section_id, {
            method: "DELETE"
        }).then(
            response => response.json()).then(
                (response) => {
                    if (response.msg === 'success') {
                        getTestTime()
                        setTime('')
                        setErrorTime(false)
                    }
                }
            )}

    }


    const handleClose = () => {
        setOpenDialog(false)
        setTime('')
        if (errorTime)
            setErrorTime(false)
    }


    const handleError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setError(false);
        setMinQuesError(false)

    }
    return (
        <Grid container spacing={3}>
            <Snackbar open={error} autoHideDuration={2000} onClose={handleError} >
                <Alert onClose={handleError} severity="error">
                Imposta il timer
                </Alert>
            </Snackbar>
            <Snackbar open={minQuesError} autoHideDuration={2000} onClose={handleError} >
                <Alert onClose={handleError} severity="error">
                Devi aggiungere almeno 2 domande
                </Alert>
            </Snackbar>
            <Dialog disableBackdropClick disableEscapeKeyDown open={openDialog} onClose={handleClose}>
                <DialogTitle>Durata del test (in minuti)</DialogTitle>

                <DialogContent>
                    <Grid item xs={12} sm={8} lg={8}>
                        <TextField
                            id="outlined-full-width"
                            label="Durata del test"
                            placeholder="Time"
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            size='small'
                            variant="outlined"
                            required
                            defaultValue={getTime.length > 0 ? getTime[0].time : 0}
                            type="number"
                            InputProps={{ inputProps: { min: 0 } }}
                            onChange={(e) => setTime(e.target.value)}
                            error={errorTime}
                            helperText={errorTime ? 'Il campo è obbligatorio' : null}
                        />
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} >
                    Cancella
                    </Button>
                    {getTime.length > 0 ?

                        <Button
                            form="my-form-id"
                            type="primary"
                            className={classes.button}
                            onClick={updatetime}>
                            Aggiorna
                        </Button>

                        : <Button
                            type="primary"
                            className={classes.button}
                            onClick={addtime}  >
                            Salva
                        </Button>}
                </DialogActions>

            </Dialog>


            {questions.length > 0 ?
                <Grid item xs={11} sm={12} lg={12} >
                    <div style={{ display: 'flex' }}>

                        {questions.filter(id => id.published === 0).map((item, index) => index).length > 0 && published === false ?

                            <Popconfirm onConfirm={() => publishTest()}
                                title="Sei sicuro?" okText="Si" cancelText="No">
                                <Button
                                    loading={disabledPublish}
                                    type="primary"
                                    className={classes.button}
                                    style={{ width: '120px' }}
                                >
                                    Pubblica tes
                                </Button>
                            </Popconfirm>

                            : null}
                        {addTimeBtn ?
                            <Button
                                loading={loading}
                                type="primary"
                                className={classes.button}
                                onClick={() => setOpenDialog(true)}
                            >
                                Durata del test (in minuti)
                            </Button>

                            : null
                        }
                    </div>
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label="collapsible table">
                            <TableHead>
                                <TableRow>
                                    <TableCell />
                                    <TableCell>Domande</TableCell>
                                    <TableCell>Punteggio</TableCell>
                                    <TableCell>Numero di risposte </TableCell>
                                    <TableCell >Modifica</TableCell>
                                    <TableCell >Elimina</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {questions.map((item, index) => (
                                    <Row key={index} row={item} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid> : null
            }
            <Grid item xs={12} lg={12} >

                {getTime.length > 0 ?
                    <Grid item xs={6} sm={6} lg={4}>
                        <TableContainer component={Paper}>
                            <Table size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell >Durata del test (in minuti)</TableCell>
                                        <TableCell >Modifica</TableCell>
                                        {questions.filter(id => id.published === 1).map((item, index) => index).length === 0 ?
                                            <TableCell >Elimina</TableCell> : null}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableCell >
                                        {getTime[0].time} Minuti
                                    </TableCell>
                                    <TableCell >
                                        <Button color='primary' onClick={() => setOpenDialog(true)}>
                                            <EditOutlined style={{ fontSize: "20px" }} />
                                        </Button>

                                    </TableCell>
                                    {questions.filter(id => id.published === 1).map((item, index) => index).length === 0 ?
                                        <TableCell >
                                            <Popconfirm onConfirm={() => deleteTime()}
                                                title="Sei sicuro?" okText="Si" cancelText="No">
                                                <Button color='primary'
                                                    icon={<DeleteOutlined style={{ fontSize: "18px" }} />} loading={disabledBtn}>


                                                </Button>
                                            </Popconfirm>
                                        </TableCell> : null}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid> : null}
            </Grid>
            {(questions.filter(id => id.published === 1).map((item, index) => index).length === questions.length && questions.length > 0) || published ?
                <Grid item xs={11} sm={12} lg={12}>

                    <Alert size='small' variant="outlined" color="info" severity="info">Il test è stato pubblicato</Alert>
                </Grid> : null}
        </Grid>

    );
}

export default withRouter(AddTest);
