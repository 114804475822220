import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { useHistory } from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import { DataGrid } from '@material-ui/data-grid';
import { useStyles } from "../components/MainTheme.js";
import { Button } from 'antd';




export default function Register() {
    const classes = useStyles();

    const [studentEnrolled, setStudentEnrolled] = useState([]);
    const [course_enrolled_btn, setCourseEnrolledBtn] = useState(true);
    const [student, setStudent] = useState('');

    const history = useHistory();

    const moment = require('moment');

    const columns = [
        { field: 'id', type: 'number', headerName: 'Id', width: 100, },
        { field: 'name', headerName: 'Nome', width: 200 },
        { field: 'surname', headerName: 'Cognome', width: 200 },
        { field: 'email', headerName: 'E-mail', width: 350 },
        { field: 'country', headerName: 'Nazione', width: 160 },
        { field: 'city', headerName: 'Città', width: 160 },
        { field: 'phone', headerName: 'Telefono', width: 160 },
        {
            field: "created_at", headerName: 'Iscritto a', width: 300,
            valueFormatter: (params) => {
                const valueFormatted = moment(params.value).format('DD/MM/YYYY, h:mm:ss a');
                return `${valueFormatted}`;
            },
        },
    ];

    useEffect(() => {
        getData()
    }, [])

    async function getData() {


        await fetch("/back-end/public/api/getStudentEnrolled").then(
            response => response.json()).then(
                json => setStudentEnrolled(json)
            )

    }
    function getCourseEnrolled(courseEnrolled) {
        setStudent(courseEnrolled)
        setCourseEnrolledBtn(false)

    }

    function goToCourseEnrolled() {
        history.push({
            pathname: `/admin/studenti-iscritti/${student.user_id}/corsi`
        })
    }

    return (
        <Container maxWidth="lg">
            <Grid container spacing={3} >
                {studentEnrolled.length > 0 ?
                    <Grid item xs={12} lg={12}>
                        <Paper >

                            <Grid item xs={12} lg={12} style={{ padding: '5px 15px 0 0', display: 'flex', justifyContent: 'space-between' }}>
                                <Button
                                    disabled={course_enrolled_btn}
                                    type="primary"
                                    className={classes.button}
                                    onClick={goToCourseEnrolled}>
                                   Elenco corsi disponibili
                                </Button>
                                {course_enrolled_btn ? null :
                                    <Typography variant="h5" className={classes.title}>
                                        {student.name} {student.surname}
                                    </Typography>}
                            </Grid>
                            {studentEnrolled.length > 0 ?
                                <div style={{ height: 400, width: '100%' }}>
                                    <DataGrid rows={studentEnrolled} columns={columns} pageSize={100}
                                        rowsPerPageOptions={[100, 300, 500]}
                                        onRowSelected={(e) => getCourseEnrolled(e.data)} />
                                </div>
                                : null}
                        </Paper>
                    </Grid> : null
                }

            </Grid>
        </Container >

    );
}
