import React, { useEffect, useState, useMemo } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import Routerx from "./Routerx";
import Home from "../UserPages/Home";
import Courses from "../AdminPages/Courses";
import AddQuestion from "../AdminPages/AddQuestion"
import TakeTest from "../UserPages/TakeTest"
import UpdateQuestion from "../AdminPages/UpdateQuestion"
import ListOfUsers from "../AdminPages/ListOfUsers"
import StudentCourseEnrolled from "../AdminPages/StudentCourseEnrolled"
import CourseDetails from "../AdminPages/CourseDetails";
import SectionModule from "../AdminPages/SectionModule";
import UpdateSection from "../AdminPages/UpdateSection";
import ReadPdf from "../AdminPages/ReadPdf";
import ReadModule from "../UserPages/ReadModule";
import CreateAccount from "../UserPages/CreateAccount";
import CourseBought from "../AdminPages/CourseBought";
import UsersCourses from "../AdminPages/UsersCourses";
import StudentModuleEnrolled from "../AdminPages/StudentModuleEnrolled";
import StudentSectionEnrolled from "../AdminPages/StudentSectionEnrolled";
import UserProfile from "../UserPages/UserProfile";
import Login from "../UserPages/Login";
import AddStudent from "../AdminPages/AddStudent";
import AddCourse from "../AdminPages/AddCourse";
import UpdateCourse from "../AdminPages/UpdateCourse";
import StudentEnrolled from '../AdminPages/StudentEnrolled'
import VideoPage from '../UserPages/VideoPage'
import VideoPageAdmin from '../AdminPages/VideoPageAdmin'
import CourseSections from "../UserPages/CourseSections";
import AdminCourseSections from "../AdminPages/AdminCourseSections";
import Modules from "../UserPages/Modules";
import TestResult from "../UserPages/TestResult";
import NotFoundPage from "../UserPages/NotFoundPage";
import UserPowerPoint from "../UserPages/UserPowerPoint";
import Context from '../Context'
import HomePage from "../AdminPages/HomePage";
import SectionPage from "../AdminPages/SectionPage";
import UserTestResult from "../AdminPages/UserTestResult";
import LoadingPage from "../LoadingPage";
import ReadPowerPoint from '../AdminPages/ReadPowerPoint';
import TestTitleFeedback from '../AdminPages/TestTitleFeedback';
import UpdateTestTitleFeedback from '../AdminPages/UpdateTestTitlefeedback';
import CheckResult from "../UserPages/CheckResult";
import Settings from "../AdminPages/Settings";


export default function Routes() {

  const [auth, setAuth] = useState(false);
  const [ready, setReady] = useState(false);
  const [user, setUser] = useState(null);

  const value = useMemo(() => ({ auth, setAuth, user, setUser }), [auth, setAuth, user, setUser])


  useEffect(() => {
    async function getData() {
      await fetch("/back-end/public/api/user", {

        headers: {
          "Content-Type": "application/json",
        },
        credentials: 'include',
      }).then(
        response => response.json()).then(
          json => {
            if (json.id) {
              setAuth(true)
              setUser(json)

            }
            setReady(true)
          }

        )
    }
    getData()
  }, [])


  return ready ? (
    <Context.Provider value={value}>
      <BrowserRouter>
        <Switch> 
          <Routerx exact path="/" component={Login} /> 
          <Routerx exact path="/entra" component={Login} />
          <Routerx exact path="/admin/corsi/:course_id/sezioni/:section_id/moduli" component={SectionModule} type="ADM" />
          <Routerx exact path="/admin/corsi/:course_id/dettagli-corso" component={CourseDetails} type="ADM" />
          <Routerx exact path="/admin/corsi/:course_id/aggiorna-corso" component={UpdateCourse} type="ADM" />
          <Routerx exact path="/admin/corsi" component={Courses} type="ADM" />
          <Routerx exact path="/admin/settings" component={Settings} type="ADM" />
          <Routerx exact path="/admin/aggiungi-corsista" component={AddStudent} type="ADM" />
          <Routerx exact path="/admin/inserisci-corso" component={AddCourse} type="ADM" />
          <Routerx exact path="/admin/studenti-iscritti/:user_id/corsi/:course_id/sezioni/:section_id/test-risultati" component={UserTestResult} type="ADM" />
          <Routerx exact path="/admin/studenti-iscritti/:user_id/corsi/:course_id/sezioni/:section_id/moduli/test-risultati" component={UserTestResult} type="ADM" />
          <Routerx exact path="/admin/studenti-iscritti/:user_id/corsi/:course_id/sezioni/:section_id/moduli" component={StudentModuleEnrolled} type="ADM" />
          <Routerx exact path="/admin/studenti-iscritti/:user_id/corsi/:course_id/sezioni" component={StudentSectionEnrolled} type="ADM" />
          <Routerx exact path="/admin/studenti-iscritti/:user_id/corsi" component={StudentCourseEnrolled} type="ADM" />
          <Routerx exact path="/admin/studenti-iscritti" component={StudentEnrolled} type="ADM" />
          <Routerx exact path="/admin/corsi/:course_id/sezioni/:section_id/moduli/nome-del-test" component={TestTitleFeedback} type="ADM" />
          <Routerx exact path="/admin/corsi/:course_id/sezioni/:section_id/moduli/aggiorna-nome-del-test" component={UpdateTestTitleFeedback} type="ADM" />
          <Routerx exact path="/admin/corsi/:course_id/sezioni/:section_id/moduli/inserisci-domande" component={AddQuestion} type="ADM" />
          <Routerx exact path="/admin/corsi/:course_id/sezioni/:section_id/inserisci-domande" component={AddQuestion} type="ADM" />
          <Routerx exact path="/admin/corsi/:course_id/sezioni/:section_id/moduli/aggiorna-domande" component={UpdateQuestion} type="ADM" />
          <Routerx exact path="/admin/corsi/:course_id/sezioni/:section_id/moduli/:module_id/video" component={VideoPageAdmin} type="ADM" />
          <Routerx exact path="/admin/corsi/:course_id/sezioni/:section_id/moduli/:module_id/pdf" component={ReadPdf} type="ADM" />
          <Routerx exact path="/admin/corsi/:course_id/sezioni/:section_id/moduli/:module_id/ppt" component={ReadPowerPoint} type="ADM" />
          <Routerx exact path="/admin/corsi/:course_id/sezioni" component={AdminCourseSections} type="ADM" />
          {/* <Routerx exact path="/admin/list-users/:user_id/course-bougth" component={CourseBought} type="ADM" />
          <Routerx exact path="/admin/list-users/users-course" component={UsersCourses} type="ADM" />
          <Routerx exact path="/admin/list-users" component={ListOfUsers} type="ADM" /> */}
          <Routerx exact path="/admin/dashbord" component={HomePage} type="ADM" />
          <Routerx exact path="/admin/dashbord/:course_id/sezione" component={SectionPage} type="ADM" />
          <Routerx exact path="/admin/corsi/sezioni/aggiorna-sezione" component={UpdateSection} type="ADM" />
        
          <Routerx exact path="/home/corsi/:course_id/moduli/:section_id/pdf/:module_id" component={ReadModule} type="USR" />
          <Routerx exact path="/home/corsi/:course_id/moduli/:section_id/ppt/:module_id" component={UserPowerPoint} type="USR" />
          <Routerx exact path="/home/corsi/:course_id/moduli/:section_id/video/:module_id" component={VideoPage} type="USR" />
          <Routerx exact path="/home/corsi/:course_id/:section_id/test-risultati" component={TestResult} type="USR" />
          <Routerx exact path="/home/corsi/:course_id/moduli/:section_id/test-risultati" component={TestResult} type="USR" />
          <Routerx exact path="/home/corsi/:course_id/controlla-I-risultati/:section_id" component={CheckResult} type="USR" />
          <Routerx exact path="/home/corsi/:course_id/:section_id/test" component={TakeTest} type="USR" />
          <Routerx exact path="/home/corsi/:course_id/moduli/:section_id/test" component={TakeTest} type="USR" />
          <Routerx exact path="/home/corsi/:course_id/moduli/:section_id" component={Modules} type="USR" />
          <Routerx exact path="/home/corsi/:course_id" component={CourseSections} type="USR" />
          <Routerx exact path="/home/profilo" component={UserProfile} type="USR" />
          <Routerx exact path="/home" component={Home} type="USR" />
          <Routerx exact path="/dettagli-account" component={CreateAccount} type="USR" />
          <Routerx component={NotFoundPage} type="Error" />
          <Routerx path="/not-found" component={NotFoundPage} type="Error" />
        </Switch>
      </BrowserRouter>
    </Context.Provider >

  ) : <LoadingPage />
}
