import React, { useState, useEffect } from "react";
import { withRouter, useParams } from "react-router-dom";
import Container from '@material-ui/core/Container';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { useStyles } from "../components/MainTheme.js";
import Grid from "@material-ui/core/Grid";
import Breadcrumb from "../components/Breadcrumb";
import InputLabel from '@material-ui/core/InputLabel';
import MuiAlert from '@material-ui/lab/Alert';

function CourseBought(props) {


    const { user_id } = useParams()
    const [courses, setCourses] = useState([]);
    const [userInfo, setUserInfo] = useState(null);
    const [noCourse, setNoCourses] = useState(false);
    const classes = useStyles();

    function Alert(props) {
        return <MuiAlert elevation={2} variant="outlined" {...props} />;
    }

    useEffect(() => {
        getData()
    }, [])


    async function getData() {
        await fetch(`/back-end/public/api/getUsercourse/${user_id}`).then(
            response => response.json()).then(
                json => {
                    if (json.length > 0) setCourses(json)
                    else setNoCourses(true)
                }
            )
        await fetch(`/back-end/public/api/getUserInfo/${user_id}`).then(
            response => response.json()).then(
                json => setUserInfo(json[0])

            )

    }

    return (
        <Container maxWidth="lg">
            <Breadcrumb />
            <Grid container spacing={3} justify='center' >

                <Grid item xs={12} lg={12} style={{ justifyContent: 'center', display: 'flex' }}>
                    {userInfo ?
                        <Grid item xs={12} lg={3}>
                            <Paper style={{ textAlign: 'center', marginTop: '1rem' }}  >
                                <Grid item xs={12} style={{ background: 'lightgreen' }}>
                                    <Typography variant='h6'> User Name </Typography>
                                </Grid>
                                <Grid item xs={12} style={{ padding: '10px' }}>
                                    <Typography>   {userInfo.first_name} {userInfo.last_name} </Typography>
                                </Grid>
                            </Paper>
                        </Grid> : null}
                </Grid>
                {courses.length > 0 ?
                    <Grid item xs={12} lg={9}>
                        <InputLabel htmlFor="age-native-helper">Courses bought</InputLabel>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>

                                        <TableCell >Course</TableCell>
                                        <TableCell >Name</TableCell>


                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {courses.map((item, index) => (
                                        <TableRow key={item.id} >
                                            <TableCell > {index + 1}
                                            </TableCell>

                                            <TableCell >{item.post_title}</TableCell>

                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid> : null}

                {noCourse ? <Alert severity="info">Did not buy any course!</Alert> : null}
            </Grid>
        </Container>

    );
}

export default withRouter(CourseBought);