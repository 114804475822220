import React, { useState, useEffect } from "react";
import Container from '@material-ui/core/Container';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { withRouter, Link, useParams, useHistory } from "react-router-dom"
import Typography from '@material-ui/core/Typography';
import { useStyles } from "../components/MainTheme.js";
import Breadcrumb from "../components/Breadcrumb";
import Grid from "@material-ui/core/Grid";
import { Button, Result , message, Popconfirm} from 'antd';
import { SmileOutlined , FrownOutlined} from '@ant-design/icons';
import MuiAlert from '@material-ui/lab/Alert';
import Chip from '@material-ui/core/Chip';

function StudentSectionEnrolled(props) {

    const { course_id } = useParams()
    const { user_id } = useParams()

    const classes = useStyles();

    const moment = require('moment');

    const [sections, setSections] = useState([])
    const [studentInfo, setStudentInfo] = useState(null);
    const history = useHistory();

    const [answerScore, setScoreAnswers] = useState('')
    const [testScore, setTestScore] = useState('')
    const [countQuestion, setCountQuestion] = useState(null);
    const [sectionStatus, setSectionsStatus] = useState([]);

    const [timesPasseTest, setTimesPasseTest] = useState(null);


    function Alert(props) {
        return <MuiAlert elevation={2} variant="outlined" {...props} />;
    }

    useEffect(() => {
        getData()
    }, [])


    async function getData() {

        await fetch(`/back-end/public/api/getAdminSections/${course_id}`).then(
            response => response.json()).then(
                json => setSections(json)
            )

            await fetch(`/back-end/public/api/getUserSectionStatus/${course_id}/${user_id}`).then(
                response => response.json()).then(
                    json => setSectionsStatus(json)
                )

        await fetch(`/back-end/public/api/getUserProfileAdmin/${user_id}`).then(
            response => response.json()).then(
                (json) => setStudentInfo(json[0])
            )

        await fetch(`/back-end/public/api/getUserTestScoreFinal/${course_id}/${user_id}`).then(
            response =>
                response.json()
        ).then(
            json => setScoreAnswers(json)
        )

        await fetch(`/back-end/public/api/getTotalUserScoreQuestionFinalAdmin/${course_id}/${user_id}`).then(
            response => response.json()).then(
                json => setTestScore(json)
            )

            await fetch(`/back-end/public/api/getCountQuestionFinalAdmin/${course_id}`).then(
                response => response.json()).then(
                    json => {
                        
                        setCountQuestion(json)
                    }
                )

    }

    useEffect(()=>{

        if(answerScore.length > 0 && testScore && answerScore && (((answerScore*100)/testScore)  < 70))
           {
                checkTimesPasseTest()
            }

           
    },[testScore, answerScore])

    async function checkTimesPasseTest()
    {
        await fetch(`/back-end/public/api/checkTimesPasseTestFinalAdmin/${course_id}/${user_id}`).then(
            response => response.json()).then(
                json => {
                   
                        setTimesPasseTest(json)
                }
            )
    }

    async function unlock()
    {
        const formData = new FormData();
        formData.append('course_id', course_id)
        formData.append('user_id', user_id)

        await fetch("/back-end/public/api/unblockForTestFinal", {
            method: "POST",
            body: formData
        }).then(
            response => response.json()).then(
                json => {
                  if(json[0] === 'success')  {success()
                getData()}
                  else error()
                }
            )
    } 

    const success = () => {
        message.success({
          content: 'Quiz sbloccato',
          style: {
            marginTop: '5vh',
          },
        });
      };

      const error = () => {
        message.error({
          content: 'Qualcosa è andato storto, riprova',
          style: {
            marginTop: '5vh',
          },
        });
      };


    function testResult() {
        history.push({
            pathname: `/admin/studenti-iscritti/${user_id}/corsi/${course_id}/sezioni/0/test-risultati`,
        })
    }
    return (
        <Container maxWidth="lg">
            <Grid container spacing={3} justify='flex-start'>
            <Breadcrumb />

                <Grid item xs={12} lg={8}>
                    {sections.length > 0 ?
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>

                                        <TableCell >#</TableCell>
                                        <TableCell >Nome sezione</TableCell>
                                        <TableCell >Apri</TableCell>
                                         <TableCell >Termina sezione</TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {sections.map((item, index) => (
                                        <TableRow key={index} >
                                            <TableCell > {index + 1}
                                            </TableCell>
                                            <TableCell>
                                                <Link to={{
                                                    pathname: `/admin/studenti-iscritti/${user_id}/corsi/${course_id}/sezioni/${item.id}/moduli`
                                                }}
                                                >{item.section_name}
                                                </Link>
                                            </TableCell>
                                            <TableCell>
                                            {sectionStatus.filter(id => id.section_id === item.id).map((item) =>
                                                item.created_at).toString() ?
                                                moment(sectionStatus.filter(id => id.section_id === item.id).map((item) =>
                                                    item.created_at).toString()).format('DD/MM/YYYY, h:mm:ss a')
                                                : <Chip
                                                    variant="outlined"
                                                    size="small"
                                                    label="Termina lettura"
                                                    color="secondary"
                                                />
                                            }
                                        </TableCell>
                                        <TableCell>
                                            {sectionStatus.filter(id => id.section_id === item.id).map((item) =>
                                                item.finished_section).toString() === '1' ?

                                                moment(sectionStatus.filter(id => id.section_id === item.id).map((item) =>
                                                    item.updated_at).toString()).format('DD/MM/YYYY, h:mm:ss a')
                                                :
                                                <Chip
                                                    variant="outlined"
                                                    size="small"
                                                    label="Non terminato"
                                                    color="secondary"
                                                />}
                                        </TableCell>

                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer> : null
                    }
                </Grid>

                {studentInfo ?
                   
                    <Grid item xs={12} lg={3}>
                        <Paper style={{ textAlign: 'center' }}  >
                            <Grid item xs={12} style={{ background: 'lightgreen' }}>
                                <Typography variant='h6'> Nome Corsista</Typography>
                            </Grid>
                            <Grid item xs={12} style={{ padding: '10px' }}>
                                <Typography>  {studentInfo.name} {studentInfo.surname} </Typography>
                            </Grid>
                        </Paper>
                    </Grid> : null}
                {answerScore.length > 0 && testScore && answerScore && (((answerScore*100)/testScore)  >= 70) ?
                    <Grid item xs={12} sm={6} lg={3}>
                        <Paper>
                            <Result style={{ padding: '27px' }}
                                status='success'
                                icon={<SmileOutlined />}
                                title={`La prova finale è stata superata!`}
                                subTitle={`Punteggio: ${Math.round((answerScore*100)/testScore)}%`}
                                extra={<Button type="primary" onClick={testResult} >Controlla I risultati</Button>}

                            />
                        </Paper>
                    </Grid>
                    : null }

                     {answerScore.length > 0 && testScore && answerScore && (((answerScore*100)/testScore)  < 70) ?
                            <Grid item xs={12} sm={6} lg={3}>
                                 {timesPasseTest && timesPasseTest === 3 ? 
                                                <Popconfirm
                                                        onConfirm={() => {
                                                            unlock()
                                                        }}
                                                        title="Sei sicuro?"
                                                        okText="Si"
                                                        cancelText="No"
                                                    >
                                                        <Button type="primary" >Sblocca</Button>
                                                    </Popconfirm>: null}
                                <Paper >
                                <Result style={{ padding: '27px' }}
                                    status='error'
                                    icon={<FrownOutlined  />}
                                    title={`Il test non è stato passato`}
                                    subTitle={`Punteggio: ${Math.round((answerScore*100)/testScore)}%`}
                                    extra={<Button type="primary" onClick={testResult} >Controlla i risultati</Button>}

                                />
                                </Paper>
                            </Grid> : null}
                { countQuestion && countQuestion  > 0 && answerScore.length === 0 ?
                    <Grid item xs={12} lg={3} >
                       <Alert severity="info">Test finale non ancora superato</Alert>
                    </Grid> : null}
                    { countQuestion && countQuestion  === 0 ?
                        <Grid item xs={12} lg={3} > <Alert severity="info">Test finale mancante</Alert> </Grid> :null
                    }

            </Grid>
        </Container>

    );
}

export default withRouter(StudentSectionEnrolled);
